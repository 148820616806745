import DocumentsList from "../DocumentsList";

import scss from "./index.module.scss";

const { documents, documents__heading, documents__title, documents__subtitle } = scss;

function DocumentsContainer({ list }) {
   return (
      <div className={documents}>
         <div className={documents__heading}>
            <h2 className={documents__title}>Прилагаемые документы</h2>
            <span className={documents__subtitle}>
               Пожалуйста ознакомитесь с пакетом документов, который для вас сформирован:
            </span>
         </div>
         <DocumentsList documents={list} />
      </div>
   );
}

export default DocumentsContainer;
