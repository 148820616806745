import formatCard from '../../../helpers/formatCard';

import Button from '../../Inputs/Button';
import CopiedField from '../../StateLess/CopiedField';

import classes from '../styles.module.scss';

const {
    modalContent__button,
    modalContent__copiedFields
} = classes;

const CreditCard = ({
    onClose = () => {},
    number  = "",
    expire  = "",
    cvc     = ""
}) => {

    return (
        <>
            <div className={modalContent__copiedFields}>
                <CopiedField
                    label='Номер карты'
                    value={formatCard(number)}
                    valueToCopy={number}
                />

                <CopiedField
                    label='Срок действия'
                    value={expire}
                    valueToCopy={expire}
                />

                <CopiedField
                    label='CVC код'
                    value={cvc}
                    valueToCopy={cvc}
                />
            </div>

            <Button
                className={modalContent__button}
                variant="gray"
                onClick={onClose}
            >Закрыть</Button>
        </>
    )
}

export default CreditCard;