function IconBookContacts(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M7.4 2A2.4 2.4 0 005 4.4v14.4a2.4 2.4 0 002.4 2.4h11.4a.6.6 0 000-1.2H7.4a1.2 1.2 0 01-1.2-1.2h12a1.2 1.2 0 001.2-1.2V4.4A2.4 2.4 0 0017 2H7.4zM14 8.6a1.8 1.8 0 11-3.6 0 1.8 1.8 0 013.6 0zm-5.4 3.9a.9.9 0 01.9-.9h5.4a.9.9 0 01.9.9v.3a2.4 2.4 0 01-2.4 2.4H11a2.4 2.4 0 01-2.4-2.4v-.3z"
         ></path>
      </svg>
   );
}

export default IconBookContacts;
