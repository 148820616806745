function IconDoubleChevronLeft(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M13.023 19.17a.9.9 0 01-1.272-.032l-6.002-6.3a.9.9 0 010-1.242l6-6.3a.9.9 0 111.304 1.24l-5.41 5.682 5.41 5.68a.9.9 0 01-.03 1.271zm4.8 0a.9.9 0 01-1.272-.03l-6.001-6.3a.9.9 0 010-1.243l6.001-6.3a.9.9 0 111.303 1.241l-5.41 5.68 5.41 5.68a.9.9 0 01-.03 1.273h-.001z"
         ></path>
      </svg>
   );
}

export default IconDoubleChevronLeft;
