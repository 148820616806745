import { Link } from "react-router-dom";
import IconPage from "../../../components/Icons/Page";
import IconArrowRight from "../Icons/IconArrowRight";
import scss from "./index.module.scss";

const { card, card_document, card__title, card__title_doc, card__text, wait, card__wrapper } = scss;

const getCard = (type, title, text, id, link, children, className) => {
   switch (type) {
      case "base": {
         return (
            <article className={card}>
               <h2 className={card__title}>{title}</h2>
               <span className={card__text}>{text}</span>
            </article>
         );
      }
      case "linked": {
         return (
            <Link to={"/manage/customers/" + id} className={card}>
               <h2 className={card__title}>
                  {title} <IconArrowRight />
               </h2>
               <span className={card__text}>{text}</span>
            </Link>
         );
      }
      case "document": {
         return (
            <a href={link} target="_blank" rel="noopener noreferrer" title={"Открыть в новой вкладке"} className={card + " " + card_document}>
               <IconPage />
               <h3 className={card__title + " " + card__title_doc}>{title}</h3>
            </a>
         );
      }
      case "wrapper": {
         return <article className={card__wrapper + " " + className}>{children}</article>;
      }
      case "wrapper-link": {
         return (
            <Link to={"/manage/loans/" + id} className={card__wrapper + " " + className}>
               {children}
            </Link>
         );
      }
      default: {
         return <div className={wait}></div>;
      }
   }
};

function Card({ type, title, text, id, link, children, className }) {
   return getCard(type, title, text, id, link, children, className);
}

export default Card;
