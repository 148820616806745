import { Link } from "react-router-dom";

import Button from "../../Inputs/Button";
import Icon, { ColorIconEnam, IconEnum } from "../../StateLess/Icon";

import classes from "../styles.module.scss";
import scss from "./index.module.scss";

const { modalContent__button } = classes;
const {
   LoanStoryList,
   LoanStoryList__Item,
   LoanStoryList__wrapper,
   LoanStoryList__icon,
   LoanStoryList__heading,
   LoanStoryList__title,
   LoanStoryList__summ,
   LoanStoryList__data,
   LoanStoryList__link,
   LoanStoryList__subtitle,
} = scss;

const LoanStory = ({ onClose = () => {}, data = {}, textButton = "Понятно" }) => {
   const getStatus = (status, item) => {
      switch (status) {
         case 0: {
            return (
               <>
                  <div className={LoanStoryList__wrapper}>
                     <Icon
                        className={LoanStoryList__icon}
                        name={IconEnum.close}
                        color={ColorIconEnam.red}
                     />
                     <div className={LoanStoryList__heading}>
                        <span className={LoanStoryList__title}>Отказ в займе</span>
                        <span className={LoanStoryList__summ}>
                           {`${(item?.amount / 100)?.toLocaleString("ru-RU")} ₽`}
                        </span>
                     </div>
                  </div>
                  <span className={LoanStoryList__data}>
                     {new Date(item?.timestamp)?.toLocaleDateString("ru-RU")}
                  </span>
               </>
            );
         }
         case 1: {
            return (
               <>
                  <div className={LoanStoryList__wrapper}>
                     <Icon
                        className={LoanStoryList__icon}
                        name={IconEnum.ok}
                        color={ColorIconEnam.gray}
                     />
                     <div className={LoanStoryList__heading}>
                        <span className={LoanStoryList__title}>Заявка подана</span>
                        <span className={LoanStoryList__summ}>
                           {`${(item?.amount / 100)?.toLocaleString("ru-RU")} ₽`}
                        </span>
                     </div>
                  </div>
                  <span className={LoanStoryList__data}>
                     {new Date(item?.timestamp)?.toLocaleDateString("ru-RU")}
                  </span>
               </>
            );
         }
         case 2: {
            return (
               <>
                  <div className={LoanStoryList__wrapper}>
                     <Icon
                        className={LoanStoryList__icon}
                        name={IconEnum.check}
                        color={ColorIconEnam.gray}
                     />
                     <div className={LoanStoryList__heading}>
                        <span className={LoanStoryList__title}>Ожидает подписания</span>
                        <span className={LoanStoryList__summ}>
                           {`${(item?.amount / 100)?.toLocaleString("ru-RU")} ₽`}
                        </span>
                     </div>
                  </div>
                  <time
                     className={LoanStoryList__data}
                     data={new Date(item?.timestamp)?.toLocaleDateString("ru-RU")}
                  >
                     {new Date(item?.timestamp)?.toLocaleDateString("ru-RU")}
                  </time>
               </>
            );
         }
         case 3: {
            return (
               <>
                  <div className={LoanStoryList__wrapper}>
                     <Icon
                        className={LoanStoryList__icon}
                        name={IconEnum.arrow_down}
                        color={ColorIconEnam.base}
                     />
                     <div className={LoanStoryList__heading}>
                        <span className={LoanStoryList__title}>Получен заём</span>
                        <span className={LoanStoryList__summ}>
                           {`${(item?.amount / 100)?.toLocaleString("ru-RU")} ₽`}
                        </span>
                     </div>
                  </div>
                  <span className={LoanStoryList__data}>
                     {new Date(item?.timestamp)?.toLocaleDateString("ru-RU")}
                  </span>
                  <Link
                     className={LoanStoryList__link}
                     to={"/loan/" + item?.id_loan}
                     onClick={onClose}
                  >
                     <span>подробнее</span>
                  </Link>
               </>
            );
         }
         case 4: {
            return (
               <>
                  <div className={LoanStoryList__wrapper}>
                     <Icon
                        className={LoanStoryList__icon}
                        name={IconEnum.ok}
                        color={ColorIconEnam.base}
                     />
                     <div className={LoanStoryList__heading}>
                        <span className={LoanStoryList__title}>Погашен</span>
                        <span className={LoanStoryList__summ}>
                           {`${(item?.amount / 100)?.toLocaleString("ru-RU")} ₽`}
                        </span>
                     </div>
                  </div>
                  <span className={LoanStoryList__data}>
                     {new Date(item?.timestamp)?.toLocaleDateString("ru-RU")}
                  </span>
                  <Link
                     className={LoanStoryList__link}
                     to={"/loan/" + item?.id_loan}
                     onClick={onClose}
                  >
                     <span>подробнее</span>
                  </Link>
               </>
            );
         }
         default:
            break;
      }
   };
   return (
      <>
         <ul className={LoanStoryList}>
            {data ? (
               data?.map((item, key) => (
                  <li className={LoanStoryList__Item} key={key}>
                     {getStatus(item?.status, item)}
                  </li>
               ))
            ) : (
               <li className={LoanStoryList__Item}>
                  <span className={LoanStoryList__subtitle}>Нет данных</span>
               </li>
            )}
         </ul>
         <Button className={modalContent__button} onClick={onClose} variant="secondary">
            {textButton}
         </Button>
      </>
   );
};

export default LoanStory;
