function IconBookQuestionMark(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M7.4 2A2.4 2.4 0 005 4.4v14.4a2.4 2.4 0 002.4 2.4h11.4a.6.6 0 000-1.2H7.4a1.2 1.2 0 01-1.2-1.2h12a1.2 1.2 0 001.2-1.2V4.4A2.4 2.4 0 0017 2H7.4zm3.312 5.29A1.09 1.09 0 0010.4 8a.6.6 0 11-1.2 0c0-.438.168-1.043.648-1.54.492-.512 1.26-.86 2.352-.86 1.092 0 1.86.347 2.352.86.48.497.648 1.102.648 1.54 0 1.255-.876 1.856-1.532 2.183a9.627 9.627 0 01-.47.215l-.014.006c-.13.054-.258.113-.384.175V11.6a.6.6 0 11-1.2.001V10.4a.88.88 0 01.292-.642c.13-.122.288-.21.414-.274a6.31 6.31 0 01.388-.176l.027-.012c.141-.061.278-.12.411-.187C13.676 8.838 14 8.545 14 8a1.088 1.088 0 00-.312-.71c-.228-.238-.66-.49-1.488-.49s-1.26.253-1.488.49zM12.2 15.2a.9.9 0 110-1.8.9.9 0 010 1.8z"
         ></path>
      </svg>
   );
}

export default IconBookQuestionMark;
