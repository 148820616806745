import { useEffect, useState } from "react";
import css from "./styles.module.scss";

const {
    pincode,
    pincode__area,
    pincode__area_invalid,
    pincode__input,
    pincode__box,
    pincode__box_current,
} = css;

const tmpArr = [];

const PincodeInput = ({
    length = 4,
    type = "text",
    regexp,
    value,
    onChange,
    invalid = false
}) => {

    const [rnd, render] = useState(0);

    // eslint-disable-next-line
    const [shown, setShowState] = useState(false);

    useEffect(()=> {
        while (tmpArr.length < length) {
            tmpArr.push("");
        }
        render(rnd + 1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pinCodeInput = (e) => {
        let tmpValue = e.target.value;

        if (regexp) {
            const valueArray = tmpValue.split("");
            let correctValue = "";
            valueArray.forEach(char => {
                if (char.match(regexp)) correctValue += char;
            });
            tmpValue = correctValue;
        }
        onChange(tmpValue);
    }

    useEffect(() => {
        if (value === -1) {
            onChange(value);
        }
        else {
            if (onChange) onChange(value);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <div className={pincode}>
            <label className={pincode__area + (invalid ? " " + pincode__area_invalid : "")}>
                <input
                    type="text"
                    onChange={pinCodeInput}
                    value={value}
                    maxLength={length}
                    className={pincode__input}
                />
                {
                    tmpArr.map((a, i)=>(
                        <div
                            key={i}
                            className={pincode__box +
                                      (value.length === i || (i === length - 1 && value.length >= length)? " " + pincode__box_current : "")}
                        >
                            {value[i] ? (type === "password" && !shown ? "*" : value[i]) : ""}
                        </div>
                    ))
                }
            </label>
        </div>
    );
}

export default PincodeInput;