import Button from "../../Inputs/Button";

import classes from "../styles.module.scss";

const { modalContent__text, modalContent__button } = classes;

const Issue = ({ onClose = () => {}, children, textButton = "Понятно" }) => {
   return (
      <>
         <p className={modalContent__text}>{children}</p>
         <Button className={modalContent__button} onClick={onClose} variant="secondary">
            {textButton}
         </Button>
      </>
   );
};

export default Issue;
