import { Link } from "react-router-dom";

import Icon from "../../StateLess/Icon";

import scss from "./index.module.scss";
import CreditDoctorBanner from "./CreditDoctor";

const {
   servicesItem,
   servicesItem__icon,
   servicesItem__body,
   servicesItem__title,
   servicesItem__text,
} = scss;

function ServicesItem({
   className,
   iconName,
   iconColor,
   value = {},
}) {
   
   const returnBanner = (name, data) => {
      switch (name) {
         case 'credit_doctor': {
            return <CreditDoctorBanner
               {...data}
            />
         }
      
         default: {
            return <></>
         }
      }
   }

   return !value.code ? (
      <Link className={servicesItem + " " + className} to={value.link}>
         <Icon className={servicesItem__icon} name={iconName} color={iconColor} />
         <div className={servicesItem__body}>
            <span className={servicesItem__title}>{value.title}</span>
            <span className={servicesItem__text}>действует до {new Date(value.timestamp).toLocaleDateString("RU-ru")}</span>
         </div>
      </Link>
   ) : returnBanner(value.code, value);
}

export default ServicesItem;
