import React from "react";
import classes from "./index.module.scss";

const { image } = classes;

function Picture({ className, fileName, secondImges, alt, lazy, ftype = "jpg" }) {
   const getImageSourceSet = (imageFileName) => {
      return imageFileName
         ? `/assets/${imageFileName}@1x.${ftype} 1x, /assets/${imageFileName}@2x.${ftype} 2x`
         : "";
   };

   return (
      <picture>
         {ftype === "png" && (
            <>
               <source
                  media="(max-width: 526px)"
                  srcSet={getImageSourceSet(secondImges[1])}
                  type="image/png"
               />
               <source
                  media="(max-width: 921px)"
                  srcSet={getImageSourceSet(secondImges[0])}
                  type="image/png"
               />
            </>
         )}
         <source srcSet={getImageSourceSet(fileName)} type="image/webp" />
         <img
            className={className ? className : image}
            src={fileName ? `/assets/${fileName}@1x.${ftype}` : ""}
            alt={alt ? alt : ""}
            srcSet={getImageSourceSet(fileName)}
            loading={lazy ? "lazy" : ""}
            decoding="async"
         />
      </picture>
   );
}

export default Picture;
