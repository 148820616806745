import Button from "../../Inputs/Button";

import classes from "../styles.module.scss";

const {
    modalContent__text,
    modalContent__button
} = classes;

const ApplicationSended = ({
    onClose = () => {}
}) => {


    return (
        <>
            <p className={modalContent__text}>
                Мы ознакомимся с Вашей заявкой и оповестим Вас об этом в СМС.
            </p>
            <p className={modalContent__text}>
                Также вы можете следить за статусом Вашей заявки в личном кабинете.
            </p>
            <Button className={modalContent__button} onClick={onClose}>
                Хорошо
            </Button>
        </>
    );
}

export default ApplicationSended;