import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../helpers/fetch";
import logOut from "../../helpers/logout";
import checkAuth from "../../helpers/checkAuth";
import LoadingComponent from "../../components/UI/ScreenLoader";
import Icon, { ColorIconEnam, IconEnum } from "../../components/StateLess/Icon";
import ConfirmTelephone from "../../components/Inputs/ConfirmTelephone";
import DocumentsContainer from "../../components/UI/DocumentsContainer";

import scss from "./index.module.scss";
import Banner from "../../components/PersonalArea/Banner";
import BannerGrid from "../../components/PersonalArea/BannerGrid";
import Button from "../../components/Inputs/Button";
import DropDownList from "../../components/Inputs/DropDownList";
import Input from "../../components/Inputs/Input";
import InputRadio from "../../components/Inputs/InputRadio";
import formatPhone from "../../helpers/formatPhone";
import { useDispatch } from "react-redux";
import { addModal } from "../../store/reducers/modals";
import Issue from "../../components/ModalContents/Issue";

const {
   approved,
   approved__subscription,
   approved__heading,
   approved__suptitle,
   approved__title,
   approved__confirm,
   approved__information,
   tranaction,
   tranaction__heading,
   tranaction__headingWrapper,
   tranaction__title,
   tranaction__subtitle,
   tranaction__icon,
   tranaction__form,
   container,
   container__button,
} = scss;

function Approved() {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState();
   const [isOnReg, setIsOnReg] = useState(1);
   const [bank, setBank] = useState(0);
   const [telephone, setTelephone] = useState(0);
   const [codeSended, setCodeSended] = useState(false);
   const [codeError, setCodeError] = useState(false);
   const [phoneError, setPhoneError] = useState(false);
   const yesNoArray = ["Да", "Нет"];

   const getData = async () => {
      const auth = checkAuth();

      if (!auth) {
         logOut();
         return;
      }

      const responce = await new api("sign", auth).send();

      if (responce.status === "success") {
         const { data } = responce;
         setData({ ...data });
         setLoading(true);
      } else {
         navigate("/profile");
      }
   };

   const confirm = async (code = 0) => {
      if (/^[0-9]{6}$/.test(code)) {
         setCodeSended(true);

         const body = new FormData();
         body.append("code", code);

         const auth = checkAuth();

         if (!auth) logOut();

         const resp = await new api("sign", auth, {
            method: "POST",
            body,
         }).send();

         if (resp.status === "success") {
            setData({
               phone_number: data?.phone_number,
               banks: resp.data.banks,
            });
            setCodeSended(false);
         } else {
            setCodeSended(false);
            if (resp.message === "Code is empty" || resp.message === "Code is invalid") {
               setCodeError("Указанный код не совпадает с кодом, отправленным в СМС.");
            } else {
               navigate("/profile");
            }
         }
      }
   };

   const okModal = (content) => {
      dispatch(
         addModal({
            title: "Отлично!",
            icon: <Icon className={""} name={IconEnum.ok} color={ColorIconEnam.base} />,
            content: (props) => <Issue {...props}>{content}</Issue>,
         })
      );
   };

   const errorModal = (content) => {
      dispatch(
         addModal({
            title: "Упссссс...",
            icon: (
               <Icon
                  className={""}
                  name={IconEnum.expressionless}
                  color={ColorIconEnam.prim}
               />
            ),
            content: (props) => <Issue {...props}>{content}</Issue>,
         })
      );
   };

   const payout = async (e) => {
      e.preventDefault();

      const auth = checkAuth();
      if (!auth) logOut();

      const body = new FormData();

      const phone = isOnReg === 0 ? data?.phone_number : telephone;

      body.append("phone", phone);
      body.append("bank", data?.banks[bank]);

      const resp = await new api("payout", auth, {
         method: "POST",
         body,
      }).send();

      if (resp.status === "success") {
         okModal(
            <>
               Заём будет зачислен на Ваш счёт в банке {data?.banks[bank]} через систему
               быстрых платежей по номеру {formatPhone(phone)} в течении 24 часов.
            </>
         );
         navigate("/");
      } else {
         if (resp.message === "Phone is empty" || resp.message === "Phone is invalid") {
            setPhoneError("Проверьте правильно ли указан номер телефона.");
         } else if (
            resp.message === "Bank is empty" ||
            resp.message === "Bank is invalid"
         ) {
         } else if (resp.message === "Can't payout loan") {
            errorModal(
               <>
                  "Что-то пошло не так в процессе подписания документов. Обратитесь в
                  службу поддержки."
               </>
            );
            navigate("/");
         } else {
            navigate("/profile");
         }
      }
   };

   const phoneInput = (e) => {
      setPhoneError(false);
      setTelephone(e);
   };

   useEffect(() => {
      getData();
      
      document.title = 'Подписание договора - ЭкспрессФинанс';

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return loading ? (
      <div className={approved}>
         {!data?.banks ? (
            <div className={approved__subscription}>
               <div className={approved__heading}>
                  <span className={approved__suptitle}>{`№ ${data?.number}`}</span>
                  <h2 className={approved__title}>Вам одобрен заём</h2>
                  <div className={approved__information}>
                     <Banner data={data} onlyBanner>
                        <BannerGrid
                           data={{
                              info: {
                                 next_payment_date: data?.next_payment_date,
                                 next_payment_amount: data?.next_payment_amount,
                                 rate: data?.rate,
                                 total_amount: data?.total_amount,
                              },
                           }}
                        />
                     </Banner>
                  </div>
               </div>
               <div className={approved__confirm}>
                  <ConfirmTelephone
                     number={data?.number}
                     phone_number={data?.phone_number}
                     onConfirm={confirm}
                     disableInput={codeSended}
                     inputError={codeError}
                  />
               </div>
               {data?.documents?.lenght > 0 || (
                  <DocumentsContainer list={data?.documents} />
               )}
            </div>
         ) : (
            <div className={tranaction}>
               <div className={approved__heading}>
                  <div className={tranaction__heading}>
                     <div className={tranaction__headingWrapper}>
                        <h2 className={tranaction__title}>Куда отправить деньги?</h2>
                        <span className={tranaction__subtitle}>
                           Выплата займа производится через систему быстрых платежей.
                           Деньги поступят на Ваш счёт в течении 2 часов.
                        </span>
                     </div>
                     <Icon className={tranaction__icon} name={IconEnum.sbp_min_text} />
                  </div>
                  {/* <div className={approved__information}>
                     <Banner data={data} onlyBanner>
                        <BannerGrid
                           data={{
                              info: {
                                 next_payment_date: data?.next_payment_date,
                                 next_payment_amount: data?.next_payment_amount,
                                 rate: data?.rate,
                                 total_amount: data?.total_amount,
                              },
                           }}
                        />
                     </Banner>
                  </div> */}
               </div>
               <form
                  className={tranaction__form}
                  action=""
                  method="post"
                  onSubmit={payout}
               >
                  <InputRadio
                     className={""}
                     label="Номер телефона в СБП совпадает с номером телефона указанным при регистрации?"
                     options={yesNoArray}
                     onChange={setIsOnReg}
                     name={"isOnReg"}
                     value={isOnReg}
                  />
                  <div className={container}>
                     <Input
                        placeholder={"Номер телефона"}
                        value={isOnReg === 0 ? data?.phone_number.toString() : telephone}
                        key={isOnReg}
                        onChange={isOnReg === 0 ? () => {} : phoneInput}
                        mask="+7 (XXX) XXX-XX-XX"
                        regex={/^[0-9]$/}
                        error={phoneError}
                        disabled={!isOnReg ? true : false}
                     />
                     <DropDownList
                        label={"Банк получателя"}
                        options={data?.banks}
                        value={bank}
                        onChange={setBank}
                     />
                  </div>
                  <Button
                     className={container__button}
                     type="submit"
                     disabled={
                        (!/^[0-9]{10}$/.test(telephone) && isOnReg === 1) ||
                        (!/^[0-9]{10}$/.test(data?.phone_number) && isOnReg === 0) ||
                        !/^[0-9а-яА-ЯёЁa-zA-Z "\-.()]{3,100}$/.test(data?.banks[bank])
                     }
                  >
                     Получить
                  </Button>
               </form>
            </div>
         )}
      </div>
   ) : (
      <LoadingComponent />
   );
}

export default Approved;
