import { useEffect } from "react";
import { useState } from "react";
import IconChevronLeft from "../Icons/IconChevronLeft";
import IconChevronRight from "../Icons/IconChevronRight";
import IconDoubleChevronLeft from "../Icons/IconDoubleChevronLeft";
import IconDoubleChevronRight from "../Icons/IconDoubleChevronRight";
import scss from "./index.module.scss";

const { list, list__button, list__button_active, list__icon, list__button_disabled } =
   scss;

function Pagination({ data, paginate }) {
   const [total, setTotal] = useState(data.page_count);
   const [endPage, setEndPage] = useState(data.page_count);
   const [startPage, setStartPage] = useState(1);

   useEffect(() => {
      setTotal(data.page_count);
      setEndPage(data.page_count);
      setStartPage(1);
   }, [data]);

   return endPage ? (
      <ul className={list}>
         <li className={list__button} onClick={() => paginate(startPage)}>
            <IconDoubleChevronLeft className={list__icon} />
         </li>
         <li
            className={
               data.page - 1 !== 0
                  ? list__button
                  : list__button + " " + list__button_disabled
            }
            onClick={data.page - 1 !== 0 ? () => paginate(data.page - 1) : null}
         >
            <IconChevronLeft className={list__icon} />
         </li>
         {data.page > 2 ? (
            <li
               className={
                  data.page !== startPage
                     ? list__button
                     : list__button + " " + list__button_active
               }
               onClick={() => paginate(startPage)}
            >
               {startPage}
            </li>
         ) : null}
         {data.page > 3 ? (
            <li className={list__button + " " + list__button_disabled}>
               <span>...</span>
            </li>
         ) : null}
         {data.page > 1 ? (
            <li
               className={
                  data.page !== data.page - 1
                     ? list__button
                     : list__button + " " + list__button_active
               }
               onClick={() => paginate(data.page - 1)}
            >
               {data.page - 1}
            </li>
         ) : null}
         {(data.page > 1 && data.page <= total - 1) ||
         data.page === 1 ||
         data.page === total ? (
            <li
               className={
                  data.page !== data.page
                     ? list__button
                     : list__button + " " + list__button_active
               }
               onClick={() => paginate(data.page)}
            >
               {data.page}
            </li>
         ) : null}
         {data.page <= total - 1 ? (
            <li
               className={
                  data.page !== data.page + 1
                     ? list__button
                     : list__button + " " + list__button_active
               }
               onClick={() => paginate(data.page + 1)}
            >
               {data.page + 1}
            </li>
         ) : null}

         {data.page <= total - 3 ? (
            <li className={list__button + " " + list__button_disabled}>
               <span>...</span>
            </li>
         ) : null}

         {data.page <= total - 2 ? (
            <li
               className={
                  data.page !== total
                     ? list__button
                     : list__button + " " + list__button_active
               }
               onClick={() => paginate(total)}
            >
               {total}
            </li>
         ) : null}
         <li
            className={
               data.page !== endPage
                  ? list__button
                  : list__button + " " + list__button_disabled
            }
            onClick={data.page !== endPage ? () => paginate(data.page + 1) : null}
         >
            <IconChevronRight className={list__icon} />
         </li>
         <li className={list__button} onClick={() => paginate(endPage)}>
            <IconDoubleChevronRight className={list__icon} />
         </li>
      </ul>
   ) : null;
}

export default Pagination;
