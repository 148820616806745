import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../Inputs/Button";
import Icon, { ColorIconEnam, IconEnum } from "../../StateLess/Icon";

import { removeModal } from "../../../store/reducers/modals";

import classes from "./styles.module.scss";

const {
    modals,
    modals__background,

    modal,
    modal_show,
    modal_fade,
    modal__wrapper,
    modal__header,
    modal__clodeButton,
    modal__closeIcon,
    modal__iconWrapper,
    modal__title,
    modal__content,
} = classes;

const Modals = () => {

    const [startShow, setStartShow] = useState(false);
    const [endShow, setEndShow] = useState(false);
    const [modalFade, setModalFade] = useState(false);

    const dispatch = useDispatch();

    const modalsArray = useSelector(state => state.modals.list);
    
    useEffect(() => {
        if (modalsArray.length > 0) {
            document.body.style.overflow = "hidden";
            show();
        }
        else {
            document.body.style.overflow = null;
            hide();
        }
    }, [modalsArray]);

    const show = () => {
        setStartShow(true);

        setTimeout(() => {
            setEndShow(true);
        }, 1);
    }

    const hide = () => {
        setEndShow(false);

        setTimeout(() => {
            setStartShow(false);
        }, 350);
    }

    const close = (i, callback = () => {}) => {
        if (modalsArray.length - 1 === i) {
            setModalFade(true);
    
            if (modalsArray.length === 1) {
                hide();
            }
    
            setTimeout(() => {
                setModalFade(false);
                dispatch(removeModal(i));
                
                if (modalsArray[i]?.onClose) {
                    modalsArray[i].onClose();
                    callback();
                }
            }, 350);
        }
    }

    useEffect(() => {
        const escapePress = (e) => {
            if (e.key === "Escape") {
                close(modalsArray.length - 1);
            }
        };

        document.addEventListener("keyup", escapePress, false);

        return () => {
            document.removeEventListener("keyup", escapePress, false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={modals} style={{display: !startShow ? "none" : null}}>
            {
                modalsArray.map(({
                    title = "",
                    icon = "",
                    content = "",
                    datetime = new Date().getTime()
                }, i) => (
                    <div key={i} className={modal__wrapper}>
                        {
                            i === modalsArray.length - 1 ? (
                                <div className={modals__background} style={{opacity: endShow ? 1 : null}} onClick={() => close(i)} />
                            ) : ""
                        }

                        <div
                            className={
                                modal +
                                ((modalFade) && i === modalsArray.length - 1 ? " " + modal_fade : "") +
                                (datetime > new Date().getTime() - 2000 ? " " + modal_show : "")
                            }
                        >
                            <div className={modal__header}>
                                <Button variant={"transparent"} className={modal__clodeButton} onClick={() => close(i)}>
                                    <Icon className={modal__closeIcon} name={IconEnum.close} color={ColorIconEnam.gray} />
                                </Button>
                            </div>

                            {
                                icon ? (
                                    <div className={modal__iconWrapper}>
                                        {icon}
                                    </div>
                                ) : ""
                            }

                            {
                                title ? (
                                    <h2 className={modal__title} dangerouslySetInnerHTML={{__html: title}} />
                                ) : ""
                            }

                            <div className={modal__content}>
                                {
                                    (
                                        () => {
                                            const Content = content;
                                            return <Content onClose={(n, callback) => close(i, callback)} />
                                        }
                                    )()
                                }
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default Modals;