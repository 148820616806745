function IconArrowSort(props) {
   return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
         <path d="M7.60797 3.93371C7.522 3.82918 7.4139 3.74503 7.29146 3.68734C7.16903 3.62965 7.03531 3.59985 6.89997 3.60011C6.78223 3.59943 6.66557 3.62245 6.55692 3.6678C6.44827 3.71316 6.34986 3.77992 6.26757 3.86411L2.66396 7.47371C2.49542 7.64246 2.40076 7.87121 2.40076 8.10971C2.40076 8.34821 2.49542 8.57696 2.66396 8.74571C2.74736 8.82945 2.84648 8.8959 2.95562 8.94124C3.06476 8.98658 3.18178 9.00992 3.29996 9.00992C3.41815 9.00992 3.53517 8.98658 3.64431 8.94124C3.75345 8.8959 3.85257 8.82945 3.93596 8.74571L5.99996 6.66851V19.5001C5.99996 19.7388 6.09479 19.9677 6.26357 20.1365C6.43235 20.3053 6.66127 20.4001 6.89997 20.4001C7.13866 20.4001 7.36758 20.3053 7.53636 20.1365C7.70514 19.9677 7.79996 19.7388 7.79996 19.5001V6.67931L9.86637 8.74091C9.94974 8.82447 10.0488 8.89077 10.1578 8.936C10.2668 8.98123 10.3837 9.00452 10.5018 9.00452C10.6198 9.00452 10.7367 8.98123 10.8457 8.936C10.9548 8.89077 11.0538 8.82447 11.1372 8.74091C11.3057 8.57216 11.4004 8.34341 11.4004 8.10491C11.4004 7.86641 11.3057 7.63766 11.1372 7.46891L7.60677 3.93251L7.60797 3.93371Z" fill="#010101"/>
         <path d="M17.4915 20.3129C17.6139 20.2552 17.722 20.171 17.808 20.0665H17.8068L21.3372 16.5313C21.5057 16.3625 21.6004 16.1338 21.6004 15.8953C21.6004 15.6568 21.5057 15.428 21.3372 15.2593C21.2538 15.1757 21.1547 15.1094 21.0457 15.0642C20.9367 15.019 20.8198 14.9957 20.7018 14.9957C20.5837 14.9957 20.4668 15.019 20.3578 15.0642C20.2488 15.1094 20.1497 15.1757 20.0664 15.2593L18 17.3209V4.5001C18 4.2614 17.9051 4.03248 17.7364 3.8637C17.5676 3.69492 17.3387 3.6001 17.1 3.6001C16.8613 3.6001 16.6323 3.69492 16.4636 3.8637C16.2948 4.03248 16.2 4.2614 16.2 4.5001V17.3329L14.1348 15.2545C14.0514 15.1708 13.9522 15.1043 13.8431 15.059C13.734 15.0136 13.6169 14.9903 13.4988 14.9903C13.3806 14.9903 13.2636 15.0136 13.1544 15.059C13.0453 15.1043 12.9462 15.1708 12.8628 15.2545C12.6942 15.4232 12.5995 15.652 12.5995 15.8905C12.5995 16.129 12.6942 16.3577 12.8628 16.5265L16.4676 20.1361C16.5499 20.2203 16.6483 20.2871 16.7569 20.3324C16.8656 20.3778 16.9822 20.4008 17.1 20.4001C17.2353 20.4004 17.369 20.3706 17.4915 20.3129Z" fill="#010101"/>
      </svg>

   );
}

export default IconArrowSort;
