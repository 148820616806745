import classes from './styles.module.scss';

const {
    tab
} = classes

const Tab = ({
    tabName = 'Вкладка',
    icon = null,
    children
}) => {


    return (
        <div className={tab}>
            {children}
        </div>
    );
}

export default Tab;