import React from "react";
import ReactLoading from "react-loading";

const LoadingComponent = () => (
   <div
      style={{
         minHeight: "70vh",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      }}
   >
      <ReactLoading type="spin" color="#2D9614" />
   </div>
);

export default LoadingComponent;
