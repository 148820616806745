import Icon, { ColorIconEnam, IconEnum } from "../../StateLess/Icon";

import scss from "./index.module.scss";

const {
   aboutLoanCard,
   aboutLoanCard__body,
   aboutLoanCard__about,
   aboutLoanCard__suptext,
   aboutLoanCard__title,
   aboutLoanCard__procent,
   aboutLoanCard__footer,
   aboutLoanCard__chips,
   aboutLoanCard__icon,
   aboutLoanCard__chips__body,
   aboutLoanCard__chips__text,
} = scss;

function AboutLoanCard({ data }) {
   return (
      <div className={aboutLoanCard}>
         <div className={aboutLoanCard__body}>
            <div className={aboutLoanCard__about}>
               <span className={aboutLoanCard__suptext}>
                  Долг по займу №{`${data?.current?.number}`}
               </span>
               <span className={aboutLoanCard__title}>
                  {`${(data?.current?.balance / 100)?.toLocaleString("ru-RU")} ₽`}
               </span>
            </div>
            <span className={aboutLoanCard__procent}>
               {`${data?.current?.rate}% в день`}
            </span>
         </div>
         <div className={aboutLoanCard__footer}>
            <div className={aboutLoanCard__chips}>
               <Icon
                  className={aboutLoanCard__icon}
                  name={IconEnum.ruble}
                  color={ColorIconEnam.gray}
               ></Icon>
               <div className={aboutLoanCard__chips__body}>
                  <span className={aboutLoanCard__chips__text}>Мин. платёж</span>
                  <span className={aboutLoanCard__chips__text}>
                     {data?.info?.total
                        ? `${parseFloat(data?.info?.total / 100).toLocaleString(
                             "ru-RU"
                          )} ₽`
                        : "Оплачен"}
                  </span>
               </div>
            </div>
            <div className={aboutLoanCard__chips}>
               <Icon
                  className={aboutLoanCard__icon}
                  name={IconEnum.calendar}
                  color={ColorIconEnam.gray}
               ></Icon>
               <div className={aboutLoanCard__chips__body}>
                  <span className={aboutLoanCard__chips__text}>Дата платежа</span>
                  <span className={aboutLoanCard__chips__text}>
                     {new Date(data?.info?.date)?.toLocaleDateString("RU-ru")}
                  </span>
               </div>
            </div>
         </div>
      </div>
   );
}

export default AboutLoanCard;
