import AdvantagesBlock from "./Contented/Advantages";
import Button from "./Contented/Button";
import DocumentsBlock from "./Contented/DocumentsBlock";
import ServicesList from "./Contented/ServicesList";
import ServicesCard from "./Contented/ServicesCard";
import InfoHTML from "./Contented/InfoHTML";
import Heading from "./Contented/Article/Heading";
import List from "./Contented/Article/List";
import SubHead from "./Contented/Article/SubHead";
import Text from "./Contented/Article/Text";
import IllustrationBlock from "./Contented/IllustrationBlock";
import ThreeDots from "./Contented/ThreeDots";
import MainDigitLine from "./Contented/MainDigitLine";
import YaRek from "./Contented/YaRek";

const library = {
   advantages: AdvantagesBlock,
   main_digit_line: MainDigitLine,
   three_dots: ThreeDots,
   button: Button,

   illustration_blocks: IllustrationBlock,

   documents_list: DocumentsBlock,

   services_two_columns: ServicesList,
   services_card: ServicesCard,
   info_html: InfoHTML,

   article_header: Heading,
   article_list: List,
   article_sub_header: SubHead,
   article_text: Text,

   yandex_reklama: YaRek,
};

export default library;
