import IconWarning from "../Icons/IconWarning";

import scss from "./index.module.scss";

const {
   notice,
   notice_full,
   notice_yellow,
   notice_green,
   notice__icon,
   notice__icon_full,
   notice__text,
   notice__text_full,
   notice__button,
   notice__button_yellow,
   notice__button_green,
   notice__button_full,
} = scss;

function LongNotice({ children, buttonText, onClick, size = "full" }) {
   if (size === "full") {
      return (
         <section className={notice + " " + notice_full}>
            <IconWarning className={notice__icon + " " + notice__icon_full} />
            <p className={notice__text + " " + notice__text_full}>{children}</p>
            {onClick && buttonText ? (
               <button
                  className={notice__button + " " + notice__button_full}
                  type="button"
                  onClick={onClick}
               >
                  {buttonText}
               </button>
            ) : null}
         </section>
      );
   } else if (size === "long") {
      return (
         <section className={notice}>
            <IconWarning className={notice__icon} />
            <p className={notice__text}>{children}</p>
            {onClick && buttonText ? (
               <button className={notice__button} type="button" onClick={onClick}>
                  {buttonText}
               </button>
            ) : null}
         </section>
      );
   } else if (size === "long_no_icon") {
      return (
         <section className={notice + " " + notice_green}>
            <p className={notice__text}>{children}</p>
            {onClick && buttonText ? (
               <button
                  className={notice__button + " " + notice__button_green}
                  type="button"
                  onClick={onClick}
               >
                  {buttonText}
               </button>
            ) : null}
         </section>
      );
   } else if (size === "long_no_icon_yellow") {
      return (
         <section className={notice + " " + notice_yellow}>
            <p className={notice__text}>{children}</p>
            {onClick && buttonText ? (
               <button
                  className={notice__button + " " + notice__button_yellow}
                  type="button"
                  onClick={onClick}
               >
                  {buttonText}
               </button>
            ) : null}
         </section>
      );
   } else if (size === "gray_double") {
      <section className={notice + " " + notice_yellow}>
         <p className={notice__text}>{children}</p>
         <button
            className={notice__button + " " + notice__button_yellow}
            type="button"
            onClick={onClick}
         >
            {buttonText}
         </button>
      </section>;
   }
}

export default LongNotice;
