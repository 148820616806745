import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import Icon, { ColorIconEnam, IconEnum } from "../../StateLess/Icon";
import { BankCard, CreditCard, TextModal } from "../../ModalContents";

import ActionButton from "../ActionButton";
import CardButton from "../CardButton";

import api from "../../../helpers/fetch";
import checkAuth from "../../../helpers/checkAuth";
import logOut from "../../../helpers/logout";

import { addModal } from "../../../store/reducers/modals";

import scss from "./index.module.scss";

const {
   listCards,
   listCards__addButton,
   listCards__promo,
   listCards__promo__body,
   listCards__promo__sale,
   listCards__promo__icon,
   listCards__promo__title,
   listCards__promo__text,
} = scss;

const ListCards = ({
   data,
   onDelete = () => {},
   onRename = () => {}
}) => {

   const dispatch = useDispatch();

   const addBankCard = async () => {

      const auth = checkAuth();

      if (!auth) logOut();

      const resp = await (new api("add_card", auth)).send();

      if (resp.status === 'success') {
         dispatch(addModal({
            title: "Добавление карты",
            icon: <Icon name={IconEnum.card} color={ColorIconEnam.base} />,
            content: props => <TextModal {...props}
               text="Вы будете перенаправлены на форму с&nbsp;вводом данных для&nbsp;добавления карты. Для&nbsp;подтверждения карты с&nbsp;Вашего счёта будет списан один&nbsp;рубль.<br /><br />Когда карта будет подтверждена, деньги вернуться на&nbsp;Ваш&nbsp;счёт."
               onClick={() => {
                  window.location.href = resp.redirect;
               }}
            />
         }));
      }
      else {
         dispatch(addModal({
            title: "Что-то пошло не так...",
            icon: <Icon name={IconEnum.expressionless} color={ColorIconEnam.prim} />,
            content: props => <TextModal {...props}
               text="Не удалось выполнить запрос для&nbsp;добавления карты. Попробуйте повторить попытку позже."
               buttonVariant="primary"
            />
         }));
      }
   }

   const showVirtualCard = () => {
      dispatch(addModal({
         title: "Реквизиты карты",
         icon: <Icon name={IconEnum.card} color={ColorIconEnam.base} />,
         content: (props) => <CreditCard {...props}
            number={data?.virtual_card?.number}
            expire={data?.virtual_card?.month?.padStart(2, '0') + "/20" + data?.virtual_card?.year}
            cvc={data?.virtual_card?.cvc}
         />
      }));
   }

   const showBankCard = (i) => {
      if (data?.bank_cards[i]) {
         dispatch(addModal({
            title: "Реквизиты карты",
            icon: <Icon name={IconEnum.card} color={ColorIconEnam.base} />,
            content: props => <BankCard {...props}
               id={data.bank_cards[i].id}
               title={data.bank_cards[i].title}
               number={data.bank_cards[i].number}
               expire={data.bank_cards[i].m.toString().padStart(2, '0') + "/20" + data.bank_cards[i].y}
               onDelete={() => onDelete(i)}
               onRename={title => onRename(i, title)}
            />
         }));
      }
   }

   return (
      <ul className={listCards}>
         <li>
            {data?.virtual_card?.status && data?.virtual_card?.status !== "none" ? (
               <>
                  {data?.virtual_card?.status === "active" ? (
                     <CardButton
                        iconName={IconEnum.ruble}
                        iconColor={ColorIconEnam.gray}
                        value={{
                           title: "Кредитная карта",
                           text: `•••• ${data?.virtual_card?.number
                              .toString()
                              .substring(12)}`,
                        }}
                        typeCard="publick"
                        action={showVirtualCard}
                     />
                  ) : null}
                  {data?.virtual_card?.status === "created" ? (
                     <Link className={listCards__promo} to="/virtual-card">
                        <Icon
                           className={listCards__promo__icon}
                           name={IconEnum.ruble}
                           color={"#ffffff"}
                        ></Icon>
                        <div className={listCards__promo__body}>
                           <span className={listCards__promo__title}>
                              Новая кредитная карта
                           </span>
                           <span className={listCards__promo__text}>Оформить сейчас</span>
                        </div>
                        <div className={listCards__promo__sale}></div>
                     </Link>
                  ) : null}
               </>
            ) : null}
         </li>
         <li>
            {
               data?.bank_cards?.map((card, i) => (
                  <CardButton
                     iconName={IconEnum.ruble}
                     iconColor={ColorIconEnam.gray}
                     value={{
                        title: card.title,
                        text: "•••• " + card.number
                     }}
                     typeCard="private"
                     nameCard={card.payment_system}
                     key={card.id}
                     action={() => showBankCard(i)}
                  />
               ))
            }
         </li>
         <li>
            <ActionButton
               className={listCards__addButton}
               value={"Добавить карту"}
               iconName={IconEnum.plus}
               iconColor={ColorIconEnam.gray}
               action={addBankCard}
               flip
            ></ActionButton>
         </li>
      </ul>
   );
}

export default ListCards;
