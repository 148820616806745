export const IconEnum = {
   arror_right: "arror-right",
   cash_getter: "cash-getter",
   document: "document",
   enter: "enter",
   menu: "menu",
   message: "message",
   ok: "ok",
   plus: "plus",
   qr_code: "qr-code",
   ruble: "ruble",
   sale: "sale",
   sbp_min: "sbp-min",
   services_menu: "services-menu",
   settings: "settings",
   upload: "upload",
   user: "user",
   user_speak: "user-speak",
   calendar: "calendar",
   card_arrow_up: "card-arrow-up",
   card: "card",
   check: "check",
   chewron_right: "chewron-right",
   close: "close",
   document_menu: "document-menu",
   about_menu: "about-menu",
   arrow_down: "arrow-down",
   arrow_up: "arrow-up",
   clock: "clock",
   clock_light: "clock-light",
   diploma: "diploma",
   download: "download",
   expressionless: "expressionless",
   home_menu: "home-menu",
   info: "info",
   lock: "lock",
   mir_express: "mir-express",
   card_other: "card-other",
   card_visa: "card-visa",
   card_mastercard: "card-mastercard",
   card_mir: "card-mir",
   rocket: "rocket",
   sewn: "sewn",
   mastercard: "mastercard",
   visa: "visa",
   sbp_min_text: "sbp-min-text",
   mir: "mir",
   e: "e",
   siren: "siren",
   copy: "copy",
   pasport: "pasport",
   question: "question"
};

export const ColorIconEnam = {
   base: "#5d9922",
   gray: "#898b87",
   red: "#c93c1d",
   secondary: "#db7309",
   prim: "#db7309",
};

function Icon({ name, color = "#898B87", className, width = "32", height = "32" }) {
   switch (name) {
      case "arror-right": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M2 16.333h28m0 0L20.4 7m9.6 9.333-9.6 9.334"
               />
            </svg>
         );
      }
      case "copy": {
         return (
            <svg
               width="32"
               height="32"
               viewBox="0 0 32 32"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
               className={className}
            >
               <path
                  d="M23.4326 25.7478C23.7724 25.7478 24.0479 25.4723 24.0479 25.1325V10.7037C24.0479 10.3639 23.7724 10.0883 23.4326 10.0883H12.4081C12.0682 10.0883 11.7927 10.3638 11.7927 10.7037V25.1324C11.7927 25.4723 12.0682 25.7478 12.4081 25.7478H23.4326ZM24.0479 7.88867C24.6415 7.88867 25.1652 8.11562 25.6191 8.56952C26.073 8.9885 26.3 9.49477 26.3 10.0883V25.7478C26.3 26.3414 26.073 26.8651 25.6191 27.319C25.1652 27.7729 24.6415 27.9999 24.0479 27.9999H11.7927C11.1991 27.9999 10.6754 27.7729 10.2215 27.319C9.7676 26.8651 9.54065 26.3414 9.54065 25.7478V10.0883C9.54065 9.49477 9.7676 8.9885 10.2215 8.56952C10.6754 8.11562 11.1991 7.88867 11.7927 7.88867H24.0479Z"
                  fill={color}
               />
               <path
                  opacity="0.5"
                  d="M21.3115 5.63665V4.61538C21.3115 4.27551 21.036 4 20.6961 4L10.6274 4.00012C7.90846 4.00016 5.70437 6.20427 5.70437 8.92317L5.70435 21.2961C5.70435 21.636 5.97986 21.9115 6.31973 21.9115H7.28863C7.62849 21.9115 7.90401 21.636 7.90401 21.2961V9.32894C7.90401 7.62961 9.28159 6.25203 10.9809 6.25203H20.6961C21.036 6.25203 21.3115 5.97651 21.3115 5.63665Z"
                  fill={color}
               />
            </svg>
         );
      }
      case "cash-getter": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M12.333 27.666h8c2.514 0 3.771 0 4.552-.78.628-.629.751-1.566.775-3.22H7.005c.024 1.654.147 2.591.775 3.22.781.78 2.039.78 4.553.78z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M15.333 9h-3c-2.514 0-3.77 0-4.552.781C7 10.563 7 11.82 7 14.333v8c0 .491 0 .934.005 1.334h18.656c.006-.4.006-.843.006-1.334v-8c0-2.514 0-3.77-.782-4.552C24.104 9 22.848 9 20.333 9h-3v6.63l.907-1.058a1 1 0 1 1 1.52 1.301l-2.667 3.111a1 1 0 0 1-1.52 0l-2.666-3.11a1 1 0 1 1 1.52-1.302l.906 1.059V9z"
               />
               <path
                  fill={color}
                  d="M24.481 5H8.187C5.32 5 3 7.4 3 10.36c0 2.54 1.707 4.667 4 5.22v-1.247c0-2.514 0-3.77.781-4.552C8.563 9 9.82 9 12.333 9h8c2.515 0 3.771 0 4.552.781.782.782.782 2.038.782 4.552v1.247c2.293-.553 4-2.68 4-5.22C29.667 7.4 27.345 5 24.48 5z"
                  opacity={0.2}
               />
            </svg>
         );
      }
      case "document": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  fillRule="evenodd"
                  d="M19 29.667h-5.333c-5.028 0-7.543 0-9.104-1.563C3 26.543 3 24.028 3 19v-5.333c0-5.028 0-7.543 1.563-9.104C6.124 3 8.652 3 13.707 3c.808 0 1.454 0 2 .023a2.013 2.013 0 0 0-.027.325l-.013 3.779c0 1.462 0 2.756.14 3.797.152 1.13.5 2.259 1.422 3.181.92.92 2.051 1.27 3.18 1.422 1.042.14 2.335.14 3.798.14h5.402c.058.712.058 1.586.058 2.75V19c0 5.028 0 7.543-1.563 9.104-1.561 1.563-4.076 1.563-9.104 1.563z"
                  clipRule="evenodd"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M8.333 18.667a1 1 0 0 0 0 2H19a1 1 0 1 0 0-2H8.333zm0 4.666a1 1 0 0 0 0 2h7.334a1 1 0 0 0 0-2H8.333zM15.68 3.348l-.013 3.78c0 1.462 0 2.755.14 3.797.152 1.13.5 2.259 1.422 3.18.92.921 2.05 1.27 3.18 1.423 1.042.14 2.335.14 3.798.14h5.402c.018.207.03.428.038.667h.02c0-.358 0-.537-.014-.747a7.096 7.096 0 0 0-1.277-3.521 8.437 8.437 0 0 0-.38-.477c-1.057-1.264-2.45-2.84-3.663-3.922-1.08-.965-2.561-2.02-3.853-2.883-1.11-.74-1.664-1.11-2.425-1.386a7.315 7.315 0 0 0-.675-.205c-.512-.126-1.01-.17-1.713-.186l.013.34z"
               />
            </svg>
         );
      }
      case "enter": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M28.675 16.737a1.002 1.002 0 0 0 0-1.476L22.84 9.928a1 1 0 0 0-1.348 1.477L25.427 15h-14.76a1 1 0 1 0 0 2h14.757l-3.933 3.596a1 1 0 1 0 1.35 1.476l5.834-5.335z"
               />
               <path
                  fill={color}
                  d="M12 5a1 1 0 1 0 0-2h-.073c-1.823 0-3.294 0-4.45.156-1.2.16-2.21.507-3.013 1.308-.803.803-1.147 1.813-1.31 3.013C3 8.633 3 10.104 3 11.927v8.146c0 1.823 0 3.294.155 4.45.162 1.2.506 2.21 1.309 3.013.803.803 1.813 1.147 3.013 1.31 1.156.154 2.627.154 4.45.154H12a1 1 0 0 0 0-2c-1.913 0-3.248-.003-4.256-.137-.979-.132-1.496-.374-1.865-.742-.37-.369-.61-.886-.742-1.866C5.003 23.248 5 21.913 5 20v-8c0-1.913.003-3.25.137-4.256.132-.979.374-1.496.742-1.865.369-.37.886-.61 1.866-.742C8.752 5.003 10.087 5 12 5z"
               />
            </svg>
         );
      }
      case "menu": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M16 29.333c-6.285 0-9.428 0-11.381-1.953C2.667 25.43 2.667 22.285 2.667 16c0-6.286 0-9.428 1.952-11.381C6.573 2.667 9.715 2.667 16 2.667s9.428 0 11.38 1.952c1.953 1.954 1.953 5.095 1.953 11.38 0 6.286 0 9.429-1.953 11.38-1.95 1.954-5.095 1.954-11.38 1.954z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M25 10.666a1 1 0 0 1-1 1H8a1 1 0 0 1 0-2h16a1 1 0 0 1 1 1zM25 16a1 1 0 0 1-1 1H8a1 1 0 1 1 0-2h16a1 1 0 0 1 1 1zm0 5.333a1 1 0 0 1-1 1H8a1 1 0 0 1 0-2h16a1 1 0 0 1 1 1z"
               />
            </svg>
         );
      }
      case "message": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="m18.172 27.296-.723 1.221c-.644 1.088-2.253 1.088-2.898 0l-.723-1.221c-.56-.947-.84-1.421-1.29-1.683-.451-.262-1.018-.272-2.151-.292-1.675-.028-2.724-.13-3.604-.496a6.667 6.667 0 0 1-3.608-3.608c-.508-1.224-.508-2.777-.508-5.884V14c0-4.364 0-6.547.982-8.15a6.667 6.667 0 0 1 2.2-2.2c1.604-.983 3.788-.983 8.151-.983h4c4.364 0 6.547 0 8.15.982a6.666 6.666 0 0 1 2.2 2.2c.983 1.604.983 3.788.983 8.15v1.334c0 3.107 0 4.66-.506 5.884a6.666 6.666 0 0 1-3.61 3.608c-.88.366-1.929.467-3.604.496-1.133.02-1.7.03-2.15.292-.45.262-.73.735-1.29 1.683z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M22.667 14.666a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0zm-5.334 0a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.667 0zm-5.333 0a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0z"
               />
            </svg>
         );
      }
      case "ok": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M12.79 4.267c-.21.19-.431.367-.66.532a3.64 3.64 0 0 1-1.314.544c-.204.04-.417.057-.843.09-1.068.086-1.602.129-2.048.286A3.617 3.617 0 0 0 5.72 7.926c-.158.445-.2.98-.286 2.048a7.612 7.612 0 0 1-.09.842c-.094.47-.278.916-.544 1.313-.116.174-.255.337-.532.66-.695.816-1.043 1.225-1.247 1.651a3.624 3.624 0 0 0 0 3.12c.204.427.552.835 1.247 1.65.277.325.416.488.532.66.266.398.45.845.544 1.314.04.204.057.418.09.843.086 1.068.128 1.602.286 2.048a3.618 3.618 0 0 0 2.206 2.207c.446.157.98.2 2.048.285.426.033.639.05.843.09.47.094.916.28 1.313.544.174.116.336.255.66.532.816.695 1.224 1.043 1.651 1.247.987.47 2.133.47 3.12 0 .427-.204.835-.552 1.65-1.247.325-.277.487-.416.66-.532.398-.266.845-.45 1.314-.544.204-.04.417-.057.843-.09 1.068-.085 1.602-.128 2.048-.285a3.618 3.618 0 0 0 2.206-2.207c.158-.445.2-.98.286-2.048.033-.425.05-.639.09-.843a3.65 3.65 0 0 1 .544-1.313c.116-.173.255-.336.532-.66.695-.816 1.043-1.224 1.247-1.65.47-.988.47-2.134 0-3.12-.204-.428-.552-.836-1.247-1.652-.19-.21-.367-.43-.532-.66a3.618 3.618 0 0 1-.544-1.313 7.611 7.611 0 0 1-.09-.842c-.086-1.068-.128-1.603-.286-2.048a3.618 3.618 0 0 0-2.206-2.207c-.446-.157-.98-.2-2.048-.285a7.611 7.611 0 0 1-.843-.091 3.616 3.616 0 0 1-1.313-.544 7.623 7.623 0 0 1-.66-.532c-.816-.695-1.224-1.043-1.651-1.247a3.617 3.617 0 0 0-3.12 0c-.427.204-.835.552-1.65 1.247z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M21.832 13.151a1.086 1.086 0 0 0-1.535-1.535l-6.466 6.467-2.127-2.127a1.085 1.085 0 0 0-1.535 1.535l2.894 2.893a1.085 1.085 0 0 0 1.533 0l7.236-7.233z"
               />
            </svg>
         );
      }
      case "plus": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M29.333 16c0 7.364-5.969 13.333-13.333 13.333S2.667 23.363 2.667 16C2.667 8.636 8.637 2.666 16 2.666c7.364 0 13.333 5.97 13.333 13.334z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M17 12a1 1 0 0 0-2 0v3h-3a1 1 0 0 0 0 2h3v3a1 1 0 0 0 2 0v-3h3a1 1 0 0 0 0-2h-3v-3z"
               />
            </svg>
         );
      }
      case "qr-code": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M14.404 18.263c-.565-.566-1.267-.795-2.047-.9-.737-.099-1.666-.099-2.781-.099H8.103c-.774 0-1.42 0-1.946.05-.548.053-1.06.165-1.528.453-.46.28-.845.666-1.126 1.125-.288.47-.4.98-.454 1.53C3 20.944 3 21.591 3 22.366v.09c0 1.179 0 2.13.073 2.894.075.789.234 1.477.612 2.094a4.65 4.65 0 0 0 1.536 1.536c.618.379 1.306.538 2.094.614.765.072 1.717.072 2.894.072h.094c.773 0 1.418 0 1.944-.05.549-.053 1.06-.165 1.529-.453.459-.28.844-.667 1.125-1.127.288-.468.4-.98.454-1.528.049-.525.049-1.172.049-1.946V23.09c0-1.115 0-2.044-.099-2.782-.105-.778-.336-1.481-.901-2.046zM12.245 3.049c.55.054 1.06.166 1.53.454.458.28.844.666 1.125 1.126.288.468.4.98.453 1.528.05.526.05 1.172.05 1.947v1.472c0 1.115 0 2.044-.099 2.781-.105.779-.335 1.482-.9 2.047-.565.565-1.267.795-2.047.9-.737.099-1.666.099-2.781.099H8.103c-.774 0-1.42 0-1.946-.05-.548-.053-1.06-.165-1.528-.453a3.412 3.412 0 0 1-1.126-1.125c-.288-.47-.4-.98-.454-1.53C3 11.72 3 11.075 3 10.3v-.09c0-1.18 0-2.13.073-2.894.075-.79.234-1.477.612-2.095a4.648 4.648 0 0 1 1.536-1.536c.618-.378 1.306-.537 2.094-.613C8.08 3 9.032 3 10.209 3h.094c.773 0 1.418 0 1.944.05h-.002zM22.457 3h-.09c-.774 0-1.42 0-1.947.05-.547.053-1.059.165-1.528.453-.459.28-.844.666-1.125 1.126-.288.468-.4.98-.454 1.528-.049.526-.049 1.172-.049 1.947v1.472c0 1.115 0 2.044.099 2.781.105.779.334 1.482.9 2.047.565.565 1.266.795 2.046.9.738.099 1.667.099 2.782.099h1.473c.773 0 1.42 0 1.945-.05.548-.053 1.06-.165 1.528-.453.46-.28.846-.667 1.127-1.125.288-.47.4-.98.453-1.53.05-.525.05-1.17.05-1.945v-.09c0-1.18 0-2.13-.074-2.894-.074-.79-.233-1.477-.612-2.095a4.651 4.651 0 0 0-1.536-1.536c-.617-.378-1.305-.537-2.093-.613C24.587 3 23.636 3 22.457 3z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M19.124 28.736a.93.93 0 1 1-1.861 0v-3.72h1.861v3.72z"
                  opacity={0.4}
               />
               <path
                  fill={color}
                  d="M28.736 17.264a.93.93 0 0 0-.93.93v4.34h1.86v-4.34a.93.93 0 0 0-.93-.93z"
                  opacity={0.5}
               />
               <path
                  fill={color}
                  d="M21.768 22.49c-.101.245-.101.555-.101 1.177 0 .62 0 .931.101 1.177.136.326.395.585.721.72.246.102.556.102 1.178.102.621 0 .932 0 1.177-.1.326-.136.585-.396.72-.722.103-.245.103-.556.103-1.178 0-.62 0-.931-.102-1.177a1.333 1.333 0 0 0-.72-.72c-.246-.102-.557-.102-1.178-.102-.622 0-.932 0-1.178.1a1.336 1.336 0 0 0-.72.722z"
               />
               <path
                  fill={color}
                  d="M29.667 25.047v-.03h-1.862c0 .59 0 .986-.021 1.292-.02.3-.057.444-.097.54a1.551 1.551 0 0 1-.839.84c-.096.04-.24.075-.54.096-.307.02-.703.022-1.293.022h-2.48v1.86h2.51c.552 0 1.012 0 1.39-.027a3.492 3.492 0 0 0 1.125-.233 3.41 3.41 0 0 0 1.845-1.846c.15-.36.208-.732.235-1.125a21 21 0 0 0 .027-1.39z"
                  opacity={0.7}
               />
               <path
                  fill={color}
                  d="M17.263 22.488v.047h1.861c0-.89 0-1.488.047-1.944.045-.44.124-.643.213-.777a1.54 1.54 0 0 1 .428-.427c.135-.09.339-.17.779-.215.456-.047 1.053-.048 1.944-.048h2.48v-1.86h-2.528c-.831 0-1.523 0-2.084.058-.587.058-1.134.189-1.624.517-.374.25-.692.568-.942.941-.328.49-.457 1.036-.517 1.623-.057.561-.057 1.253-.057 2.085z"
                  opacity={0.6}
               />
               <path
                  fill={color}
                  d="M7.677 25.253c.292.207.704.207 1.528.207.826 0 1.238 0 1.528-.207a1.12 1.12 0 0 0 .267-.265c.205-.29.205-.703.205-1.528 0-.824 0-1.236-.206-1.528a1.126 1.126 0 0 0-.266-.265c-.29-.207-.702-.207-1.528-.207-.824 0-1.236 0-1.528.206a1.123 1.123 0 0 0-.265.267c-.207.29-.207.703-.207 1.527 0 .825 0 1.237.207 1.528.073.103.163.193.267.267l-.002-.002zm1.528-14.046c-.824 0-1.236 0-1.528-.207a1.12 1.12 0 0 1-.265-.267c-.207-.29-.207-.703-.207-1.526 0-.826 0-1.238.207-1.528a1.12 1.12 0 0 1 .267-.267c.29-.205.702-.205 1.526-.205.826 0 1.238 0 1.528.206.103.073.194.163.267.266.205.29.205.702.205 1.528 0 .823 0 1.237-.206 1.527a1.118 1.118 0 0 1-.266.266c-.29.207-.702.207-1.528.207zM21.933 11c.291.207.703.207 1.528.207.824 0 1.236 0 1.528-.207.103-.073.193-.163.266-.265.206-.291.206-.704.206-1.528 0-.826 0-1.238-.206-1.528a1.124 1.124 0 0 0-.267-.267c-.29-.205-.703-.205-1.527-.205-.825 0-1.237 0-1.528.206a1.122 1.122 0 0 0-.266.266c-.206.29-.206.702-.206 1.528 0 .823 0 1.237.207 1.527.073.103.163.193.265.266z"
               />
            </svg>
         );
      }
      case "ruble": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  fillRule="evenodd"
                  d="M29.667 16.333c0 7.364-5.97 13.334-13.334 13.334S3 23.697 3 16.333 8.97 3 16.333 3c7.364 0 13.334 5.97 13.334 13.333z"
                  clipRule="evenodd"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  fillRule="evenodd"
                  d="M12.333 18a1 1 0 0 0 0 2h.334v3a1 1 0 1 0 2 0v-3h1.666a1 1 0 1 0 0-2h-1.666v-.667h3.666a4.334 4.334 0 0 0 0-8.666h-3.117c-.195 0-.396 0-.581.029a2.333 2.333 0 0 0-1.94 1.938c-.028.186-.028.387-.028.582v4.117h-.334a1 1 0 1 0 0 2h.334V18h-.334zm6-2.667h-3.666v-4.33l.004-.058a.333.333 0 0 1 .274-.274c.107-.003.214-.005.32-.005h3.068a2.333 2.333 0 1 1 0 4.667z"
                  clipRule="evenodd"
               />
            </svg>
         );
      }
      case "sale": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M13.122 4.6c-.21.19-.43.367-.66.532a3.61 3.61 0 0 1-1.313.544c-.204.04-.417.058-.843.091-1.068.085-1.602.128-2.048.285A3.617 3.617 0 0 0 6.052 8.26c-.158.445-.2.98-.286 2.048a7.61 7.61 0 0 1-.09.843 3.646 3.646 0 0 1-.544 1.313c-.116.173-.255.336-.532.66-.695.816-1.043 1.224-1.247 1.65a3.624 3.624 0 0 0 0 3.12c.204.427.552.835 1.247 1.651.277.324.416.487.532.66.266.398.45.844.544 1.314.04.204.057.417.09.842.086 1.068.128 1.603.286 2.048a3.618 3.618 0 0 0 2.206 2.207c.446.157.98.2 2.048.285.426.034.639.051.843.091.47.093.916.279 1.313.544.174.116.336.255.66.532.816.695 1.224 1.043 1.651 1.247.987.47 2.133.47 3.12 0 .427-.204.835-.552 1.65-1.247.325-.277.487-.416.66-.532a3.64 3.64 0 0 1 1.314-.544c.204-.04.417-.057.843-.09 1.068-.086 1.602-.129 2.048-.286a3.618 3.618 0 0 0 2.206-2.207c.158-.445.2-.98.286-2.048.033-.425.05-.638.09-.842a3.65 3.65 0 0 1 .544-1.314c.116-.173.255-.336.532-.66.695-.816 1.043-1.224 1.247-1.65.47-.987.47-2.134 0-3.12-.204-.427-.552-.835-1.247-1.651-.19-.21-.367-.43-.532-.66a3.618 3.618 0 0 1-.544-1.313 7.611 7.611 0 0 1-.09-.843c-.086-1.068-.128-1.603-.286-2.048a3.617 3.617 0 0 0-2.206-2.207c-.446-.157-.98-.2-2.048-.285a7.61 7.61 0 0 1-.843-.09 3.618 3.618 0 0 1-1.313-.545 7.64 7.64 0 0 1-.66-.532c-.816-.694-1.224-1.042-1.651-1.246a3.617 3.617 0 0 0-3.12 0c-.427.204-.835.552-1.65 1.246z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M21.44 11.227a1.085 1.085 0 0 1 0 1.535l-8.68 8.68a1.085 1.085 0 0 1-1.535-1.534l8.68-8.68a1.086 1.086 0 0 1 1.536 0h-.001zm-.043 8.726a1.447 1.447 0 1 1-2.893 0 1.447 1.447 0 0 1 2.893 0zm-8.681-5.788a1.447 1.447 0 1 0 0-2.894 1.447 1.447 0 0 0 0 2.893z"
               />
            </svg>
         );
      }
      case "sbp-min": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <g clipPath="url(#a)">
                  <path
                     fill="#5B57A2"
                     d="m3 6.965 3.875 6.927v4.225l-3.87 6.913L3 6.965z"
                  />
                  <path
                     fill="#D90751"
                     d="m17.88 11.372 3.63-2.226 7.432-.007-11.063 6.777v-4.544z"
                  />
                  <path
                     fill="#FAB718"
                     d="m17.859 6.925.02 9.17-3.884-2.386V0l3.864 6.925z"
                  />
                  <path
                     fill="#ED6F26"
                     d="m28.942 9.139-7.432.007-3.651-2.221L13.995 0l14.947 9.139z"
                  />
                  <path
                     fill="#63B22F"
                     d="M17.88 25.069v-4.45l-3.885-2.341L13.997 32l3.882-6.931z"
                  />
                  <path
                     fill="#1487C9"
                     d="M21.501 22.863 6.875 13.892 3 6.965l25.926 15.89-7.425.008z"
                  />
                  <path
                     fill="#017F36"
                     d="m13.997 32 3.882-6.932 3.622-2.205 7.425-.009L13.997 32z"
                  />
                  <path
                     fill="#984995"
                     d="m3.005 25.03 11.022-6.752-3.706-2.274-3.446 2.113-3.87 6.913z"
                  />
               </g>
               <defs>
                  <clipPath id="a">
                     <path fill="#fff" d="M3 0h26.133v32H3z" />
                  </clipPath>
               </defs>
            </svg>
         );
      }
      case "services-menu": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M3 16.333c0-6.285 0-9.428 1.952-11.381C6.907 3 10.048 3 16.333 3c6.286 0 9.428 0 11.38 1.952 1.954 1.955 1.954 5.096 1.954 11.381 0 6.286 0 9.428-1.954 11.38-1.95 1.954-5.094 1.954-11.38 1.954-6.285 0-9.428 0-11.381-1.954C3 25.763 3 22.62 3 16.333z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  fillRule="evenodd"
                  d="M9.552 15.333H3v2h10.884a4.334 4.334 0 0 1-4.217 3.334 1 1 0 1 0 0 2 6.334 6.334 0 0 0 5.666-3.502v10.502h2V19.165A6.334 6.334 0 0 0 23 22.667a1 1 0 0 0 0-2 4.335 4.335 0 0 1-4.217-3.334h10.884v-2h-6.551a5.328 5.328 0 0 0 1.008-2.036c.717-2.87-1.884-5.47-4.755-4.753a5.316 5.316 0 0 0-2.036 1.008V3h-2v6.552a5.316 5.316 0 0 0-2.036-1.008c-2.87-.717-5.47 1.883-4.753 4.753.19.763.54 1.452 1.008 2.036zm5.781 0h-1.67a3.33 3.33 0 0 1-3.179-2.521 1.92 1.92 0 0 1 2.33-2.328 3.33 3.33 0 0 1 2.52 3.179v1.67zm3.671 0h-1.67v-1.66a3.33 3.33 0 0 1 2.52-3.189 1.92 1.92 0 0 1 2.329 2.328 3.33 3.33 0 0 1-3.179 2.521z"
                  clipRule="evenodd"
               />
            </svg>
         );
      }
      case "settings": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  fillRule="evenodd"
                  d="M18.04 3.203C17.545 3 16.92 3 15.666 3c-1.252 0-1.879 0-2.372.203-.657.27-1.18.789-1.453 1.444-.126.297-.174.645-.194 1.15a2.154 2.154 0 0 1-1.06 1.804 2.187 2.187 0 0 1-2.106.011c-.45-.237-.778-.368-1.1-.41a2.701 2.701 0 0 0-1.987.527c-.424.323-.737.862-1.363 1.938s-.938 1.613-1.009 2.14a2.65 2.65 0 0 0 .533 1.972c.198.256.476.47.907.74.636.396 1.044 1.07 1.044 1.814s-.408 1.419-1.043 1.814c-.432.27-.71.485-.909.741a2.654 2.654 0 0 0-.532 1.972c.07.525.383 1.064 1.01 2.14.626 1.076.938 1.613 1.362 1.937.565.431 1.28.62 1.987.528.322-.042.65-.173 1.1-.41a2.186 2.186 0 0 1 2.106.01c.648.374 1.032 1.06 1.06 1.804.02.507.068.854.194 1.151a2.674 2.674 0 0 0 1.453 1.444c.493.203 1.12.203 2.372.203 1.252 0 1.879 0 2.372-.203a2.677 2.677 0 0 0 1.453-1.444c.126-.297.174-.644.194-1.15.026-.745.412-1.433 1.061-1.805a2.187 2.187 0 0 1 2.105-.01c.451.237.778.368 1.1.41a2.697 2.697 0 0 0 1.987-.528c.424-.322.737-.861 1.363-1.937s.938-1.613 1.01-2.14a2.653 2.653 0 0 0-.534-1.972c-.198-.256-.476-.47-.907-.74-.636-.396-1.044-1.07-1.044-1.815 0-.744.408-1.418 1.043-1.813.432-.27.71-.485.91-.741a2.654 2.654 0 0 0 .531-1.972c-.07-.526-.383-1.064-1.01-2.14-.626-1.076-.938-1.614-1.362-1.938a2.702 2.702 0 0 0-1.987-.528c-.322.043-.649.174-1.1.411a2.186 2.186 0 0 1-2.106-.01 2.153 2.153 0 0 1-1.06-1.805c-.02-.506-.068-.853-.194-1.15a2.675 2.675 0 0 0-1.453-1.444z"
                  clipRule="evenodd"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M19.698 16.334c0 2.209-1.806 4-4.03 4-2.227 0-4.031-1.791-4.031-4 0-2.21 1.803-4 4.03-4 2.227 0 4.03 1.79 4.03 4z"
               />
            </svg>
         );
      }
      case "upload": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M3 16.333C3 8.97 8.97 3 16.333 3c7.364 0 13.334 5.97 13.334 13.333 0 7.364-5.97 13.334-13.334 13.334S3 23.697 3 16.332z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M15.333 23.333a1 1 0 0 0 2 0v-6.92l2.294 2.293a1 1 0 0 0 1.413-1.413l-4-4a1 1 0 0 0-1.413 0l-4 4a1.003 1.003 0 0 0-.026 1.439.998.998 0 0 0 1.44-.026l2.292-2.293v6.92zM21.667 11a1 1 0 0 0 0-2H11a1 1 0 1 0 0 2h10.667z"
               />
            </svg>
         );
      }
      case "user": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M29.667 16.333c0 7.364-5.97 13.334-13.334 13.334S3 23.697 3 16.333 8.97 3 16.333 3c7.364 0 13.334 5.97 13.334 13.333z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M22.743 25.681a11.28 11.28 0 0 1-6.41 1.986 11.28 11.28 0 0 1-6.409-1.986c-.805-.553-1.15-1.606-.68-2.463.97-1.778 2.97-2.884 7.09-2.884 4.12 0 6.12 1.106 7.09 2.884.467.857.124 1.91-.681 2.463zm-6.41-9.348a4 4 0 1 0 0-7.999 4 4 0 0 0 0 8z"
               />
            </svg>
         );
      }
      case "user-speak": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M24.476 2.819a1 1 0 0 1 1.372-.343l.004.003.004.002.013.008a6.171 6.171 0 0 1 .521.384 7.13 7.13 0 0 1 1.07 1.118C28.236 5 29 6.543 29 8.667s-.764 3.666-1.54 4.676c-.387.501-.773.87-1.07 1.117-.16.134-.328.259-.503.373l-.018.011-.013.008-.004.003-.003.001s-.001.001-.516-.856l.515.857a1 1 0 0 1-1.035-1.71l.007-.006.053-.036a3.54 3.54 0 0 0 .236-.18 5.18 5.18 0 0 0 .764-.8C26.431 11.4 27 10.275 27 8.666c0-1.61-.57-2.734-1.127-3.458a5.07 5.07 0 0 0-.764-.8 3.554 3.554 0 0 0-.289-.217l-.007-.004a1 1 0 0 1-.337-1.37zM13.333 14a5.333 5.333 0 1 0 0-10.667 5.333 5.333 0 0 0 0 10.667z"
               />
               <path
                  fill={color}
                  d="M23.181 5.143a1 1 0 0 0-1.373.342l.333 1.366.01.006c.147.111.278.241.39.387.31.409.471.91.459 1.423a2.267 2.267 0 0 1-.46 1.424 2.031 2.031 0 0 1-.39.386l-.01.006a1 1 0 0 0 1.04 1.708h.003l.002-.002.004-.002.01-.007.026-.016c.102-.065.198-.137.29-.216a4.02 4.02 0 0 0 .612-.639A4.267 4.267 0 0 0 25 8.667a4.267 4.267 0 0 0-.873-2.643 4.042 4.042 0 0 0-.902-.855l-.026-.016-.01-.005-.004-.003-.002-.001s-.002-.001-.516.856l.514-.857z"
               />
               <path
                  fill={color}
                  d="M2.667 24c0 3.313 0 6 10.666 6C24 30 24 27.313 24 24s-4.776-6-10.667-6c-5.89 0-10.666 2.687-10.666 6z"
                  opacity={0.2}
               />
            </svg>
         );
      }
      case "calendar": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M9.28 2.667c.557 0 1.008.413 1.008.922v1.864c.893-.016 1.896-.016 3.024-.016h5.376c1.128 0 2.13 0 3.024.016V3.59c0-.51.45-.922 1.008-.922.557 0 1.008.413 1.008.922v1.944c1.933.141 3.204.49 4.137 1.344.934.855 1.314 2.018 1.468 3.79V12H2.667v-1.334c.154-1.771.534-2.933 1.468-3.789.933-.853 2.202-1.202 4.137-1.344V3.59c0-.51.452-.922 1.008-.922z"
               />
               <path
                  fill={color}
                  d="M29.333 18.667V16c0-1.119-.017-3.113-.034-4H2.675c-.018.887 0 2.881 0 4v2.667c0 5.028 0 7.542 1.56 9.104 1.564 1.562 4.077 1.562 9.104 1.562h5.333c5.027 0 7.539 0 9.101-1.562 1.56-1.562 1.56-4.076 1.56-9.104z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  fillRule="evenodd"
                  d="M18.667 16.333a2.333 2.333 0 0 0-2.334 2.333v2.667a2.333 2.333 0 1 0 4.667 0v-2.667a2.333 2.333 0 0 0-2.333-2.333zm0 2a.333.333 0 0 0-.334.333v2.667a.333.333 0 1 0 .667 0v-2.667a.334.334 0 0 0-.333-.333z"
                  clipRule="evenodd"
               />
               <path
                  fill={color}
                  d="M15 17.333a1 1 0 0 0-1.707-.707l-2 2a1 1 0 0 0 1.414 1.414l.293-.294v2.92a1 1 0 1 0 2 0v-5.333z"
               />
            </svg>
         );
      }
      case "card-arrow-up": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M18.667 26.667h-5.334c-5.028 0-7.542 0-9.104-1.563C2.667 23.543 2.667 21.028 2.667 16c0-.59.002-2.144.005-2.666h26.661c.003.522 0 2.077 0 2.666 0 .88 0 1.681-.008 2.416l-1.87-1.87a2.997 2.997 0 0 0-4.243 0l-2.667 2.666a3 3 0 0 0 1.788 5.103v2.317c-1.048.035-2.258.035-3.666.035z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  fillRule="evenodd"
                  d="M24.627 17.96a1 1 0 0 1 1.413 0l2.667 2.667a1.003 1.003 0 0 1 .025 1.438 1 1 0 0 1-1.438-.025l-.96-.96v5.587a1 1 0 0 1-2 0V21.08l-.96.96a.999.999 0 1 1-1.414-1.413l2.667-2.667z"
                  clipRule="evenodd"
               />
               <path
                  fill={color}
                  d="M16.667 20.334a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2h-2zm-8.667 0a1 1 0 0 0 0 2h5.333a1 1 0 0 0 0-2H8zm5.327-15h5.346c5.042 0 7.563 0 9.128 1.488 1.128 1.07 1.444 2.613 1.532 5.178v1.334H2.667V12c.088-2.566.404-4.106 1.532-5.178 1.565-1.489 4.086-1.489 9.128-1.489z"
               />
            </svg>
         );
      }
      case "card": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M13.333 26.667h5.334c5.028 0 7.542 0 9.104-1.563 1.562-1.561 1.562-4.076 1.562-9.104 0-.59-.002-2.144-.005-2.666H2.667c-.003.522 0 2.077 0 2.666 0 5.028 0 7.543 1.561 9.104 1.563 1.563 4.079 1.563 9.105 1.563z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M7 18a1 1 0 0 1 1-1h2.667a1 1 0 0 1 0 2H8a1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1h5.333a1 1 0 0 1 0 2H8a1 1 0 0 1-1-1z"
               />
               <path
                  fill={color}
                  fillRule="evenodd"
                  d="M22.93 16.39c-.431-.056-.96-.056-1.537-.056h-.12c-.577 0-1.106 0-1.536.057-.474.064-.974.213-1.386.627-.414.412-.563.912-.627 1.386-.057.43-.057.96-.057 1.536v.12c0 .578 0 1.107.057 1.536.064.475.213.975.627 1.387.412.413.912.563 1.386.627.431.057.96.057 1.536.057h.12c.578 0 1.107 0 1.536-.058.475-.064.975-.213 1.387-.626.413-.412.563-.912.627-1.387.057-.43.057-.96.057-1.536v-.12c0-.577 0-1.107-.057-1.536-.064-.474-.214-.974-.627-1.387-.412-.413-.912-.562-1.387-.626zm-3.162 2.04h-.004l-.001.005a.94.94 0 0 0-.056.236c-.038.28-.04.672-.04 1.33 0 .656.002 1.048.04 1.328.008.081.027.16.056.236l.001.005h.004a.93.93 0 0 0 .236.057c.28.037.672.04 1.33.04.657 0 1.049-.003 1.329-.04a.93.93 0 0 0 .236-.056l.004-.002.001-.003a.939.939 0 0 0 .056-.236c.037-.28.04-.672.04-1.33 0-.657-.003-1.05-.04-1.33a.94.94 0 0 0-.056-.235l-.001-.004-.004-.002a.934.934 0 0 0-.236-.055c-.28-.038-.672-.04-1.33-.04-.657 0-1.049.002-1.329.04a.934.934 0 0 0-.236.056z"
                  clipRule="evenodd"
               />
               <path
                  fill={color}
                  d="M13.327 5.333h5.346c5.042 0 7.563 0 9.128 1.489 1.128 1.07 1.444 2.613 1.532 5.178v1.334H2.667V12c.088-2.566.404-4.106 1.532-5.178 1.565-1.489 4.086-1.489 9.128-1.489z"
               />
            </svg>
         );
      }
      case "check": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M9.66 3h12.68c1.545 0 2.317 0 2.941.217A4.06 4.06 0 0 1 27.79 5.8c.21.64.21 1.436.21 3.027v18.672c0 1.144-1.313 1.752-2.144.992a1.261 1.261 0 0 0-1.712 0l-.644.589a2.21 2.21 0 0 1-3 0 2.21 2.21 0 0 0-3 0 2.21 2.21 0 0 1-3 0 2.21 2.21 0 0 0-3 0 2.21 2.21 0 0 1-3 0l-.644-.59a1.261 1.261 0 0 0-1.712 0c-.83.76-2.144.154-2.144-.99V8.827C4 7.236 4 6.44 4.21 5.8a4.059 4.059 0 0 1 2.51-2.583C7.343 3 8.115 3 9.66 3z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M20.08 11.667a1.003 1.003 0 0 0-.416-1.612 1.002 1.002 0 0 0-1.077.278l-4.015 4.499-1.16-1.299a1 1 0 0 0-1.49 1.334L13.824 17a1 1 0 0 0 1.492 0l4.763-5.333zM10 20a1 1 0 0 0 0 2h12a1 1 0 0 0 0-2H10z"
               />
            </svg>
         );
      }
      case "chewron-right": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M16.333 29.667c7.364 0 13.334-5.97 13.334-13.334S23.697 3 16.333 3 3 8.97 3 16.333c0 7.364 5.97 13.334 13.333 13.334z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M15.04 11.627a.999.999 0 1 0-1.413 1.413l3.293 3.294-3.293 3.293a1 1 0 1 0 1.413 1.413l4-4a1 1 0 0 0 0-1.413l-4-4z"
               />
            </svg>
         );
      }
      case "close": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M16.333 29.667c-6.285 0-9.428 0-11.381-1.954C3 25.763 3 22.62 3 16.333c0-6.285 0-9.428 1.952-11.381C6.907 3 10.048 3 16.333 3c6.286 0 9.428 0 11.38 1.952 1.954 1.955 1.954 5.096 1.954 11.381 0 6.286 0 9.428-1.954 11.38-1.95 1.954-5.094 1.954-11.38 1.954z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M12.293 12.293a1 1 0 0 1 1.414 0l2.626 2.627 2.627-2.627a.997.997 0 0 1 1.439-.025 1 1 0 0 1-.026 1.439l-2.626 2.626 2.626 2.627a.999.999 0 1 1-1.413 1.413l-2.627-2.626-2.626 2.626a1 1 0 0 1-1.414-1.413l2.627-2.627-2.627-2.626a1 1 0 0 1 0-1.414z"
               />
            </svg>
         );
      }
      case "document-menu": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  fillRule="evenodd"
                  d="M19.002 29.67h-5.334c-5.029 0-7.544 0-9.105-1.563C3 26.546 3 24.031 3 19.002v-5.334c0-5.029 0-7.544 1.563-9.105C6.124 3 8.653 3 13.708 3c.808 0 1.455 0 2 .023a2.014 2.014 0 0 0-.026.325l-.014 3.78c0 1.462 0 2.756.14 3.797.152 1.13.5 2.259 1.423 3.182.92.92 2.051 1.27 3.18 1.421 1.042.14 2.335.14 3.798.14h5.404c.057.712.057 1.587.057 2.751v.583c0 5.029 0 7.544-1.563 9.105-1.561 1.563-4.076 1.563-9.105 1.563z"
                  clipRule="evenodd"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="m15.681 3.346-.013 3.78c0 1.464 0 2.756.14 3.799.152 1.13.5 2.259 1.423 3.18.92.921 2.051 1.27 3.18 1.423 1.042.14 2.335.14 3.798.14h5.404c.017.207.029.428.037.667h.02c0-.358 0-.536-.013-.747a7.097 7.097 0 0 0-1.278-3.522 8.823 8.823 0 0 0-.38-.476c-1.057-1.264-2.45-2.84-3.663-3.923-1.08-.966-2.562-2.02-3.854-2.883-1.11-.74-1.664-1.11-2.425-1.387a7.325 7.325 0 0 0-.675-.204c-.512-.127-1.011-.17-1.714-.187l.014.34z"
               />
            </svg>
         );
      }
      case "about-menu": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="m16.334 21.757-5.028 5.207c-.72.745-1.08 1.119-1.387 1.247-.693.293-1.465.042-1.83-.598-.16-.28-.211-.786-.31-1.8-.057-.573-.085-.858-.17-1.098a1.911 1.911 0 0 0-1.114-1.152c-.23-.09-.507-.12-1.06-.178-.979-.102-1.468-.154-1.74-.32-.617-.378-.861-1.177-.577-1.896.125-.316.485-.69 1.205-1.434l3.285-3.402 1.651-1.65 7.075 7.074 7.074-7.074 1.651 1.65 3.285 3.4c.72.747 1.08 1.12 1.206 1.435.284.72.04 1.519-.578 1.897-.272.166-.76.218-1.74.32-.552.059-.829.088-1.06.178-.518.198-.92.616-1.113 1.152-.085.24-.113.525-.17 1.097-.1 1.013-.15 1.52-.31 1.801-.365.64-1.137.892-1.832.598-.304-.128-.664-.502-1.384-1.247l-5.03-5.207z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  fillRule="evenodd"
                  d="M16.334 21.667a9.334 9.334 0 1 0 0-18.668 9.334 9.334 0 0 0 0 18.668zm0-13.334c-.379 0-.632.454-1.139 1.364l-.13.235c-.144.259-.216.387-.329.472-.113.085-.253.117-.533.18l-.253.059c-.984.222-1.476.333-1.593.709-.118.377.218.77.889 1.553l.173.203c.19.223.287.333.33.472.042.139.027.287 0 .584l-.027.27c-.101 1.047-.152 1.571.153 1.803.307.232.768.02 1.69-.404l.237-.109c.262-.12.393-.18.532-.18s.27.06.532.18l.237.11c.921.425 1.383.635 1.69.403.306-.232.254-.756.153-1.802l-.027-.271c-.028-.297-.043-.445 0-.584.043-.137.139-.25.33-.472l.173-.203c.67-.784 1.006-1.176.89-1.553-.118-.376-.61-.487-1.594-.71l-.253-.058c-.28-.063-.42-.093-.534-.18-.112-.085-.184-.213-.328-.472l-.13-.235c-.507-.91-.76-1.364-1.14-1.364z"
                  clipRule="evenodd"
               />
            </svg>
         );
      }
      case "arrow-down": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M29.667 16.333c0 7.364-5.97 13.334-13.334 13.334S3 23.697 3 16.333 8.97 3 16.333 3c7.364 0 13.334 5.97 13.334 13.333z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M11.627 18.373a1 1 0 1 1 1.413-1.413l2.293 2.293V11a1 1 0 0 1 2 0v8.253l2.294-2.293a1.001 1.001 0 1 1 1.413 1.413l-4 4a1 1 0 0 1-1.413 0l-4-4z"
               />
            </svg>
         );
      }
      case "arrow-up": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M3 16.333C3 8.97 8.97 3 16.333 3c7.364 0 13.334 5.97 13.334 13.333 0 7.364-5.97 13.334-13.334 13.334S3 23.697 3 16.333z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M21.04 14.293a1 1 0 1 1-1.413 1.414l-2.293-2.294v8.254a1 1 0 0 1-2 0v-8.254l-2.294 2.294a1 1 0 0 1-1.413-1.414l4-4a1 1 0 0 1 1.413 0l4 4z"
               />
            </svg>
         );
      }
      case "clock": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 48 48"
            >
               <path
                  fill={color}
                  d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20z"
                  opacity={0.5}
               />
               <path
                  fill={color}
                  fillRule="evenodd"
                  d="M24 14.5a1.5 1.5 0 0 1 1.5 1.5v7.38l4.56 4.56a1.5 1.5 0 1 1-2.12 2.12l-5-5A1.5 1.5 0 0 1 22.5 24v-8a1.5 1.5 0 0 1 1.5-1.5z"
                  clipRule="evenodd"
               />
            </svg>
         );
      }
      case "clock-light": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 48 48"
            >
               <path
                  fill={color}
                  d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  fillRule="evenodd"
                  d="M24 14.5a1.5 1.5 0 0 1 1.5 1.5v7.38l4.56 4.56a1.5 1.5 0 1 1-2.12 2.12l-5-5A1.5 1.5 0 0 1 22.5 24v-8a1.5 1.5 0 0 1 1.5-1.5z"
                  clipRule="evenodd"
               />
            </svg>
         );
      }
      case "diploma": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 48 48"
            >
               <path
                  fill={color}
                  d="M4 16v8c0 5.656 0 8.486 1.758 10.242.952.954 2.22 1.39 4.08 1.59.15-.2.29-.358.39-.468.232-.256.526-.546.812-.828l2.986-2.944 1.102-1.116a9.002 9.002 0 0 1 17.744 0l1.102 1.116 2.986 2.944c.286.282.58.572.812.828.1.11.24.268.388.468 1.862-.2 3.13-.636 4.082-1.59C44 32.486 44 29.656 44 24v-8c0-5.656 0-8.486-1.758-10.242C40.486 4 37.656 4 32 4H16C10.344 4 7.514 4 5.758 5.758 4 7.514 4 10.344 4 16z"
                  opacity={0.5}
               />
               <path
                  fill={color}
                  d="M14 17.5a1.5 1.5 0 1 0 0 3h20a1.5 1.5 0 1 0 0-3H14zm2.5-5.5a1.5 1.5 0 0 1 1.5-1.5h12a1.5 1.5 0 1 1 0 3H18a1.5 1.5 0 0 1-1.5-1.5zm1.502 19.834L18 32a6 6 0 1 0 .002-.166zm-2.65 2.666-2.16 2.13c-.65.64-.974.96-1.086 1.228a1.286 1.286 0 0 0 .52 1.626c.244.142.684.186 1.566.276.496.05.746.074.954.15a1.676 1.676 0 0 1 1 .988c.078.206.104.45.154.94.09.87.136 1.304.28 1.546a1.324 1.324 0 0 0 1.648.512c.274-.112.6-.43 1.246-1.07l2.16-2.14a9.018 9.018 0 0 1-6.282-6.186zm11.012 6.186 2.16 2.14c.65.64.974.96 1.248 1.07.626.252 1.32.036 1.648-.512.144-.24.19-.676.28-1.544.05-.492.076-.736.154-.94a1.679 1.679 0 0 1 1-.99c.21-.076.458-.1.954-.15.88-.09 1.322-.134 1.566-.276a1.285 1.285 0 0 0 .52-1.626c-.112-.27-.436-.59-1.084-1.228l-2.16-2.132a9.017 9.017 0 0 1-6.286 6.188z"
               />
            </svg>
         );
      }
      case "download": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M29.667 16.333c0 7.364-5.97 13.334-13.334 13.334S3 23.697 3 16.333 8.97 3 16.333 3c7.364 0 13.334 5.97 13.334 13.333z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M17.333 9.334a1 1 0 1 0-2 0v6.92L13.04 13.96a1 1 0 0 0-1.413 1.413l4 4a1 1 0 0 0 1.413 0l4-4a1.001 1.001 0 1 0-1.413-1.413l-2.294 2.293v-6.92zM11 21.666a1 1 0 0 0 0 2h10.667a1 1 0 1 0 0-2H11z"
               />
            </svg>
         );
      }
      case "expressionless": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M16.333 29.667c7.364 0 13.334-5.97 13.334-13.334S23.697 3 16.333 3 3 8.97 3 16.333c0 7.364 5.97 13.334 13.333 13.334z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M11.333 21.667a1 1 0 0 1 1-1h8a1 1 0 0 1 0 2h-8a1 1 0 0 1-1-1zm9-5.334c.736 0 1.334-.895 1.334-2 0-1.104-.598-2-1.334-2-.736 0-1.333.896-1.333 2s.597 2 1.333 2zm-8 0c.736 0 1.334-.895 1.334-2 0-1.104-.598-2-1.334-2-.736 0-1.333.896-1.333 2s.597 2 1.333 2z"
               />
            </svg>
         );
      }
      case "home-menu": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M19.774 29.654V25c0-1.247 0-1.87-.268-2.334a2 2 0 0 0-.732-.732c-.464-.268-1.087-.268-2.334-.268-1.246 0-1.87 0-2.333.268a2 2 0 0 0-.732.732c-.268.465-.268 1.087-.268 2.334v4.654h6.667z"
               />
               <path
                  fill={color}
                  fillRule="evenodd"
                  d="M8.083 16.334a4.056 4.056 0 0 1-2.976-1.29V19c0 5.028 0 7.543 1.563 9.104 1.257 1.259 3.13 1.503 6.437 1.55h6.667c3.306-.047 5.18-.293 6.437-1.55 1.563-1.561 1.563-4.076 1.563-9.104v-3.954a4.08 4.08 0 0 1-7.034-2.386l-.097-.97a4.223 4.223 0 1 1-8.41.05l-.091.92a4.08 4.08 0 0 1-4.06 3.674zM19.773 25v4.655h-6.666V25c0-1.247 0-1.87.268-2.333a2 2 0 0 1 .732-.732c.464-.268 1.087-.268 2.333-.268 1.247 0 1.87 0 2.334.268a2 2 0 0 1 .732.732c.268.464.268 1.086.268 2.333z"
                  clipRule="evenodd"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M13.107 3h6.667l.869 8.69a4.223 4.223 0 1 1-8.405 0L13.107 3z"
               />
               <path
                  fill={color}
                  d="M4.88 7.468c.238-1.187.356-1.78.598-2.261a4 4 0 0 1 2.518-2.064C8.516 3 9.12 3 10.331 3h2.777l-.966 9.66a4.079 4.079 0 0 1-7.406 1.951 4.08 4.08 0 0 1-.653-3.156l.797-3.987zm23.12 0c-.237-1.187-.356-1.78-.597-2.261a4.001 4.001 0 0 0-2.517-2.064C24.366 3 23.76 3 22.55 3h-2.776l.966 9.66a4.08 4.08 0 1 0 8.058-1.205L28 7.468z"
                  opacity={0.7}
               />
            </svg>
         );
      }
      case "info": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M29.333 16c0 7.364-5.969 13.333-13.333 13.333S2.667 23.363 2.667 16C2.667 8.636 8.637 2.666 16 2.666c7.364 0 13.333 5.97 13.333 13.334z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M16 23.666a1 1 0 0 0 1-1v-8a1 1 0 0 0-2 0v8a1 1 0 0 0 1 1zm0-14.333A1.333 1.333 0 1 1 16 12a1.333 1.333 0 0 1 0-2.667z"
               />
            </svg>
         );
      }
      case "lock": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 32 32"
            >
               <path
                  fill={color}
                  d="M2.667 22.333c0-3.77 0-5.657 1.172-6.828 1.17-1.172 3.057-1.172 6.828-1.172h10.666c3.771 0 5.658 0 6.828 1.172 1.172 1.17 1.172 3.057 1.172 6.828 0 3.77 0 5.657-1.172 6.828-1.17 1.172-3.057 1.172-6.828 1.172H10.667c-3.77 0-5.658 0-6.828-1.172-1.172-1.17-1.172-3.057-1.172-6.828z"
                  opacity={0.2}
               />
               <path
                  fill={color}
                  d="M10.667 23.666a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666zm5.333 0A1.333 1.333 0 1 0 16 21a1.333 1.333 0 0 0 0 2.666zm6.667-1.333a1.333 1.333 0 1 1-2.667 0 1.333 1.333 0 0 1 2.667 0zM9 11.667a7 7 0 0 1 13.78-1.751 1 1 0 0 0 1.936-.499A9 9 0 0 0 7 11.667v2.74a31.399 31.399 0 0 1 2-.069v-2.671z"
               />
            </svg>
         );
      }
      case "mir-express": {
         return (
            <svg
               className={className}
               width={48}
               height={34}
               viewBox="0 0 48 34"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <rect width={48} height={34} rx={4} fill="#5D9922" />
               <path
                  opacity={0.5}
                  d="M24 0C21.7909 0 20 1.79086 20 4V30C20 32.2091 21.7909 34 24 34H44C46.2091 34 48 32.2091 48 30V28.1202C46.92 29.0297 45.7367 29.8156 44.45 30.4781C41.8304 31.8247 39.0525 32.5 36.1162 32.5C33.18 32.5 30.6405 31.8267 28.5 30.4781C28.5 30.4781 43.037 32.1793 47.6007 20.3645H39.1727C36.495 20.3645 34.4647 17.9602 34.9297 15.3386H48V11.1622C47.7658 10.6664 47.4776 10.1992 47.1357 9.76096C45.6666 7.87849 43.5039 6.93825 40.6519 6.93825C38.2668 6.93825 36.1423 7.5996 34.2723 8.90239L30.9612 4.89841C32.242 3.99602 33.649 3.25299 35.1862 2.67331C37.2586 1.89044 39.4032 1.5 41.6159 1.5C43.8286 1.5 45.8289 1.89243 47.6187 2.67331C47.6804 2.70028 47.7417 2.7276 47.8025 2.75527C47.2792 1.15557 45.7746 0 44 0H24Z"
                  fill="white"
               />
               <g clipPath="url(#clip0_560_10976)">
                  <path
                     d="M25.69 22.7002H24V27.3002H25.6V24.5802H25.72L26.62 27.2802H27.82L28.72 24.5802H28.82V27.2802H30.44V22.7102H28.82C28.59 22.7102 28.13 22.7002 27.94 23.3102L27.28 25.4102H27.16L26.51 23.3102C26.31 22.7002 25.87 22.7102 25.69 22.7102V22.7002Z"
                     fill="white"
                  />
                  <path
                     d="M32.75 25.4102V22.7102H31.1V27.2902H32.66C32.66 27.2902 33.13 27.2502 33.34 26.8102L34.41 24.5902H34.54V27.2902H36.18V22.7102H34.63C34.63 22.7102 34.18 22.7502 33.96 23.1902L32.87 25.4102H32.75Z"
                     fill="white"
                  />
                  <path
                     d="M40.12 25.9302C40.92 25.9302 41.63 25.4902 41.86 24.8902H36.83V27.2902H38.39V25.9302H40.12Z"
                     fill="white"
                  />
                  <path
                     d="M36.75 22.7002H40.19C41.51 22.7002 42.15 23.7402 41.97 24.6002H38.75C37.77 24.6002 36.83 23.7202 36.75 22.7002Z"
                     fill="white"
                  />
               </g>
               <defs>
                  <clipPath id="clip0_560_10976">
                     <rect
                        width={22}
                        height={10}
                        fill="white"
                        transform="translate(22 20)"
                     />
                  </clipPath>
               </defs>
            </svg>
         );
      }
      case "card-other": {
         return (
            <svg
               className={className}
               width={48}
               height={34}
               viewBox="0 0 48 34"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <rect width={48} height={34} rx={4} fill="#2B3334" />
               <rect opacity={0.5} x={20} width={28} height={34} rx={4} fill="#0E0F12" />
            </svg>
         );
      }
      case "card-visa": {
         return (
            <svg
               className={className}
               width={48}
               height={34}
               viewBox="0 0 48 34"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <rect width={48} height={34} rx={4} fill="#2B3334" />
               <rect opacity={0.5} x={20} width={28} height={34} rx={4} fill="#0E0F12" />
               <g clipPath="url(#clip0_560_10993)">
                  <path
                     d="M42 20H24C22.8954 20 22 20.8954 22 22V28C22 29.1046 22.8954 30 24 30H42C43.1046 30 44 29.1046 44 28V22C44 20.8954 43.1046 20 42 20Z"
                     fill="#2B3334"
                  />
                  <path
                     d="M31.7999 27.8287H30.3418L31.2538 22.1895H32.7119L31.7999 27.8287Z"
                     fill="white"
                  />
                  <path
                     d="M37.0859 22.3275C36.7983 22.2134 36.3421 22.0874 35.7781 22.0874C34.3381 22.0874 33.3241 22.8553 33.3179 23.9531C33.3059 24.763 34.0439 25.2129 34.5958 25.4829C35.16 25.7589 35.3517 25.939 35.3517 26.185C35.346 26.5628 34.8959 26.7369 34.4761 26.7369C33.8939 26.7369 33.582 26.6472 33.108 26.437L32.916 26.3469L32.7119 27.6128C33.0539 27.7686 33.684 27.9068 34.3381 27.9129C35.8681 27.9129 36.8641 27.1569 36.8759 25.987C36.8818 25.3451 36.4921 24.8532 35.652 24.4512C35.142 24.1932 34.8297 24.0192 34.8297 23.7552C34.8357 23.5152 35.0939 23.2694 35.6695 23.2694C36.1436 23.2574 36.4919 23.3713 36.7557 23.4853L36.8876 23.5452L37.0859 22.3275Z"
                     fill="white"
                  />
                  <path
                     d="M39.0238 25.8309C39.1439 25.5069 39.6059 24.2531 39.6059 24.2531C39.5999 24.2651 39.7258 23.9231 39.7978 23.7132L39.8997 24.1991C39.8997 24.1991 40.1758 25.549 40.2358 25.8309C40.0079 25.8309 39.3118 25.8309 39.0238 25.8309ZM40.8236 22.1895H39.6958C39.348 22.1895 39.0837 22.2914 38.9337 22.6573L36.7678 27.8286H38.2978C38.2978 27.8286 38.5497 27.1326 38.6038 26.9827C38.7717 26.9827 40.26 26.9827 40.4759 26.9827C40.5178 27.1807 40.6499 27.8286 40.6499 27.8286H42L40.8236 22.1895Z"
                     fill="white"
                  />
                  <path
                     d="M29.1239 22.1895L27.6959 26.0349L27.5399 25.255C27.2759 24.3551 26.4479 23.3774 25.5239 22.8912L26.8319 27.8227H28.3738L30.6658 22.1895H29.1239Z"
                     fill="white"
                  />
                  <path
                     d="M26.37 22.1895H24.024L24 22.3034C25.83 22.7714 27.042 23.8994 27.5399 25.2552L27.0299 22.6635C26.946 22.3033 26.688 22.2013 26.37 22.1895Z"
                     fill="white"
                  />
               </g>
               <defs>
                  <clipPath id="clip0_560_10993">
                     <rect
                        width={22}
                        height={10}
                        fill="white"
                        transform="translate(22 20)"
                     />
                  </clipPath>
               </defs>
            </svg>
         );
      }
      case "card-mastercard": {
         return (
            <svg
               className={className}
               width={48}
               height={34}
               viewBox="0 0 48 34"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <rect width={48} height={34} rx={4} fill="#2B3334" />
               <rect opacity={0.5} x={20} width={28} height={34} rx={4} fill="#0E0F12" />
               <g clipPath="url(#clip0_560_10985)">
                  <path
                     d="M42.4545 20H28.5455C27.6919 20 27 20.8954 27 22V28C27 29.1046 27.6919 30 28.5455 30H42.4545C43.3081 30 44 29.1046 44 28V22C44 20.8954 43.3081 20 42.4545 20Z"
                     fill="#2B3334"
                  />
                  <path
                     d="M36.9841 22.3394H34.0073V27.6605H36.9841V22.3394Z"
                     fill="#898B87"
                  />
                  <path
                     d="M34.2054 24.9998C34.2054 23.9187 34.7162 22.9592 35.5 22.3393C34.9231 21.888 34.1955 21.6152 33.4017 21.6152C31.5214 21.6152 30 23.129 30 24.9998C30 26.8707 31.5214 28.3845 33.4017 28.3845C34.1955 28.3845 34.9231 28.1117 35.5 27.6604C34.7151 27.0492 34.2054 26.081 34.2054 24.9998Z"
                     fill="#C3C3C3"
                  />
                  <path
                     d="M41 24.9998C41 26.8707 39.4786 28.3845 37.5983 28.3845C36.8045 28.3845 36.0769 28.1117 35.5 27.6604C36.2937 27.0394 36.7946 26.081 36.7946 24.9998C36.7946 23.9187 36.2838 22.9592 35.5 22.3393C36.0758 21.888 36.8034 21.6152 37.5972 21.6152C39.4786 21.6152 41 23.1389 41 24.9998Z"
                     fill="#F8F8F8"
                  />
               </g>
               <defs>
                  <clipPath id="clip0_560_10985">
                     <rect
                        width={17}
                        height={10}
                        fill="white"
                        transform="translate(27 20)"
                     />
                  </clipPath>
               </defs>
            </svg>
         );
      }
      case "card-mir": {
         return (
            <svg
               className={className}
               width={48}
               height={34}
               viewBox="0 0 48 34"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <rect width={48} height={34} rx={4} fill="#2B3334" />
               <rect opacity={0.5} x={20} width={28} height={34} rx={4} fill="#0E0F12" />
               <g clipPath="url(#clip0_560_10968)">
                  <path
                     d="M42 20H24C22.8954 20 22 20.8954 22 22V28C22 29.1046 22.8954 30 24 30H42C43.1046 30 44 29.1046 44 28V22C44 20.8954 43.1046 20 42 20Z"
                     fill="#2B3334"
                  />
                  <path
                     d="M24 22.7002H25.69V22.7102L25.6986 22.7102C25.8836 22.71 26.3131 22.7097 26.51 23.3102L27.16 25.4102H27.28L27.94 23.3102C28.127 22.7097 28.5757 22.71 28.809 22.7102L28.82 22.7102H30.44V27.2802H28.82V24.5802H28.72L27.82 27.2802H26.62L25.72 24.5802H25.6V27.3002H24V22.7002Z"
                     fill="white"
                  />
                  <path
                     d="M41.86 24.8902C41.63 25.4902 40.92 25.9302 40.12 25.9302H38.39V27.2902H36.83V24.8902H41.86Z"
                     fill="white"
                  />
                  <path
                     d="M36.75 22.7002H40.19C41.51 22.7002 42.15 23.7402 41.97 24.6002H38.75C37.77 24.6002 36.83 23.7202 36.75 22.7002Z"
                     fill="white"
                  />
                  <path
                     d="M32.75 25.4102V22.7102H31.1V27.2902H32.66C32.66 27.2902 33.13 27.2502 33.34 26.8102L34.41 24.5902H34.54V27.2902H36.18V22.7102H34.63C34.63 22.7102 34.18 22.7502 33.96 23.1902L32.87 25.4102H32.75Z"
                     fill="white"
                  />
               </g>
               <defs>
                  <clipPath id="clip0_560_10968">
                     <rect
                        width={22}
                        height={10}
                        fill="white"
                        transform="translate(22 20)"
                     />
                  </clipPath>
               </defs>
            </svg>
         );
      }
      case "rocket": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 48 48"
            >
               <path
                  fill={color}
                  fillRule="evenodd"
                  d="m31.004 28.734 10.06-10.028c1.448-1.444 2.174-2.166 2.554-3.086.382-.918.382-1.94.382-3.98v-.98c0-3.14 0-4.71-.98-5.686C42.044 4 40.47 4 37.318 4h-.978c-2.048 0-3.074 0-3.994.38-.92.38-1.646 1.102-3.094 2.548l-10.06 10.028c-1.692 1.688-2.742 2.734-3.148 3.746-.128.32-.194.634-.194.966 0 1.38 1.114 2.49 3.342 4.712l.3.298L23 23.118a1.291 1.291 0 0 1 1.838 1.812l-3.52 3.57.238.234c2.228 2.22 3.34 3.332 4.724 3.332.306 0 .6-.054.894-.162 1.038-.382 2.096-1.44 3.832-3.17h-.002zm4.726-11.776a3.351 3.351 0 0 1-4.726 0 3.324 3.324 0 0 1 0-4.712 3.351 3.351 0 0 1 4.726 0 3.323 3.323 0 0 1 0 4.712zM5.548 24.962a1.518 1.518 0 0 1 0 2.148l-.312.31a.68.68 0 1 0 .966.96l3.426-3.42a1.52 1.52 0 0 1 2.144 2.15l-3.424 3.42a3.72 3.72 0 0 1-6.065-4.052c.187-.451.461-.861.807-1.206l.312-.31a1.52 1.52 0 0 1 2.146 0zm9.046 8.43c.586.6.576 1.56-.024 2.146l-3.46 3.384a1.518 1.518 0 0 1-2.122-2.17l3.46-3.384a1.518 1.518 0 0 1 2.146.024zm8.368 2.844a1.52 1.52 0 0 1 0 2.148l-3.426 3.42a.68.68 0 0 0 0 .96c.268.266.7.266.968 0l.312-.31A1.518 1.518 0 0 1 22.96 44.6l-.31.31a3.72 3.72 0 0 1-5.26-5.258l3.426-3.42a1.518 1.518 0 0 1 2.146.004z"
                  clipRule="evenodd"
               />
               <path
                  fill={color}
                  d="m21.692 10.82-4.376 4.36c-.804.802-1.54 1.538-2.124 2.202-.394.44-.75.912-1.064 1.412l-.044-.042-.16-.16a8.357 8.357 0 0 0-2.638-1.73l-.212-.084-.65-.26a1.316 1.316 0 0 1-.446-2.154c1.926-1.92 4.24-4.228 5.358-4.692a5.814 5.814 0 0 1 3.074-.394c.94.14 1.83.624 3.282 1.542zm7.472 22.966c.352.36.586.612.798.88.28.358.528.736.746 1.134.246.446.436.924.816 1.878.31.776 1.34.982 1.936.386l.146-.144c1.926-1.92 4.24-4.228 4.706-5.34a5.793 5.793 0 0 0 .394-3.068c-.14-.936-.624-1.824-1.544-3.272l-4.39 4.378c-.822.82-1.578 1.572-2.26 2.16-.421.372-.872.71-1.348 1.008zm-13.792-4.66a1.517 1.517 0 0 0-1.064-2.62 1.517 1.517 0 0 0-1.082.474L8.94 31.264a1.52 1.52 0 0 0 2.148 2.148l4.284-4.286zm5.618 5.612a1.517 1.517 0 0 0-1.064-2.62 1.517 1.517 0 0 0-1.082.474l-4.256 4.254a1.52 1.52 0 0 0 2.148 2.148l4.254-4.256z"
                  opacity={0.5}
               />
            </svg>
         );
      }
      case "sewn": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               width={width}
               height={height}
               viewBox="0 0 48 48"
            >
               <path
                  fill={color}
                  d="M6.756 10.164C6 11.24 6 14.44 6 20.834v3.148c0 11.276 8.478 16.75 13.798 19.072 1.442.63 2.164.946 4.202.946 2.04 0 2.76-.316 4.202-.946C33.522 40.73 42 35.26 42 23.982v-3.148c0-6.396 0-9.594-.756-10.67-.754-1.074-3.76-2.104-9.774-4.162l-1.146-.392C27.19 4.536 25.624 4 24 4c-1.622 0-3.19.536-6.324 1.61L16.53 6c-6.014 2.06-9.02 3.09-9.774 4.164z"
                  opacity={0.5}
               />
               <path
                  fill={color}
                  d="m21.722 16.726-.26.47c-.29.518-.434.776-.658.946-.224.17-.504.234-1.064.36l-.508.116c-1.968.444-2.952.668-3.186 1.42-.234.754.436 1.538 1.778 3.106l.348.406c.38.446.57.668.656.944.086.276.058.574 0 1.168l-.052.54c-.204 2.094-.304 3.14.308 3.606.612.466 1.534.04 3.376-.806l.478-.22c.522-.24.784-.362 1.062-.362s.54.12 1.062.36l.478.22c1.84.85 2.764 1.274 3.376.808.612-.464.512-1.512.308-3.604l-.052-.542c-.058-.594-.086-.892 0-1.168.086-.276.276-.5.656-.944l.348-.406c1.34-1.568 2.012-2.352 1.778-3.106-.234-.752-1.218-.976-3.186-1.42l-.508-.116c-.56-.126-.84-.19-1.064-.36-.224-.17-.368-.428-.656-.944l-.262-.472C25.264 14.908 24.758 14 24 14s-1.264.908-2.278 2.726z"
               />
            </svg>
         );
      }
      case "mastercard": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               width={64}
               height={33}
               viewBox="0 0 64 33"
               fill="none"
            >
               <path fill="#FF5A00" d="M36.864 7.986h-9.757v17.529h9.757V7.985Z" />
               <path
                  fill="#EB001B"
                  d="M27.757 16.75c0-3.561 1.674-6.722 4.243-8.764A11.09 11.09 0 0 0 25.123 5.6a11.143 11.143 0 0 0-11.15 11.15 11.143 11.143 0 0 0 11.15 11.149A11.09 11.09 0 0 0 32 25.515a11.127 11.127 0 0 1-4.243-8.765Z"
               />
               <path
                  fill="#F79E1B"
                  d="M50.027 16.75c0 6.163-4.987 11.15-11.15 11.15A11.09 11.09 0 0 1 32 25.515a11.089 11.089 0 0 0 4.243-8.765c0-3.561-1.674-6.722-4.243-8.764A11.072 11.072 0 0 1 38.874 5.6c6.166 0 11.153 5.019 11.153 11.15Z"
               />
            </svg>
         );
      }
      case "visa": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               width={64}
               height={33}
               fill="none"
               viewBox="0 0 64 33"
            >
               <path
                  fill="#00579F"
                  d="M28.8 24.293h-3.888l2.432-15.038h3.888L28.8 24.293Zm14.095-14.67a9.582 9.582 0 0 0-3.487-.64c-3.84 0-6.544 2.047-6.56 4.975-.032 2.16 1.935 3.36 3.407 4.08 1.505.736 2.016 1.216 2.016 1.872-.015 1.007-1.215 1.472-2.335 1.472-1.552 0-2.384-.24-3.648-.8l-.512-.24-.544 3.375c.912.416 2.592.784 4.336.8 4.08 0 6.736-2.015 6.768-5.135.015-1.712-1.024-3.024-3.264-4.096-1.36-.688-2.193-1.152-2.193-1.855.016-.64.704-1.296 2.24-1.296a6.607 6.607 0 0 1 2.896.576l.352.16.529-3.248Zm5.168 9.343c.32-.864 1.553-4.208 1.553-4.208-.016.033.32-.88.511-1.44l.272 1.296s.737 3.6.896 4.352h-3.232Zm4.8-9.71h-3.008c-.927 0-1.632.271-2.032 1.247l-5.775 13.79h4.08l.816-2.256h4.992c.111.528.464 2.256.464 2.256H56L52.863 9.255Zm-31.199-.001L17.856 19.51l-.416-2.08c-.704-2.4-2.912-5.007-5.376-6.303l3.488 13.15h4.112l6.111-15.022h-4.111Z"
               />
               <path
                  fill="#FAA61A"
                  d="M14.32 9.255H8.064L8 9.56c4.88 1.248 8.112 4.256 9.44 7.872l-1.36-6.912c-.224-.96-.912-1.232-1.76-1.264Z"
               />
            </svg>
         );
      }
      case "sbp-min-text": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               width={64}
               height={33}
               fill="none"
               viewBox="0 0 64 33"
            >
               <path
                  fill="#0E0F12"
                  d="M56 13.587V20.4h-2.433v-4.78h-2.343v4.78H48.79v-6.815H56Z"
               />
               <path
                  fill="#0E0F12"
                  fillRule="evenodd"
                  d="M43.794 20.644c2.178 0 3.795-1.335 3.795-3.36 0-1.96-1.193-3.232-3.187-3.232-.92 0-1.68.324-2.252.883.136-1.155 1.113-1.999 2.19-1.999l2.117-.004 1.057-2.022s-2.347.053-3.438.053c-2.493.044-4.176 2.31-4.176 5.061 0 3.206 1.642 4.62 3.894 4.62Zm.013-4.8c.809 0 1.37.531 1.37 1.44 0 .818-.499 1.492-1.37 1.494-.833 0-1.394-.625-1.394-1.48 0-.909.56-1.454 1.394-1.454Z"
                  clipRule="evenodd"
               />
               <path
                  fill="#0E0F12"
                  d="M37.92 18.12s-.575.33-1.433.393c-.987.03-1.866-.593-1.866-1.7 0-1.08.776-1.7 1.84-1.7.654 0 1.518.453 1.518.453s.632-1.16.96-1.74c-.6-.455-1.399-.704-2.328-.704-2.344 0-4.16 1.529-4.16 3.677 0 2.175 1.706 3.668 4.16 3.623.686-.025 1.632-.266 2.209-.637l-.9-1.665Z"
               />
               <path fill="#5B57A2" d="m8 8.881 3.375 6.033v3.68l-3.371 6.021L8 8.881Z" />
               <path
                  fill="#D90751"
                  d="m20.96 12.719 3.162-1.938 6.473-.007-9.636 5.903V12.72Z"
               />
               <path
                  fill="#FAB718"
                  d="m20.942 8.846.017 7.987-3.383-2.079V2.814l3.366 6.032Z"
               />
               <path
                  fill="#ED6F26"
                  d="m30.595 10.774-6.473.006-3.18-1.934-3.366-6.032 13.019 7.96Z"
               />
               <path
                  fill="#63B22F"
                  d="M20.96 24.648v-3.875l-3.384-2.04.002 11.953 3.381-6.038Z"
               />
               <path
                  fill="#1487C9"
                  d="m24.114 22.728-12.739-7.814L8 8.881l22.581 13.84-6.467.007Z"
               />
               <path
                  fill="#017F36"
                  d="m17.578 30.686 3.381-6.037 3.155-1.92 6.467-.009-13.003 7.966Z"
               />
               <path
                  fill="#984995"
                  d="m8.004 24.616 9.6-5.881-3.227-1.98-3.002 1.84-3.371 6.02Z"
               />
            </svg>
         );
      }
      case "mir": {
         return (
            <svg
               className={className}
               width={64}
               height={33}
               viewBox="0 0 64 33"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.4387 10.2058C12.8675 10.2033 14.142 10.0885 14.6818 11.9104C15.0455 13.1377 15.6247 15.148 16.4196 17.9414H16.7433C17.5957 14.9964 18.1813 12.9861 18.5 11.9104C19.0455 10.0695 20.4091 10.2059 20.9545 10.2059L25.1629 10.2059V23.2968H20.8736V15.5821H20.586L18.195 23.2968H14.9679L12.5768 15.5764H12.2892V23.2968H8V10.2059L12.4387 10.2058ZM31.3221 10.2059V17.9263H31.6643L34.5727 11.5779C35.1373 10.3145 36.3406 10.2059 36.3406 10.2059H40.4913V23.2968H36.1125V15.5764H35.7704L32.9189 21.9248C32.3543 23.1825 31.094 23.2968 31.094 23.2968H26.9433V10.2059H31.3221ZM55.6264 16.4268C55.0156 18.1576 53.0975 19.3972 50.9738 19.3972H46.3818V23.2968H42.2178V16.4268H55.6264Z"
                  fill="#0F754E"
               />
               <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M51.1745 10.2061H41.9993C42.2176 13.1205 44.7275 15.6155 47.3257 15.6155H55.9156C56.4113 13.1932 54.7048 10.2061 51.1745 10.2061Z"
                  fill="url(#paint0_linear_139_7029)"
               />
               <defs>
                  <linearGradient
                     id="paint0_linear_139_7029"
                     x1={1442.05}
                     y1={350.485}
                     x2={41.9993}
                     y2={350.485}
                     gradientUnits="userSpaceOnUse"
                  >
                     <stop stopColor="#1F5CD7" />
                     <stop offset={1} stopColor="#02AEFF" />
                  </linearGradient>
               </defs>
            </svg>
         );
      }
      case "e": {
         return (
            <svg
               className={className}
               width={211}
               height={253}
               viewBox="0 0 211 253"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  d="M208.098 76.053c-3.597-15.575-9.935-28.955-19.016-40.14-9.081-11.169-20.823-19.948-35.226-26.337C139.453 3.203 123.356 0 105.549 0 87.743 0 70.484 3.186 53.807 9.576c-12.371 4.73-23.694 10.795-34 18.16l26.645 32.677c15.049-10.632 32.145-16.03 51.339-16.03 22.952 0 40.355 7.674 52.178 23.037 9.532 12.388 13.871 27.622 12.952 45.521H51.742C48 134.336 64.34 153.959 85.888 153.959h67.823C116.985 250.383 0 236.499 0 236.499 17.226 247.505 37.662 253 61.29 253c23.63 0 45.985-5.511 67.066-16.501 21.081-11.007 38.71-26.191 52.903-45.554 14.194-19.363 23.388-40.953 27.549-64.77 3.145-17.835 2.903-34.547-.71-50.122Z"
                  fill="#8FB966"
               />
            </svg>
         );
      }
      case "siren": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               width={width}
               height={height}
               viewBox="0 0 32 32"
               fill="none"
            >
               <path
                  fill={color}
                  d="M26.665 28.333v-7c0-3.733 0-5.6-.726-7.026a6.667 6.667 0 0 0-2.914-2.914c-1.426-.726-3.293-.726-7.026-.726-3.734 0-5.6 0-7.027.726a6.667 6.667 0 0 0-2.913 2.914c-.727 1.426-.727 3.293-.727 7.026v7h21.333Z"
                  opacity={0.5}
               />
               <path
                  fill={color}
                  d="M19 14.42a1 1 0 0 1 1-1c2.157 0 3.994 1.696 3.913 3.95a1 1 0 1 1-2-.073c.038-1.026-.791-1.877-1.914-1.877a1 1 0 0 1-1-1ZM5.332 28.333H2.666a1 1 0 0 0 0 2h26.667a1 1 0 1 0 0-2h-24Zm11.666-3.266a2 2 0 1 0-2 0v3.266h2v-3.266Zm-1-23.4a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1Zm12.707 5.706-2 2a.999.999 0 1 1-1.413-1.413l2-2a1 1 0 1 1 1.413 1.413Zm-24-1.413a1 1 0 0 0-1.413 1.413l2 2A1 1 0 0 0 6.706 7.96l-2-2Z"
               />
            </svg>
         );
      }
      case "pasport": {
         return (
            <svg
               className={className}
               xmlns="http://www.w3.org/2000/svg"
               width={width}
               height={height}
               viewBox="0 0 32 32"
               fill="none"
            >
               <path
                  d="M12.6666 17.667C12.6666 16.8713 12.9827 16.1083 13.5453 15.5457C14.1079 14.9831 14.871 14.667 15.6666 14.667C16.4623 14.667 17.2253 14.9831 17.7879 15.5457C18.3506 16.1083 18.6666 16.8713 18.6666 17.667C18.6666 18.4626 18.3506 19.2257 17.7879 19.7883C17.2253 20.3509 16.4623 20.667 15.6666 20.667C14.871 20.667 14.1079 20.3509 13.5453 19.7883C12.9827 19.2257 12.6666 18.4626 12.6666 17.667Z"
                  fill={color}
               />
               <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5 25.259V6.59229C5 6.94591 5.14048 7.28505 5.39052 7.53509C5.64057 7.78514 5.97971 7.92562 6.33333 7.92562H22.3333C23.3942 7.92562 24.4116 8.34705 25.1618 9.09719C25.9119 9.84734 26.3333 10.8648 26.3333 11.9256V25.259C26.3333 26.3198 25.9119 27.3372 25.1618 28.0874C24.4116 28.8375 23.3942 29.259 22.3333 29.259H9C7.93913 29.259 6.92172 28.8375 6.17157 28.0874C5.42143 27.3372 5 26.3198 5 25.259ZM10.6667 17.667C10.6667 16.3409 11.1935 15.0691 12.1311 14.1314C13.0688 13.1937 14.3406 12.667 15.6667 12.667C16.9927 12.667 18.2645 13.1937 19.2022 14.1314C20.1399 15.0691 20.6667 16.3409 20.6667 17.667C20.6667 18.993 20.1399 20.2648 19.2022 21.2025C18.2645 22.1402 16.9927 22.667 15.6667 22.667C14.3406 22.667 13.0688 22.1402 12.1311 21.2025C11.1935 20.2648 10.6667 18.993 10.6667 17.667ZM12 25.667C12 25.4017 12.1054 25.1474 12.2929 24.9598C12.4804 24.7723 12.7348 24.667 13 24.667H18.3333C18.5985 24.667 18.8529 24.7723 19.0404 24.9598C19.228 25.1474 19.3333 25.4017 19.3333 25.667C19.3333 25.9322 19.228 26.1865 19.0404 26.3741C18.8529 26.5616 18.5985 26.667 18.3333 26.667H13C12.7348 26.667 12.4804 26.5616 12.2929 26.3741C12.1054 26.1865 12 25.9322 12 25.667Z"
                  fill={color}
               />
               <path
                  opacity="0.5"
                  d="M23.6667 5.66683V8.1535C23.2493 8.00683 22.8 7.92683 22.3333 7.92683H6.33333C5.97971 7.92683 5.64057 7.78636 5.39052 7.53631C5.14048 7.28626 5 6.94712 5 6.5935V6.4975C5 5.78683 5.52267 5.18416 6.22667 5.08416L20.6227 3.02683C21.0009 2.97277 21.3864 3.00062 21.7529 3.10849C22.1195 3.21637 22.4585 3.40176 22.7472 3.65211C23.0358 3.90245 23.2673 4.2119 23.426 4.5595C23.5846 4.90711 23.6667 5.28474 23.6667 5.66683Z"
                  fill={color}
               />
            </svg>
         );
      }
      case "question": {
         return (
            <svg
               className={className}
               width={width}
               height={height}
               viewBox="0 0 32 32"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  opacity={0.5}
                  d="M29.6667 16.3333C29.6667 23.6973 23.6973 29.6667 16.3333 29.6667C8.96933 29.6667 3 23.6973 3 16.3333C3 8.96933 8.96933 3 16.3333 3C23.6973 3 29.6667 8.96933 29.6667 16.3333Z"
                  fill={color}
               />
               <path
                  d="M16.3334 10.6669C15.5054 10.6669 14.8334 11.3389 14.8334 12.1669C14.8334 12.4321 14.728 12.6865 14.5405 12.874C14.3529 13.0615 14.0986 13.1669 13.8334 13.1669C13.5682 13.1669 13.3138 13.0615 13.1263 12.874C12.9387 12.6865 12.8334 12.4321 12.8334 12.1669C12.8334 11.5935 12.9743 11.0288 13.2437 10.5226C13.5131 10.0164 13.9027 9.5842 14.3784 9.2639C14.854 8.94361 15.401 8.74508 15.9714 8.68577C16.5417 8.62646 17.1179 8.70818 17.6492 8.92376C18.1806 9.13935 18.6508 9.48217 19.0186 9.9221C19.3864 10.362 19.6405 10.8856 19.7585 11.4467C19.8765 12.0079 19.8548 12.5894 19.6953 13.1402C19.5359 13.691 19.2436 14.1942 18.844 14.6055C18.7214 14.7322 18.604 14.8495 18.492 14.9615C18.2212 15.2195 17.9669 15.4942 17.7307 15.7842C17.4374 16.1602 17.3334 16.4362 17.3334 16.6669V17.6669C17.3334 17.9321 17.228 18.1865 17.0405 18.374C16.8529 18.5615 16.5986 18.6669 16.3334 18.6669C16.0682 18.6669 15.8138 18.5615 15.6263 18.374C15.4387 18.1865 15.3334 17.9321 15.3334 17.6669V16.6669C15.3334 15.7935 15.74 15.0855 16.152 14.5562C16.4574 14.1642 16.84 13.7829 17.152 13.4709C17.2454 13.3775 17.3334 13.2909 17.4094 13.2122C17.6149 13.0007 17.7535 12.7334 17.8079 12.4436C17.8623 12.1538 17.83 11.8544 17.7152 11.5828C17.6004 11.3112 17.4081 11.0795 17.1623 10.9166C16.9166 10.7537 16.6282 10.6668 16.3334 10.6669ZM16.3334 23.0002C16.687 23.0002 17.0261 22.8597 17.2762 22.6097C17.5262 22.3596 17.6667 22.0205 17.6667 21.6669C17.6667 21.3133 17.5262 20.9741 17.2762 20.7241C17.0261 20.474 16.687 20.3335 16.3334 20.3335C15.9798 20.3335 15.6406 20.474 15.3906 20.7241C15.1405 20.9741 15 21.3133 15 21.6669C15 22.0205 15.1405 22.3596 15.3906 22.6097C15.6406 22.8597 15.9798 23.0002 16.3334 23.0002Z"
                  fill={color}
               />
            </svg>
         );
      }
      default: {
         return null;
      }
   }
}

export default Icon;
