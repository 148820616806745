function IconWarning(props) {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="24"
         fill="none"
         viewBox="0 0 24 24"
         {...props}
      >
         <path
            fill="#EA3939"
            d="M10.03 4.16a2.25 2.25 0 013.94 0l7.74 14a2.25 2.25 0 01-1.97 3.34H4.25a2.25 2.25 0 01-1.97-3.34l7.75-14zM13 17.5a1 1 0 10-2-.06 1 1 0 002 .06zm-.26-7.85a.75.75 0 00-1.5.1l.01 4.5v.1a.75.75 0 001.5-.1v-4.5l-.01-.1z"
         ></path>
      </svg>
   );
}

export default IconWarning;
