function IconSettings(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M2.292 8.86a10.189 10.189 0 012.136-3.696.6.6 0 01.648-.162l2.302.823a1.2 1.2 0 001.584-.915L9.4 2.503a.6.6 0 01.465-.48c1.407-.3 2.86-.3 4.266 0a.6.6 0 01.465.48l.44 2.407a1.2 1.2 0 001.584.915l2.302-.823a.6.6 0 01.648.163 10.19 10.19 0 012.136 3.695.6.6 0 01-.183.642l-1.866 1.584a1.202 1.202 0 000 1.828l1.866 1.584a.6.6 0 01.183.642 10.188 10.188 0 01-2.136 3.696.6.6 0 01-.648.162l-2.302-.823a1.2 1.2 0 00-1.584.915l-.44 2.408a.6.6 0 01-.465.479c-1.406.3-2.86.3-4.266 0a.6.6 0 01-.465-.48l-.438-2.407a1.2 1.2 0 00-1.584-.915l-2.303.823a.599.599 0 01-.648-.163 10.187 10.187 0 01-2.136-3.695.6.6 0 01.184-.642l1.864-1.584a1.2 1.2 0 000-1.828L2.476 9.502a.6.6 0 01-.184-.642zM9.6 12a2.4 2.4 0 104.8 0 2.4 2.4 0 00-4.8 0z"
         ></path>
      </svg>
   );
}

export default IconSettings;
