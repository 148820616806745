import { useRef } from "react";

import classes from "./styles.module.scss";

import Icon, {ColorIconEnam, IconEnum} from "../../StateLess/Icon";

const {
    advantagesBlock,
    advantagesBlock__header,
    advantagesBlock__wrapper,
    advantagesBlock__card,
    advantagesBlock__cardIcon,
    advantagesBlock__cardTitle,
    advantagesBlock__cardText
} = classes;

const AdvantagesBlock = ({ data, editable = false, blockIndex = -1, onChange = () => {} }) => {

    const title = data.title;
    const advantages = data.advantages;

    const defaultTitle = useRef(title).current;
    const defaultAdvantages = useRef(advantages).current;

    const onTitleChange = (e) => {
        onChange(blockIndex, 'title', e.target.textContent);
    }

    const advantagesIcons = [
        () => <Icon className={advantagesBlock__cardIcon} name={IconEnum.rocket} color={ColorIconEnam.base} />,
        () => <Icon className={advantagesBlock__cardIcon} name={IconEnum.diploma} color={ColorIconEnam.base} />,
        () => <Icon className={advantagesBlock__cardIcon} name={IconEnum.clock} color={ColorIconEnam.base} />,
        () => <Icon className={advantagesBlock__cardIcon} name={IconEnum.sewn} color={ColorIconEnam.base} />
    ];

    return (
        <section className={advantagesBlock}>
            <h2
                className={advantagesBlock__header}
                contentEditable={editable} suppressContentEditableWarning={true}
                value={title}
                onChange={onTitleChange}
                onBlur={onTitleChange}
            >{defaultTitle}</h2>

            <div className={advantagesBlock__wrapper}>
                {
                    advantages.map((advantage, i) => (
                        i < 4 ? (
                            <div className={advantagesBlock__card} key={i}>
                                {advantagesIcons[i]()}
                                <h3
                                    className={advantagesBlock__cardTitle}
                                    contentEditable={editable}
                                    suppressContentEditableWarning={true}
                                    value={advantage.title}
                                    onChange={e => onChange(blockIndex, `advantages.${i}.title`, e.target.textContent)}
                                    onBlur={e => onChange(blockIndex, `advantages.${i}.title`, e.target.textContent)}
                                >{defaultAdvantages[i].title}</h3>
                                <p
                                    className={advantagesBlock__cardText}
                                    contentEditable={editable}
                                    suppressContentEditableWarning={true}
                                    value={advantage.text}
                                    onChange={e => onChange(blockIndex, `advantages.${i}.text`, e.target.textContent)}
                                    onBlur={e => onChange(blockIndex, `advantages.${i}.text`, e.target.textContent)}
                                >{defaultAdvantages[i].text}</p>
                            </div>
                        ) : ''
                    ))
                }
            </div>
        </section>
    );
}

export default AdvantagesBlock;