import AuthWrapper from "../../UILess/AuthWrapper";
import PAFooter from "../../PersonalArea/Footer";

import style from "./index.module.scss";

const { main, otherPageContainer } = style;

function PersonalAreaContainer({ isProfile = false, children }) {
   return (
      <AuthWrapper>
         <main className={isProfile ? main : otherPageContainer}>{children}</main>
         <PAFooter />
      </AuthWrapper>
   );
}

export default PersonalAreaContainer;
