import { useNavigate } from "react-router";
import logOut from "./logout";

class api {

    constructor (query = "", auth = false, data = {method: "GET"}) {
        this.query = query;
        this.auth = auth;
        this.data = data;
    }

    baseUrl = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? "http://192.168.43.52/api/" : "/api/";

    send = async () => {

        const {data, baseUrl, query, auth} = this;

        if (auth) {
            if (!data.headers) data.headers = {};
            data.headers.authorization = auth;
        }

        const request = await fetch(baseUrl + query, data);

        let resp = {};

        try {
            resp = await request.json();
        }
        catch (e) {
            return {
                "status": "error",
                "message": "Network error"
            };
        }

        if (resp.status === "error") {
            if (
                resp.message === "Unauthorized" ||
                resp.message === "Invalid token" ||
                resp.message === "Token doesn't exist"
            ) {
                logOut();
            }
            else if (resp.message === "Access denied") {
                window.location.href = "/";
            }
            else {
                return resp;
            }
        }
        else {
            return resp;
        }
    }
}

export default api;