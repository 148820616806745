import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addModal } from "../../store/reducers/modals";
import Icon, { ColorIconEnam, IconEnum } from "../../components/StateLess/Icon";
import { useEffect } from "react";
import LoadingComponent from "../../components/UI/ScreenLoader";
import { TextModal } from "../../components/ModalContents";


let initialState = {};

if (window.initialState) {
    initialState = window.initialState;
    window.initialState.content = null;
 }

let hasShown = false;

const PaymentResult = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'Сервис Онлайн Займов. 24/7. Срочный займ на карту, счёт!';

        setTimeout(() => {
            if (!hasShown) {
                if (typeof initialState.payment_result === 'boolean') {
                    if (initialState.payment_result) {
                        dispatch(addModal({
                            title: "Отлично!",
                            icon: <Icon name={IconEnum.ok} color={ColorIconEnam.base} />,
                            content: (props) => <TextModal
                                {...props}
                                text= "Баланс пополнен! Информацию о&nbsp;всех транзакциях вы&nbsp;сможете найти в&nbsp;личном кабинете."
                            />
                        }));
                    }
                    else {
                        dispatch(addModal({
                            title: "Что-то пошло не так...",
                            icon: <Icon name={IconEnum.expressionless} color={ColorIconEnam.prim} />,
                            content: (props) => <TextModal
                            {...props}
                            text= "Нам не удалось обработать платёж. Если деньги были списаны с&nbsp;Вашего счёта, попробуйте перейти в&nbsp;личный кабинет и&nbsp;посмотреть список транзакций там, иногда требуется время для&nbsp;обработки платежа.<br /><br />Если платёж не&nbsp;отобразится в&nbsp;личном кабинете, обратитесь в&nbsp;службу поддержки."
                            buttonText="Закрыть"
                            buttonVariant="primary"
                        />
                        }));
                    }
                }
                
                navigate('/');
                hasShown = true;
            }
        }, 1000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <LoadingComponent />;
}

export default PaymentResult;