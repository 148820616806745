import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import formatPhone from "../../../helpers/formatPhone";
import logOut from "../../../helpers/logout";

import css from "./styles.module.scss";

import Icon, { ColorIconEnam, IconEnum } from "../../StateLess/Icon";
import Button from "../../Inputs/Button";

const {
   clientHeaderBlock,
   clientHeaderBlock__infoBlock,
   clientHeaderBlock__phone,
   clientHeaderBlock__logOut,
   clientHeaderBlock__avatar,
   clientHeaderBlock__logOutIcon,
} = css;

const ClientHeaderBlock = ({ className, children, onLogOut, onClick }) => {
   const [phone, setPhone] = useState("");
   const [email, setEmail] = useState("");
   const token = checkAuth();

   const role = localStorage.getItem("role");

   let tmpClassName = clientHeaderBlock;
   tmpClassName += className ? " " + className : "";

   const update = async () => {
      if (token) {
         const response = await new api("user", token).send();

         if (response.status === "success") {
            setPhone(formatPhone(response.data.phone_number));
            setEmail(response.data.email);
         } else {
            logOut();
         }
      }
   };

   useEffect(() => {
      update();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return token ? (
      <div className={tmpClassName}>
         <Link
            to={role === "0" ? "/profile" : "/manage"}
            className={clientHeaderBlock__infoBlock}
            onClick={onClick}
            title="Перейти в личный кабинет"
         >
            <Icon
               className={clientHeaderBlock__avatar}
               name={IconEnum.user}
               color={ColorIconEnam.base}
            />

            <p className={clientHeaderBlock__phone}>{role === "0" ? phone : email}</p>
         </Link>

         <Button
            className={clientHeaderBlock__logOut}
            onClick={() => logOut("/", onLogOut)}
            title="Выйти"
         >
            <Icon
               className={clientHeaderBlock__logOutIcon}
               name={IconEnum.enter}
               color={"#ffffff"}
            />
         </Button>
      </div>
   ) : (
      children
   );
};

export default ClientHeaderBlock;
