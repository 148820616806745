import ContinerPageProfile from "../../components/PersonalArea/ContainerPageProfile";
import PaymentForm from "../../components/StateFull/PaymentForm";
import checkAuth from "../../helpers/checkAuth";
import api from "../../helpers/fetch";
import { useState } from "react";
import { useEffect } from "react";

function Payment() {

   const [state, setState] = useState({});

   const getData = async () => {

      const response = await new api("payments", checkAuth()).send();

      if (response.status === "success") {
         setState({
            minimalPayment: response.data.minimal,
            nextPaymentDate: response.data.next_payment,
            number: response.data.number,
            paymentMethods: response.data.payments_methods,
            totalPayment: response.data.total
         });
      }
      else {
         
      }
   }

   useEffect(() => {
      getData();
      document.title = 'Внести платеж - ЭкспрессФинанс';
   }, []);

   return (
      <ContinerPageProfile>
         {
            state.paymentMethods ? (
               <PaymentForm
                  minimalPayment={state.minimalPayment}
                  nextPaymentDate={state.nextPaymentDate}
                  number={state.number}
                  paymentMethods={state.paymentMethods}
                  totalPayment={state.totalPayment}
               />
            ) : (
               ""
            )
         }
      </ContinerPageProfile>
   );
}

export default Payment;
