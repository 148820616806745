import { useRef } from "react";
import classes from "./styles.module.scss";

const {
    threeDots,
    threeDots__header,
    threeDots__wrapper,
    threeDots__card,
    threeDots__cardDot,
    threeDots__cardDot_secondary,
    threeDots__cardTitle,
    threeDots__cardText
} = classes;

const ThreeDots = ({ data, editable = false, blockIndex = -1, onChange = () => {} }) => {

    let color = data.color;
    if (color !== "primary" && color !== "secondary") {
        color = "secondary";
    }

    const title = data.title;
    const defaultTitle = useRef(title).current;

    const onTitleChange = (e) => {
        onChange(blockIndex, "title", e.target.textContent);
    }

    const list = data.list;
    const defaultList = useRef(list).current;

    return (
        <section className={threeDots}>
            <h2
                className={threeDots__header}
                contentEditable={editable}
                suppressContentEditableWarning={true}
                value={title}
                onChange={onTitleChange}
                onBlur={onTitleChange}
            >{defaultTitle}</h2>

            <div className={threeDots__wrapper}>
                {
                    list.map((listItem, i) => (
                        <div className={threeDots__card} key={i}>
                            <div className={threeDots__cardDot + (color === "secondary" ? " " + threeDots__cardDot_secondary : "")}>
                                {i + 1}
                            </div>

                            <h3
                                className={threeDots__cardTitle}
                                contentEditable={editable}
                                suppressContentEditableWarning={true}
                                value={listItem.title}
                                onChange={e =>
                                    onChange(blockIndex, `list.${i}.title`, e.target.textContent)
                                }
                                onBlur={e =>
                                    onChange(blockIndex, `list.${i}.title`, e.target.textContent)
                                }
                            >{defaultList[i].title}</h3>

                            <p
                                className={threeDots__cardText}
                                contentEditable={editable}
                                suppressContentEditableWarning={true}
                                value={listItem.text}
                                onChange={e =>
                                    onChange(blockIndex, `list.${i}.text`, e.target.textContent)
                                }
                                onBlur={e =>
                                    onChange(blockIndex, `list.${i}.text`, e.target.textContent)
                                }
                            >{defaultList[i].text}</p>
                        </div>
                    ))
                }
            </div>
        </section>
    )
}

export default ThreeDots;