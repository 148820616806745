import css from "./styles.module.scss";

const {
    inputRadio,
    inputRadio__label,
    inputRadio__wrapper,
    inputRadio__option,
    inputRadio__input,
    inputRadio__optionText
} = css;

const InputRadio = ({className, value, name, label, options = [], onChange = () => {}, error}) => {

    let tmpClass = inputRadio + (className ? " " + className : "");

    return (
        <div className={tmpClass}>
            {
                label ? (
                    <p className={inputRadio__label}>{label}</p>
                ) : ""
            }
            <div className={inputRadio__wrapper}>
                {
                    options.map((option, i) => (
                        <label
                            className={inputRadio__option}
                            key={i}
                        >
                            <input
                                type={"radio"}
                                className={inputRadio__input}
                                name={name}
                                checked={i === value}
                                onChange={()=>onChange(i)}
                            />
                            <p className={inputRadio__optionText}>
                                {option}
                            </p>
                        </label>
                    ))
                }
            </div>
        </div>
    );
}

export default InputRadio;