import React from "react";

import Button from "../../Inputs/Button";
import IconAttach from "../../Icons/Attach";

import ToolTipWrapper, { createInputToolTip } from "../../Inputs/ToolTipWrapper";

import css from "./index.module.scss";

const { 
   contentButton,
   contentButton__button
} = css;

const ContentButton = ({
   data,
   blockIndex = -1,
   editable = false,
   onChange = () => {},
}) => {
   // Копируем значение текста по умолчанию
   const defaultText = data.text + "";

   return (
      <div className={contentButton}>
         {/* Обертка для всплывающей подсказки */}
         <ToolTipWrapper
            // Создаем содержимое всплывающей подсказки с помощью функции createInputToolTip
            tooltipContent={createInputToolTip(
               <IconAttach />,
               "Ссылка",
               "text",
               data.href,
               (val) => onChange(blockIndex, "href", val)
            )}
            // Показываем подсказку при наведении, если режим редактирования активен
            showOnHover={editable}
         >
            {/* Компонент кнопки */}
            <Button
               className={contentButton__button}
               href={editable ? null : data.href}
               contentEditable={editable}
               suppressContentEditableWarning={true}
               value={data.text}
               // Обработчик изменения текста кнопки
               onChange={(e) => onChange(blockIndex, "text", e.target.textContent)}
            >
               {/* Выводим текст кнопки */}
               {defaultText}
            </Button>
         </ToolTipWrapper>
      </div>
   );
};

export default ContentButton;
