import React from "react";

export default function IconLogo(props) {
   return (
      <svg width="258" height="33" viewBox="0 0 258 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
         <path d="M26.6935 25.5617L29.6788 8.72247H34.1077L32.9078 15.498L33.955 14.4453L40.6893 8.72247H46.2091L38.5148 15.3822C39.6057 17.0028 40.5766 18.7066 41.4274 20.4901C42.2783 22.2698 42.9328 23.9628 43.3874 25.5617H38.5803C38.2966 24.5525 37.8421 23.3949 37.2203 22.0854C36.5985 20.7758 35.8895 19.5278 35.1004 18.3449L31.9151 21.1086L31.126 25.5617H26.6935Z" fill="#DB7309"/>
         <path d="M51.8888 26.0031C50.4125 26.0031 49.1144 25.7607 48.0017 25.2723C46.8854 24.784 46.0236 24.1292 45.4091 23.3081C44.7946 22.4869 44.38 21.55 44.1655 20.4973C43.951 19.4446 43.9437 18.3377 44.151 17.1765C44.3546 16.037 44.7473 14.9481 45.3327 13.9135C45.9182 12.8789 46.6636 11.9492 47.569 11.1245C48.4744 10.3033 49.5726 9.64854 50.8598 9.16019C52.147 8.67183 53.5288 8.42946 55.0051 8.42946C56.3723 8.42946 57.5541 8.60672 58.5467 8.95399L56.9759 12.5895C56.3359 12.3472 55.5251 12.2278 54.5433 12.2278C52.9797 12.2278 51.6743 12.7089 50.6271 13.6675C49.5798 14.6262 48.9362 15.791 48.6926 17.162C48.4417 18.5764 48.6708 19.7702 49.3798 20.7469C50.0889 21.7236 51.2198 22.212 52.7724 22.212C53.7215 22.212 54.576 22.0745 55.3396 21.7996L55.6341 25.3519C54.4742 25.786 53.2233 26.0031 51.8888 26.0031Z" fill="#DB7309"/>
         <path d="M57.6559 25.5617L60.6412 8.72247H75.8479L72.8625 25.5617H68.3827L70.7099 12.4376H64.4629L62.1357 25.5617H57.6559Z" fill="#DB7309"/>
         <path d="M78.8914 9.54364C81.7058 8.84185 84.2403 8.49096 86.4984 8.49096C87.7419 8.49096 88.8619 8.63566 89.8509 8.92505C90.84 9.21445 91.6472 9.61599 92.2727 10.126C92.8981 10.6361 93.3963 11.233 93.7635 11.9167C94.1344 12.6004 94.3635 13.3347 94.458 14.1125C94.5526 14.8902 94.5235 15.7078 94.3708 16.5615C94.1708 17.6901 93.8035 18.7428 93.2654 19.7195C92.7272 20.6962 92.029 21.5681 91.1636 22.3422C90.2982 23.1163 89.2291 23.7241 87.9565 24.169C86.6838 24.614 85.2729 24.8346 83.7312 24.8346C82.7058 24.8346 81.7095 24.6827 80.7386 24.3752L79.2986 32.5H74.8188L78.8914 9.54364ZM81.404 20.6456C82.3094 20.8771 83.1458 20.9893 83.9057 20.9893C84.6002 20.9893 85.262 20.9061 85.8911 20.7433C86.5202 20.5805 87.1129 20.3273 87.6619 19.9872C88.211 19.6472 88.6801 19.1878 89.0619 18.6054C89.4437 18.0229 89.7055 17.3465 89.8437 16.5651C90.0982 15.129 89.811 14.0546 88.9855 13.342C88.1601 12.6293 86.9856 12.2748 85.4657 12.2748C84.673 12.2748 83.8003 12.3472 82.8476 12.4882L81.404 20.6456Z" fill="#DB7309"/>
         <path d="M103.978 25.9886C101.112 25.9886 98.9306 25.2145 97.4288 23.6698C95.9271 22.1251 95.4325 19.9257 95.938 17.0752C96.178 15.715 96.6325 14.4815 97.2979 13.3673C97.9633 12.2531 98.7597 11.3451 99.6905 10.6361C100.621 9.92709 101.643 9.38447 102.756 9.00102C103.872 8.61757 105.018 8.42584 106.196 8.42584C107.781 8.42584 109.156 8.74056 110.316 9.37C111.476 9.99944 112.33 10.9472 112.872 12.2061C113.414 13.465 113.534 14.9409 113.235 16.6302C113.181 16.9377 113.072 17.3935 112.912 17.994H100.152C100.091 19.3976 100.476 20.4539 101.309 21.1665C102.141 21.8792 103.25 22.2337 104.639 22.2337C106.523 22.2337 108.563 21.6693 110.759 20.5407L111.225 24.2594C108.807 25.4134 106.392 25.9886 103.978 25.9886ZM100.872 15.1688H109.072C109.003 14.1921 108.657 13.4288 108.032 12.8753C107.407 12.3218 106.585 12.0433 105.559 12.0433C104.621 12.0433 103.727 12.3218 102.876 12.8753C102.021 13.4288 101.352 14.1921 100.872 15.1688Z" fill="#DB7309"/>
         <path d="M122.555 26.0031C121.079 26.0031 119.781 25.7607 118.668 25.2723C117.552 24.784 116.69 24.1292 116.075 23.3081C115.461 22.4869 115.046 21.55 114.832 20.4973C114.617 19.4446 114.61 18.3377 114.817 17.1765C115.021 16.037 115.414 14.9481 115.999 13.9135C116.584 12.8789 117.33 11.9492 118.235 11.1245C119.141 10.3033 120.239 9.64854 121.526 9.16019C122.813 8.67183 124.195 8.42946 125.671 8.42946C127.039 8.42946 128.22 8.60672 129.213 8.95399L127.642 12.5895C127.002 12.3472 126.191 12.2278 125.21 12.2278C123.646 12.2278 122.341 12.7089 121.293 13.6675C120.246 14.6262 119.602 15.791 119.359 17.162C119.108 18.5764 119.337 19.7702 120.046 20.7469C120.755 21.7236 121.886 22.212 123.439 22.212C124.388 22.212 125.242 22.0745 126.006 21.7996L126.3 25.3519C125.14 25.786 123.89 26.0031 122.555 26.0031Z" fill="#DB7309"/>
         <path d="M136.06 26.0031C134.584 26.0031 133.286 25.7607 132.173 25.2723C131.057 24.784 130.195 24.1292 129.58 23.3081C128.966 22.4869 128.551 21.55 128.337 20.4973C128.122 19.4446 128.115 18.3377 128.322 17.1765C128.526 16.037 128.918 14.9481 129.504 13.9135C130.089 12.8789 130.835 11.9492 131.74 11.1245C132.646 10.3033 133.744 9.64854 135.031 9.16019C136.318 8.67183 137.7 8.42946 139.176 8.42946C140.543 8.42946 141.725 8.60672 142.718 8.95399L141.147 12.5895C140.507 12.3472 139.696 12.2278 138.714 12.2278C137.151 12.2278 135.845 12.7089 134.798 13.6675C133.751 14.6262 133.107 15.791 132.864 17.162C132.613 18.5764 132.842 19.7702 133.551 20.7469C134.26 21.7236 135.391 22.212 136.944 22.212C137.893 22.212 138.747 22.0745 139.511 21.7996L139.805 25.3519C138.645 25.786 137.394 26.0031 136.06 26.0031Z" fill="#DB7309"/>
         <path d="M151.678 25.5617L152.201 22.6026C149.005 22.3603 146.623 21.3257 145.06 19.4953C143.492 17.6648 142.958 15.3497 143.456 12.557C143.947 9.78239 145.281 7.53233 147.456 5.79957C149.63 4.06681 152.379 3.09372 155.703 2.87305L156.117 0.5H160.514L160.095 2.86943C163.339 3.0901 165.746 4.05958 167.317 5.78148C168.884 7.50339 169.422 9.76068 168.928 12.557C168.433 15.3424 167.077 17.654 164.855 19.488C162.633 21.3257 159.881 22.3639 156.601 22.6063L156.077 25.5653H151.678V25.5617ZM148.034 12.5534C147.736 14.2427 147.994 15.6571 148.812 16.7966C149.63 17.9361 150.983 18.6234 152.87 18.8513L155.041 6.59903C153.056 6.80884 151.463 7.43466 150.27 8.48011C149.074 9.52917 148.329 10.8857 148.034 12.5534ZM157.263 18.8513C159.223 18.6198 160.815 17.9361 162.041 16.7966C163.266 15.6571 164.03 14.2427 164.332 12.5534C164.63 10.864 164.368 9.50023 163.546 8.4584C162.721 7.41657 161.354 6.79799 159.437 6.59903L157.263 18.8513Z" fill="#5D9922"/>
         <path d="M168.95 25.5617L171.942 8.67545H176.389L175.124 15.8127C176.851 13.7073 179.658 11.3307 183.545 8.67545H187.331L184.338 25.5617H179.891L181.909 14.1812C180.189 15.4871 178.636 16.8509 177.251 18.2762C175.862 19.7014 174.76 21.0942 173.949 22.4543L173.4 25.5617H168.95Z" fill="#5D9922"/>
         <path d="M198.407 25.5617L199.745 18.0157H193.349L192.01 25.5617H187.531L190.516 8.72247H194.996L194.01 14.2789H200.407L201.392 8.72247H205.854L202.868 25.5617H198.407Z" fill="#5D9922"/>
         <path d="M212.828 25.9886C210.515 25.9886 208.77 25.4966 207.603 24.5091C206.432 23.5215 206.01 22.1071 206.337 20.2658C206.643 18.533 207.526 17.1982 208.981 16.2613C210.435 15.3243 212.253 14.8541 214.435 14.8541C215.602 14.8541 216.897 15.0458 218.319 15.4292C218.428 14.3114 218.268 13.4903 217.839 12.973C217.406 12.4521 216.526 12.1916 215.191 12.1916C214.399 12.1916 213.548 12.3074 212.642 12.5389C211.737 12.7704 210.93 13.049 210.221 13.3781L209.519 9.87644C210.53 9.44958 211.675 9.10231 212.951 8.831C214.228 8.56331 215.384 8.42946 216.42 8.42946C221.511 8.42946 223.646 10.741 222.827 15.3677L221.14 24.8889C219.813 25.2181 218.373 25.4821 216.817 25.6847C215.26 25.8873 213.93 25.9886 212.828 25.9886ZM210.922 20.0523C210.81 20.6999 210.955 21.2425 211.359 21.6874C211.766 22.1324 212.49 22.353 213.537 22.353C214.715 22.353 215.93 22.2554 217.177 22.0564L217.853 18.24C216.693 18.0085 215.519 17.8963 214.326 17.8963C213.279 17.8963 212.475 18.1062 211.915 18.5294C211.359 18.9526 211.028 19.4591 210.922 20.0523Z" fill="#5D9922"/>
         <path d="M234.889 25.5617L236.227 18.0157H229.831L228.493 25.5617H224.013L226.998 8.72247H231.478L230.493 14.2789H236.889L237.874 8.72247H242.336L239.35 25.5617H234.889Z" fill="#5D9922"/>
         <path d="M251.026 26.0031C249.55 26.0031 248.252 25.7607 247.139 25.2723C246.023 24.784 245.161 24.1292 244.547 23.3081C243.932 22.4869 243.518 21.55 243.299 20.4973C243.085 19.4446 243.078 18.3377 243.285 17.1765C243.485 16.037 243.881 14.9481 244.467 13.9135C245.052 12.8789 245.797 11.9492 246.703 11.1245C247.608 10.3033 248.706 9.64854 249.994 9.16019C251.281 8.67183 252.663 8.42946 254.139 8.42946C255.506 8.42946 256.688 8.60672 257.681 8.95399L256.11 12.5895C255.47 12.3472 254.659 12.2278 253.677 12.2278C252.114 12.2278 250.805 12.7089 249.761 13.6675C248.714 14.6262 248.07 15.791 247.826 17.162C247.576 18.5764 247.805 19.7702 248.514 20.7469C249.223 21.7236 250.354 22.212 251.906 22.212C252.855 22.212 253.71 22.0745 254.473 21.7996L254.768 25.3519C253.612 25.786 252.361 26.0031 251.026 26.0031Z" fill="#5D9922"/>
         <path d="M12.534 21.6947C12.534 21.6947 5.31615 25.8439 0 18.5583L6.26884 16.8147C6.26884 16.8183 6.91609 21.5391 12.534 21.6947Z" fill="#5D9922"/>
         <path d="M26.2135 8.82015C25.8608 7.30805 25.2353 6.00938 24.3445 4.92415C23.45 3.83891 22.2973 2.98519 20.8792 2.36661C19.4647 1.74802 17.8793 1.43692 16.1266 1.43692C14.374 1.43692 12.6759 1.74802 11.0359 2.36661C9.81779 2.82602 8.7051 3.41567 7.6906 4.12831L10.3123 7.30081C11.7923 6.26984 13.4758 5.74531 15.363 5.74531C17.6211 5.74531 19.3338 6.4905 20.4974 7.9809C21.4355 9.18551 21.8609 10.6614 21.77 12.4014H10.8323C10.465 14.4778 12.0722 16.3842 14.1922 16.3842H20.8646C17.2502 25.7462 5.74159 24.4005 5.74159 24.4005C7.43606 25.4677 9.44689 26.0031 11.7704 26.0031C14.094 26.0031 16.2939 25.4677 18.3702 24.4005C20.4428 23.3334 22.1773 21.8575 23.5736 19.9764C24.9699 18.0953 25.8753 16.0008 26.2826 13.6856C26.5917 11.9565 26.5698 10.3322 26.2135 8.82015Z" fill="#DB7309"/>
      </svg>
   );
}
