import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import api from "../../helpers/fetch";
import BlockWrapper from "../../components/Containers/BlockWrapper";
import Button from "../../components/Inputs/Button";
import Input from "../../components/Inputs/Input";

import scss from "./index.module.scss";
import Icon, { ColorIconEnam, IconEnum } from "../../components/StateLess/Icon";
import Footer from "../../components/StateFull/Footer";
import PaymentForm from "../../components/StateFull/PaymentForm";
import PAFooter from "../../components/PersonalArea/Footer";

const {
   PaymentUnauthorized__container,
   PaymentUnauthorized__wrapper,
   PaymentUnauthorized__iconWraper,
   PaymentUnauthorized__icon,
   PaymentUnauthorized__title,
   PaymentUnauthorized__text,
   PaymentUnauthorized__secondaryText,
   PaymentUnauthorized__form,
   PaymentUnauthorized__input,
   PaymentUnauthorized__button,

   wrapper,
   
   container,


} = scss;

function PaymentUnauthorized() {

   useEffect(() => {
      document.title = 'Онлайн-оплата задолженности - ЭкспрессФинанс';
   }, []);

   const navigate = useNavigate();
   const param = useParams();

   const [number, setNumber] = useState("");
   const [numberError, setNumberError] = useState(false);
   // const [date, setDate] = useState("");
   // const [dateError, setDateError] = useState(false);

   const [wait, setWait] = useState(false);
   const [response, setResponse] = useState();

   const [errorMessage, setErrorMessage] = useState(false);

   const getCompact = async (e) => {
      e.preventDefault();
      setWait(true);
      // setDateError(false);
      setNumberError(false);
      setErrorMessage(false);
      const request = new api(`payments?number=${number}`, false); // &bday=${date}
      const res = await request.send();
      
      setWait(false);

      if (res.status === "success") {

         setResponse({
            ...res.data,
            number
         });
      }
      else {
         if (res.message === "Can't get payment information") {
            setErrorMessage("Не удалось получить платёжную информацию.");
         }
         else if (res.message === "Loan not found") {
            setNumberError("Заём с таким номером не найден.");
         }
         // else if (res.message === "Bday is invalid" || res.message === "Bday is empty") {
         //    setDateError("Дата рождения не указана или указана не корректно.")
         // }
         else if (res.message === "Number is invalid" || res.message === "Number is empty") {
            setNumberError("Номер не указан или указан не корректно.");
         }
         else {
            navigate("/404");
         }
      }
   };

   const resetError = () => {
      if (number) {
         setNumberError(false);
      }
   }

   const onChangeNumber = (number) => {
      setNumber(number);
      resetError();
   };

   return (
      <>
         {param["token"] ? (
               <section className={container}>
                  <Outlet />
               </section>
            ) : (
               !response ? (
                  <>
                     <section className={PaymentUnauthorized__container}>
                        <BlockWrapper className={PaymentUnauthorized__wrapper}>

                           <div className={PaymentUnauthorized__iconWraper}>
                              <Icon className={PaymentUnauthorized__icon} name={IconEnum.document} color={ColorIconEnam.base} />
                           </div>

                           <h2 className={PaymentUnauthorized__title}>Оплата по номеру договора</h2>
                           {errorMessage ? <p className={PaymentUnauthorized__text}>{errorMessage}</p> : ""}
                           <form className={PaymentUnauthorized__form} onSubmit={getCompact} action="">
                              <Input
                                 className={PaymentUnauthorized__input}
                                 type="text"
                                 placeholder="Номер договора"
                                 name="number"
                                 regex={/[0-9-]/}
                                 value={number}
                                 onChange={(number) => onChangeNumber(number)}
                                 error={numberError}
                              />
                              <div className={wrapper}>
                                 <Button
                                    isWaiting={wait}
                                    className={PaymentUnauthorized__button}
                                    type={"sabmit"}
                                    disabled={!number || numberError} //  || !date  || dateError
                                 >
                                    Найти
                                 </Button>

                                 
                                 <p className={PaymentUnauthorized__secondaryText}>
                                    Указывая номер договора, вы соглашаетесь на <Link to="/politika-konfidencialnosti">обработку персональных данных</Link>.
                                 </p>
                              </div>
                           </form>
                        </BlockWrapper>
                     </section>

                     <Footer />
                  </>
               ) : (
                  <>
                     <section className={PaymentUnauthorized__container}>
                        <PaymentForm
                           number={response.number}
                           minimalPayment={response.minimal}
                           totalPayment={response.total}
                           token = {response.token}
                           nextPaymentDate={response.next_payment}
                           paymentMethods={response.payments_methods}
                        />
                     </section>

                     <PAFooter />
                  </>
               )
            )
         }
      </>
   );
}

export default PaymentUnauthorized;
