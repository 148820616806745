import {
    createSlice
} from '@reduxjs/toolkit';

export const modalsReducers = createSlice({
    name: 'modals',
    initialState: {
        list: []
    },
    reducers: {
        addModal: (state, action) => {
            const {
                title = "",
                icon  = "",
                content = "",
                onClose = () => {}
            } = action.payload;

            state.list.push({
                title,
                icon,
                content,
                onClose,
                datetime: new Date().getTime()
            });
        },

        removeModal: (state, action) => {
            const index = action.payload;

            const lastIndex = state.list.length - 1;

            state.list.splice(index ? index : lastIndex, 1);
        }
    },
});

export const {
    addModal,
    removeModal
} = modalsReducers.actions;

export default modalsReducers.reducer;