import Button from "../../Inputs/Button";

import classes from "../styles.module.scss";

const {
    modalContent__text,
    modalContent__button
} = classes;

const PaymentIssue = ({
    onClose = () => {},
    children
}) => {

    return (
        <>
            <p className={modalContent__text}>
                {children}
            </p>
            
            <Button className={modalContent__button} onClick={onClose} variant="primary">
                Закрыть
            </Button>
        </>
    );
}

export default PaymentIssue;