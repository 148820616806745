import { Link } from "react-router-dom";
import IconArchive from "../Icons/IconArchive";
import IconBookContacts from "../Icons/IconBookContacts";
import IconBookQuestionMark from "../Icons/IconBookQuestionMark";
import IconCalendar from "../Icons/IconCalendar";
import IconDismiss from "../Icons/IconDismiss";
import IconDocument from "../Icons/IconDocument";
import IconGlance from "../Icons/IconGlance";
import IconMailInbox from "../Icons/IconMailInbox";
import IconMoney from "../Icons/IconMoney";
import IconPeople from "../Icons/IconPeople";
import IconPerson from "../Icons/IconPerson";
import IconPersonFeedback from "../Icons/IconPersonFeedback";
import IconSBP from "../Icons/IconSBP";
import IconSettings from "../Icons/IconSettings";
import scss from "./index.module.scss";
import IconCard from "../Icons/Card";

const { list__item, list__text, list__icon } = scss;

function AsideListItem({ className, title, link, disabeld }) {
   const getIcon = (link) => {
      switch (link) {
         case "/manage": {
            return <IconGlance className={list__icon} />;
         }
         case "pages": {
            return <IconDocument className={list__icon} />;
         }
         case "applications": {
            return <IconMailInbox className={list__icon} />;
         }
         case "loans": {
            return <IconMoney className={list__icon} />;
         }
         case "archive": {
            return <IconArchive className={list__icon} />;
         }
         case "customers": {
            return <IconPeople className={list__icon} />;
         }
         case "staff": {
            return <IconPerson className={list__icon} />;
         }
         case "education": {
            return <IconBookQuestionMark className={list__icon} />;
         }
         case "support": {
            return <IconPersonFeedback className={list__icon} />;
         }
         case "settings":
         case "calculator": {
            return <IconSettings className={list__icon} />;
         }
         case "about-organization": {
            return <IconBookContacts className={list__icon} />;
         }
         case "payment-information": {
            return <IconCard className={list__icon} />;
         }
         case "schedule": {
            return <IconCalendar className={list__icon} />;
         }
         case "banks-sbp": {
            return <IconSBP className={list__icon} />;
         }
         default: {
            return <IconDismiss className={list__icon} />;
         }
      }
   };
   return (
      <li className={className ? className + " " + list__item : list__item}>
         {disabeld ? (
            <a to={link}>
               {link ? getIcon(link) : null}
               <span className={list__text}>{title}</span>
            </a>
         ) : (
            <Link to={link}>
               {link ? getIcon(link) : null}
               <span className={list__text}>{title}</span>
            </Link>
         )}
      </li>
   );
}

export default AsideListItem;
