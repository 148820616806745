import React from "react";

export default function IconCopy(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
         <path
            fill="#2D9614"
            d="M22.98 25V10.98H12.02V25h10.96zm0-15.98c.54 0 1 .2 1.41.6.4.38.61.83.61 1.36V25c0 .53-.2 1-.6 1.4-.42.41-.88.62-1.42.62H12.02c-.54 0-1-.2-1.41-.61A1.93 1.93 0 0110 25V10.98c0-.53.2-.98.6-1.36.42-.4.88-.6 1.42-.6h10.96zm-3-4.04V7h-12v14.02H6.02V7a2.01 2.01 0 011.96-2.02h12z"
         ></path>
      </svg>
   );
}
