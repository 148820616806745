import Button from "../Button";
import Input from "../Input";

import classes from "./styles.module.scss";

const {
    inputHint,
    inputHint__input,
    inputHint__optionsList,
    inputHint__option
} = classes;

const InputHint = ({
    value = "",
    onChange = () => {},
    type = "text",
    placeholder,
    title,
    options = []
}) => {

    return (
        <div className={inputHint}>
            <Input
                className={inputHint__input}
                type={type}
                value={value}
                onChange={e => onChange(e)}
                placeholder={placeholder}
                title={title}
            />

            <div className={inputHint__optionsList}>
                {
                    options.map((option, i) => (
                        <Button
                            className={inputHint__option}
                            variant={"transparent"}
                            key={i}
                            onClick={() => onChange(option.value)}
                            style={{display: value === option.value ? "none" : null}}
                        >
                            {option.text}
                        </Button>
                    ))
                }
            </div>
        </div>
    );
}

export default InputHint;