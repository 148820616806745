import manageRouter from "../manage/router";

// Страницы с компонентами
import Error from "../pages/Error";
import Home from "../pages/Home";
import Root from "./root";
import Profile from "../pages/Profile";
import ResetPassword from "../pages/ResetPassword";
import Payment from "../pages/Payment";
import Application from "../pages/Application";
import AboutLoan from "../pages/AboutLoan";
import VirtualCard from "../pages/VirtualСard";

// Обёртки
import PaymentUnauthorized from "../pages/PaymentUnauthorized";
import Approved from "../pages/Approved";
import PersonalAreaContainer from "../components/PersonalArea/PersonalAreaContainer";
import PaymentResult from "../pages/PaymentResult";

const router = [
   {
      path: "/",
      element: <Root />,
      errorElement: <Error />,
      children: [
         {
            index: true,
            element: <Home />,
         },
         {
            path: "404",
            element: <Error />,
         },
         {
            path: "oplatit",
            element: <PaymentUnauthorized />,
         },
         {
            path: "payment_result/:token",
            element: <PaymentResult />
         },
         {
            path: "reset-password",
            element: <ResetPassword />,
         },
         {
            path: "reset-password/:token",
            element: <ResetPassword />,
         },
         {
            path: "profile",
            element: <PersonalAreaContainer isProfile={true} children={<Profile />} />,
         },
         {
            path: "sign",
            element: <PersonalAreaContainer children={<Approved />} />,
         },
         {
            path: "application",
            element: <PersonalAreaContainer children={<Application />} />,
         },
         {
            path: "loan/:idLoan",
            element: <PersonalAreaContainer children={<AboutLoan />} />,
         },
         {
            path: "loan/",
            element: <PersonalAreaContainer children={<AboutLoan />} />,
         },
         {
            path: "payment",
            element: <PersonalAreaContainer children={<Payment />} />
         },
         {
            path: "virtual-card",
            element: <PersonalAreaContainer children={<VirtualCard />} />,
         },
      ],
   },
   ...manageRouter,
];

export default router;
