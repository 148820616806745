function IconArrowLeft(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M10.478 20.154a.9.9 0 001.236-1.308L5.428 12.9h15.268a.9.9 0 100-1.8H5.426l6.288-5.948a.9.9 0 10-1.236-1.308l-7.701 7.283c-.192.181-.318.42-.36.68a.904.904 0 00.002.394c.043.257.169.492.358.67l7.701 7.284v-.001z"
         ></path>
      </svg>
   );
}

export default IconArrowLeft;
