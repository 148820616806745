import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../components/StateFull/Footer";
import PageBuilder from "../../components/UILess/PageBuilder";
import HeadingContentPage from "../../components/Contented/HeadingContentPage";

function ContentPages() {
   let initialState = {};
   const location = useLocation();

   if (window.initialState?.content) {
      initialState = window.initialState;
      window.initialState.content = null;
   }

   const [content, setContent] = useState(initialState.content || null);
   const [pageData, setPageData] = useState({});

   const getPageContent = async () => {
      const resp = await (
         await fetch(global.api + "pages?url=" + location.pathname)
      ).json();
      setContent(resp.json_content);
      document.title = (resp.meta.title ? resp.meta.title : resp.title + " ЭкспрессФинанс");
      setPageData({
         title: resp.title,
         meta: resp.meta,
         pathname: resp.pathname,
      });
   };

   if (content === null) {
      setContent([]);
      getPageContent();
   }

   useEffect(() => {
      getPageContent();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [location]);

   return (
      <>
         <main>
            <HeadingContentPage pageData={pageData} />
            <PageBuilder content={content} />
         </main>
         <Footer />
      </>
   );
}

export default ContentPages;
