import { Link } from "react-router-dom"
import Button from "../../../Inputs/Button";
import Icon, { IconEnum } from "../../../StateLess/Icon";

import classes from "./styles.module.scss";
import Picture from "../../../StateLess/Picture";

const {
    creditDoctor,
    creditDoctor__img,
    creditDoctor__supTitle,
    creditDoctor__title,
    creditDoctor__button,
    creditDoctor__icon,
    creditDoctor__link
} = classes;

const CreditDoctorBanner = ({
    link = ''
}) => {


    return (
        <div className={creditDoctor}>
            <Picture
                className={creditDoctor__img}
                lazy
                fileName={"credit_doctor"}
                alt={"Кредитный доктор"}
                ftype="png"
                secondImges={["credit_doctor", "credit_doctor"]}
            />
            <p className={creditDoctor__supTitle}>Вам доступна услуга</p>
            <h2 className={creditDoctor__title}>Кредитный доктор<br />от наших партнёров</h2>
            <Button
                className={creditDoctor__button}
                href={link ? link : '#'}
                target="_blank"
            >
                Подробнее <Icon className={creditDoctor__icon} name={IconEnum.info} color={"#ffffff"} />
            </Button>
            <a className={creditDoctor__link} href={link ? link : '#'} target="_blank" rel="noreferrer">doctor.corector.ru</a>
        </div>
    );
}

export default CreditDoctorBanner;