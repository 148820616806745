import Button from "../../Inputs/Button";
import Icon, { ColorIconEnam, IconEnum } from "../../StateLess/Icon";

import classes from "./styles.module.scss";

const {
    copiedField,
    copiedField__text,
    copiedField__label,
    copiedField__value,
    copiedField__button,
    copiedField__icon
} = classes;

const CopiedField = ({
    label = "",
    value = "",
    valueToCopy = null
}) => {

    const copyText = () => {
        navigator.clipboard.writeText(valueToCopy)
    }

    return (
        <div className={copiedField}>
            <div className={copiedField__text}>
                <p className={copiedField__label}>
                    {label}
                </p>

                <p className={copiedField__value}>
                    {value}
                </p>
            </div>

            {
                valueToCopy !== null && navigator.clipboard ? (
                    <Button className={copiedField__button} variant={"transparent"} onClick={copyText}>
                        <Icon className={copiedField__icon} name={IconEnum.copy} color={ColorIconEnam.base} />
                    </Button>
                ) : ""
            }
        </div>
    )
}

export default CopiedField;