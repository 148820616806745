import Icon, { IconEnum } from "../../StateLess/Icon";

import scss from "./index.module.scss";

const {
   cardButton,
   cardButton__icon,
   cardButton__heading,
   cardButton__title,
   cardButton__text,
   cardButton__body,
   cardButton__icon__card,
} = scss;

function CardButton({
   className,
   iconName,
   iconColor,
   action = () => {},
   value = { title: "", text: "" },
   typeCard = "private",
   nameCard = "mir",
}) {
   const getIconCardByPaymentSystem = (name) => {
      switch (name) {
         case "mir": {
            return IconEnum.card_mir;
         }
         case "visa": {
            return IconEnum.card_visa;
         }
         case "mastercard": {
            return IconEnum.card_mastercard;
         }
         default: {
            return IconEnum.card_other;
         }
      }
   };
   return (
      <button className={cardButton + " " + className} onClick={action}>
         <div className={cardButton__heading}>
            <Icon className={cardButton__icon} name={iconName} color={iconColor} />
            <div className={cardButton__body}>
               <span className={cardButton__title}>{value.title}</span>
               <span className={cardButton__text}>{value.text}</span>
            </div>
         </div>
         {typeCard === "private" ? (
            <Icon
               className={cardButton__icon__card}
               name={getIconCardByPaymentSystem(nameCard)}
            />
         ) : (
            <Icon className={cardButton__icon__card} name={IconEnum.mir_express} />
         )}
      </button>
   );
}

export default CardButton;
