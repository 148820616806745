import Icon, { ColorIconEnam, IconEnum } from "../../StateLess/Icon";

import ServicesItem from "../ServicesItem";

import scss from "./index.module.scss";

const {
   listServices,
   listServices__item,
   listServices__title,
   listServices__button,
   listServices__icon,
} = scss;

function ListServices({ services, onStory }) {
   return (
      <>
         <ul className={listServices}>
            {services?.length === 0 ? (
               <li className={listServices__item}>
                  <span className={listServices__title}>Нет активных услуг</span>
               </li>
            ) : (
               services?.map((service, key) => (
                  <li key={key}>
                     <ServicesItem
                        iconName={IconEnum.card_arrow_up}
                        iconColor={ColorIconEnam.gray}
                        value={service}
                     ></ServicesItem>
                  </li>
               ))
            )}
         </ul>
         <button className={listServices__button} onClick={onStory}>
            <span>История займов</span>
            <Icon
               className={listServices__icon}
               name={IconEnum.chewron_right}
               color={ColorIconEnam.gray}
            />
         </button>
      </>
   );
}

export default ListServices;
