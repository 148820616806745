import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import logOut from "../../../helpers/logout";
import FormBuilder from "../../Components/FormBuilder";
import scss from "./index.module.scss";

const {} = scss;

function SettingsPage() {
   const [form, setForm] = useState([]);
   const [requestState, setRequestState] = useState(false);

   const params = useParams();

   const paramsArray = {
      "calculator": "calculator",
      "about-organization": "about",
      "payment-information": "payment_information",
      "banks-sbp": "banks_sbp"
   };

   const getSettings = async () => {
      const auth = checkAuth();

      if (!auth) logOut();

      const resp = await new api("manage/settings/"+paramsArray[params["slug"]], auth).send();

      if (resp.status === "success") {
         setForm(resp.data[0].data);
      }
   }

   useEffect (() => {
      getSettings();
   }, []);
   
   useEffect (() => {
      getSettings();
   }, [params["slug"]]);

   const onSubmit = async (formSubmit) => {
      const body = JSON.stringify({
         form: JSON.stringify(formSubmit)
      });

      const auth = checkAuth();
      
      if (!auth) logOut();
      
      setRequestState(true);

      const resp = await new api("manage/settings/"+paramsArray[params["slug"]], auth, {
         method: "PUT",
         body
      }).send();

      setTimeout(() => {
         setRequestState(false);
      }, 500);

      if (resp.status === "success") {
         setForm(formSubmit);
      }
      else {
         setForm(form);
      }
   }

   return <FormBuilder form={form} onSubmit={onSubmit} disabled={requestState} />;
}

export default SettingsPage;
