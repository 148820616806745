import { useRef } from "react";
import scss from "./index.module.scss";
import Picture from "../../StateLess/Picture";

const {
   illustration_block,
   illustration_block__img,
   illustration_block__card,
   illustration_block__title,
   illustration_block__text,
} = scss;

const IllustrationBlock = ({
   data,
   editable = false,
   blockIndex = -1,
   onChange = () => {},
}) => {
   const blocks = { ...data };

   const defaultBlocks = useRef(blocks).current;

   const onTileChange = (e, key) => {
      onChange(blockIndex, `${key}.title`, e.target.textContent);
   };

   const onTextChange = (e, key) => {
      onChange(blockIndex, `${key}.text`, e.target.textContent);
   };

   const blockElements = Object.keys(blocks).map((key) => {
      const block = blocks[key];
      return (
         <div className={illustration_block__card} key={key}>
            <Picture
               className={illustration_block__img}
               fileName={key}
               secondImges={[key, key]}
               ftype="png"
               alt=""
            />
            <h3
               className={illustration_block__title}
               contentEditable={editable}
               suppressContentEditableWarning={true}
               onChange={(e) => onTileChange(e, key)}
               onBlur={(e) => onTileChange(e, key)}
               value={block.title}
            >
               {defaultBlocks[key].title}
            </h3>
            <p
               className={illustration_block__text}
               contentEditable={editable}
               suppressContentEditableWarning={true}
               onChange={(e) => onTextChange(e, key)}
               onBlur={(e) => onTextChange(e, key)}
               value={block.text}
            >
               {defaultBlocks[key].text}
            </p>
         </div>
      );
   });

   return <section className={illustration_block}>{blockElements}</section>;
};

export default IllustrationBlock;
