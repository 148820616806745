import scss from "../index.module.scss";

const { section, title } = scss;

function Heading({ data }) {
   return (
      <section className={section}>
         <h2 className={title}>{data.text}</h2>
      </section>
   );
}

export default Heading;
