import checkAuth from "../../../helpers/checkAuth";
import LoginManagePage from "../../pages/Login";

const CheckkAuth = ({children}) => {

    const auth = checkAuth();

    return auth ? children : <LoginManagePage />;
}

export default CheckkAuth;