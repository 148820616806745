function IconMoney(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M4.2 4.8a1.8 1.8 0 00-1.8 1.8V15a1.8 1.8 0 001.8 1.8h13.2a1.8 1.8 0 001.8-1.8V6.6a1.8 1.8 0 00-1.8-1.8H4.2zm3 1.2v1.2a2.4 2.4 0 01-2.4 2.4H3.6V8.4h1.2A1.2 1.2 0 006 7.2V6h1.2zm3.6 6.9a2.1 2.1 0 110-4.2 2.1 2.1 0 010 4.2zm-7.2.3V12h1.2a2.4 2.4 0 012.4 2.4v1.2H6v-1.2a1.2 1.2 0 00-1.2-1.2H3.6zm13.2 0a1.2 1.2 0 00-1.2 1.2v1.2h-1.2v-1.2a2.4 2.4 0 012.4-2.4H18v1.2h-1.2zm0-4.8H18v1.2h-1.2a2.4 2.4 0 01-2.4-2.4V6h1.2v1.2a1.2 1.2 0 001.2 1.2zm3.6 6.6a3 3 0 01-3 3H4.902A1.8 1.8 0 006.6 19.2h10.8a4.2 4.2 0 004.2-4.2V9a1.8 1.8 0 00-1.2-1.698V15z"
         ></path>
      </svg>
   );
}

export default IconMoney;
