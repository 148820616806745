import Breadcrumbs from "../Breadcrumbs";
import scss from "./index.module.scss";

const { header } = scss;

function Header({ className }) {
   return (
      <header className={header + " " + className}>
         <Breadcrumbs />
      </header>
   );
}

export default Header;
