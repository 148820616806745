import { useEffect, useState } from "react";

import Input from "../../components/Inputs/Input";
import InputRadio from "../../components/Inputs/InputRadio";
import DropDownList from "../../components/Inputs/DropDownList";
import FileLoader from "../../components/Inputs/FileLoader";

import css from "./styles.module.scss";

const {
    application__stepBlock,
    application__formBlock,
    application__formBlock_text
} = css;

const ApplicationStep2 = ({onChange = () => {}, data = {}, errors = {}, hasSend = false}) => {

    const yesNoArray = ["Да", "Нет"];

    const [pasport, setPasport] = useState(data.pasport ? data.pasport : "");
    const [bday, setBday] = useState(data.bday ? data.bday : "");
    
    const [bplace, setBplace] = useState(data.bplace ? data.bplace : "");
    
    const [pasportOrgCode, setpasportOrgCode] = useState(data.pasportOrgCode ? data.pasportOrgCode : "");
    const [pasportDateGet, setpasportDateGet] = useState(data.pasportDateGet ? data.pasportDateGet : "");
    
    const [pasportOrg, setpasportOrg] = useState(data.pasportOrg ? data.pasportOrg : "");

    const [inn, setInn] = useState(data.inn ? data.inn : "");

    const [photo1, setPhoto1] = useState(data.photo1 ? data.photo1 : "");
    const [photo2, setPhoto2] = useState(data.photo2 ? data.photo2 : "");
    const [photo3, setPhoto3] = useState(data.photo3 ? data.photo3 : "");
    const [photo4, setPhoto4] = useState(data.photo4 ? data.photo4 : "");
    
    const [registration, setRegistration] = useState(data.registration ? data.registration : "");
    const [isOnReg, setIsOnReg] = useState(data.isOnReg || data.isOnReg === 0? data.isOnReg : 1);
    const [living, setLiving] = useState(data.living ? data.living : "");
    
    const [familyState, setFamilyState] = useState(data.familyState || data.familyState === 0 ? data.familyState : 0);
    const familyStateArray = [
        "Холост / не замужем",
        "Гражданский брак",
        "Женат / замужем",
        "В разводе"
    ];
    
    const [familyCount, setFamilyCount] = useState(data.familyCount || data.familyCount === 0 ? data.familyCount : 0);
    const familyCountArray = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "Более 5"
    ];
    
    const [childrenCount, setChildrenCount] = useState(data.childrenCount || data.childrenCount === 0 ? data.childrenCount : 0);
    const childrenCountArray = [
        "Нет",
        "1",
        "2",
        "3",
        "4",
        "5",
        "Более 5"
    ];

    const [liveWithParents, setLiveWithParents] = useState(data.liveWithParents || data.liveWithParents === 0 ? data.liveWithParents : 1);
    
    useEffect(() => {
        onChange({
            pasport,
            bday,
            bplace,
            pasportOrgCode,
            pasportDateGet,
            pasportOrg,
            inn,
            photo1,
            photo2,
            photo3,
            photo4,
            registration,
            isOnReg,
            living,
            familyState,
            familyCount,
            childrenCount,
            liveWithParents
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pasport,
        bday,
        bplace,
        pasportOrgCode,
        pasportDateGet,
        pasportOrg,
        inn,
        photo1,
        photo2,
        photo3,
        photo4,
        registration,
        isOnReg,
        living,
        familyState,
        familyCount,
        childrenCount,
        liveWithParents
    ]);

    return (
        <>
            <div className={application__stepBlock}>
                <Input
                    placeholder={"Серия и номер"}
                    className={application__formBlock}
                    mask={"XXXX-XXXXXX"}
                    type={"number"}
                    value={pasport}
                    onChange={setPasport}
                    error={errors.pasport ? errors.pasport : null}
                    showErrorOnBlur={!hasSend}
                />
                <Input
                    placeholder={"Дата рождения"}
                    className={application__formBlock}
                    type={"date"}
                    value={bday}
                    onChange={setBday}
                    error={errors.bday ? errors.bday : null}
                    showErrorOnBlur={!hasSend}
                />
            </div>
            <div className={application__stepBlock}>
                <Input
                    placeholder={"Место рождения"}
                    className={application__formBlock}
                    value={bplace}
                    onChange={setBplace}
                    error={errors.bplace ? errors.bplace : null}
                    showErrorOnBlur={!hasSend}
                />
            </div>
            <div className={application__stepBlock}>
                <Input
                    placeholder={"Код подразделения"}
                    className={application__formBlock}
                    mask={"XXX-XXX"}
                    type={"number"}
                    value={pasportOrgCode}
                    onChange={setpasportOrgCode}
                    error={errors.pasportOrgCode ? errors.pasportOrgCode : null}
                    showErrorOnBlur={!hasSend}
                />
                <Input
                    placeholder={"Дата выдачи"}
                    className={application__formBlock}
                    type={"date"}
                    value={pasportDateGet}
                    onChange={setpasportDateGet}
                    error={errors.pasportDateGet ? errors.pasportDateGet : null}
                    showErrorOnBlur={!hasSend}
                />
            </div>
            <div className={application__stepBlock}>
                <Input
                    placeholder={"Кем выдан"}
                    className={application__formBlock}
                    value={pasportOrg}
                    onChange={setpasportOrg}
                    error={errors.pasportOrg ? errors.pasportOrg : null}
                    showErrorOnBlur={!hasSend}
                />
            </div>
            <div className={application__stepBlock}>
                <Input
                    placeholder={"ИНН"}
                    className={application__formBlock}
                    mask={"XXXXXXXXXXXX"}
                    type={"number"}
                    value={inn}
                    onChange={setInn}
                />
                <p className={application__formBlock + " " + application__formBlock_text}>
                    Заполненное поле ИНН повышает шансы получения займа
                </p>
            </div>
            <div className={application__stepBlock}>
                <FileLoader
                    extensions=".jpg,.jpeg,.png"
                    value={photo1}
                    onChange={setPhoto1}
                >
                    Фото основного<br />разворота паспорта
                </FileLoader>
                <FileLoader
                    extensions=".jpg,.jpeg,.png"
                    value={photo2}
                    onChange={setPhoto2}
                >
                    Фото страницы регистрации
                </FileLoader>
                <FileLoader
                    extensions=".jpg,.jpeg,.png"
                    value={photo3}
                    onChange={setPhoto3}
                >
                    Селфи с основным разворотом паспорта
                </FileLoader>
                <FileLoader
                    extensions=".jpg,.jpeg,.png"
                    value={photo4}
                    onChange={setPhoto4}
                >
                    Фото<br />СНИЛСа
                </FileLoader>
            </div>
            <div className={application__stepBlock}>
                <Input
                    placeholder={"Адрес регистрации"}
                    className={application__formBlock}
                    value={registration}
                    onChange={setRegistration}
                    error={errors.registration ? errors.registration : null}
                    showErrorOnBlur={!hasSend}
                />
            </div>
            <div className={application__stepBlock}>
                <InputRadio
                    className={""}
                    label="Адрес проживания совпадает с адресом регистрации"
                    options={yesNoArray}
                    onChange={setIsOnReg}
                    name={"isOnReg"}
                    value={isOnReg}
                />
            </div>
            {
                isOnReg ? (
                    <div className={application__stepBlock}>
                        <Input
                            placeholder={"Адрес проживания"}
                            className={application__formBlock}
                            value={living}
                            onChange={setLiving}
                            error={errors.living ? errors.living : null}
                            showErrorOnBlur={!hasSend}
                        />
                    </div>
                ) : ""
            }
            <div className={application__stepBlock}>
                <DropDownList
                    options={familyStateArray}
                    label="Семейное положение"
                    value={familyState}
                    onChange={setFamilyState}
                />
                <DropDownList
                    options={familyCountArray}
                    label="Количество членов семьи"
                    value={familyCount}
                    onChange={setFamilyCount}
                />
            </div>
            <div className={application__stepBlock}>
                <DropDownList
                    options={childrenCountArray}
                    label="Количество детей до 18 лет"
                    value={childrenCount}
                    onChange={setChildrenCount}
                />
                <InputRadio
                    className={""}
                    label="Проживаю с родителями"
                    options={yesNoArray}
                    onChange={setLiveWithParents}
                    name={"liveWithPArents"}
                    value={liveWithParents}
                />
            </div>
        </>
    );
}

export default ApplicationStep2;