import CheckBox from "../../Inputs/Checkbox";
import Icon, { ColorIconEnam, IconEnum } from "../../StateLess/Icon";

import scss from "./index.module.scss";

const {
   document,
   document__heading,
   document__icon,
   document__name,
   document__link,
   document__check,
   document__checkTitle,
} = scss;

function DocumentLinkDownload({ item, flag }) {
   return (
      <div className={document}>
         <div className={document__heading}>
            <Icon
               className={document__icon}
               name={IconEnum.document}
               color={ColorIconEnam.base}
            />
            <p className={document__name}>{item?.name}</p>
         </div>
         {!flag || (
            <div className={document__check}>
               <span className={document__checkTitle}>Я согласен(а)</span>
               <CheckBox value={true} />
            </div>
         )}

         <a
            className={document__link}
            href={item?.url}
            target={"_blank"}
            rel="noreferrer"
            title={`Открыть документ: ${item?.name}`}
         > </a>
      </div>
   );
}

export default DocumentLinkDownload;
