function IconDoubleChevronRight(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M10.58 5.248a.9.9 0 011.272.031l6.001 6.3a.9.9 0 010 1.242l-6 6.3a.902.902 0 01-1.273.032.9.9 0 01-.031-1.273l5.41-5.68-5.41-5.68a.9.9 0 01.03-1.272zm-4.8 0a.9.9 0 011.272.031l6.001 6.3a.9.9 0 010 1.242l-6 6.3a.902.902 0 01-1.475-.255.9.9 0 01.17-.986l5.411-5.68-5.412-5.681a.9.9 0 01.033-1.272v.001z"
         ></path>
      </svg>
   );
}

export default IconDoubleChevronRight;
