import { useEffect, useState } from "react";
import CheckBox from "../../../../components/Inputs/Checkbox";
import Input from "../../../../components/Inputs/Input";

import classes from './styles.module.scss';
import checkAuth from "../../../../helpers/checkAuth";
import api from "../../../../helpers/fetch";

const {
    servicesTab__head,
    servicesTab__list,
    servicesTab__service,
    servicesTab__serviceHead,
    servicesTab__serviceName,
    servicesTab__serviceStatus
} = classes;

const CustomerServicesTab = ({
    data = {}
}) => {

    const [partnerServices, setPartnerServices] = useState([]);
    const [services, setServices] = useState([]);

    useEffect(() => {
        if (data.services) {
            const tmpPartnerServices = [];
            const tmpServices = [];

            data.services.forEach(service => {
                if (service.link) {
                    tmpPartnerServices.push(service);
                }
                else {
                    tmpServices.push(service);
                }
            });

            setPartnerServices(tmpPartnerServices);
            setServices(tmpServices);
        }
    }, []);

    const updateData = async (id_service, status, date_off = null) => {

        if (!data.id_user || !id_service || typeof status !== 'boolean') {
            return false;
        }

        const auth = checkAuth();
        const body = JSON.stringify({
            id_client: data.id_user,
            id_service: id_service,
            status: status,
            date_off: status && date_off > 0 ? date_off : (
                status && date_off === 0 ? parseInt((new Date().getTime() + (30 * 24 * 60 * 60 * 1000)) / 1000) : date_off
            )
        });

        const response = await new api(
           `manage/services`,
           auth,
           {
              method: "PUT",
              body,
           }
        ).send();
        if (response.status === "success") {
            const tmpPartnerServices = JSON.parse(JSON.stringify(partnerServices));
            let psc = false;

            tmpPartnerServices.forEach((service, i) => {
                if (service.id === id_service) {
                    tmpPartnerServices[i].able = response.data.status;

                    psc = true;
                }
            });

            if (psc) {
                setPartnerServices(tmpPartnerServices);
            }
            
            const tmpServices = JSON.parse(JSON.stringify(services));
            let sc = false;

            tmpServices.forEach((service, i) => {
                if (service.id === id_service) {
                    tmpServices[i].able = response.data.status;
                    tmpServices[i].date_off = response.data.date_off;

                    sc = true;
                }
            });

            if (sc) {
                setServices(tmpServices);
            }
        }
    }

    const onDateChange = (id_service, status, value) => {
        const tmpDate = new Date(value);

        if (tmpDate.toLocaleDateString() === 'Invalid Date' || value < (new Date().getTime())) return false;

        updateData(id_service, status, tmpDate.getTime() / 1000);
    }

    return (
        <>
            <h2 className={servicesTab__head}>Услуги от партнёров</h2>

            <div className={servicesTab__list}>
                {
                    partnerServices.map((service, i) => (
                        <div className={servicesTab__service} key={i}>
                            <div className={servicesTab__serviceHead}>
                                <h3 className={servicesTab__serviceName}>{service.name}</h3>

                                <label className={servicesTab__serviceStatus}>
                                    <CheckBox
                                        value={service.able}
                                        onChange={value => updateData(service.id, value)}
                                    />

                                    Услуга {service.able ? 'активна' : 'не активна'}
                                </label>
                            </div>
                        </div>
                    ))
                }
            </div>

            <h2 className={servicesTab__head}>Услуги</h2>

            <div className={servicesTab__list}>
                {
                    services.map((service, i) => (
                        <div className={servicesTab__service} key={i}>
                            <div className={servicesTab__serviceHead}>
                                <h3 className={servicesTab__serviceName}>{service.name}</h3>

                                <label className={servicesTab__serviceStatus}>
                                    <CheckBox
                                        value={service.able}
                                        onChange={value => updateData(service.id, value, service.date_off ? service.date_off : 0)}
                                    />

                                    Услуга {service.able ? 'активна' : 'не активна'}
                                </label>
                            </div>

                            <Input
                                type="date"
                                value={service.date_off ? service.date_off * 1000 : ''}
                                placeholder={'Активна до'}
                                onChange={value => onDateChange(service.id, service.able, value)}
                            />
                        </div>
                    ))
                }
            </div>
        </>
    );
}

export default CustomerServicesTab;