function IconArchive(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M2.4 5.1a1.5 1.5 0 011.5-1.5h16.2a1.5 1.5 0 011.5 1.5v1.8a1.5 1.5 0 01-1.5 1.5H3.9a1.5 1.5 0 01-1.5-1.5V5.1zm1.2 4.5h16.8v7.2a3.6 3.6 0 01-3.6 3.6H7.2a3.6 3.6 0 01-3.6-3.6V9.6zm6.6 2.4a.6.6 0 000 1.2h3.6a.6.6 0 100-1.2h-3.6z"
         ></path>
      </svg>
   );
}

export default IconArchive;
