import Button from "../../Inputs/Button";

import classes from "../styles.module.scss";

const {
    modalContent__text,
    modalContent__button
} = classes;

const TextModal = ({
    onClose = () => {},
    text = "",
    buttonVariant = null,
    buttonText = "Хорошо",
    onClick = () => {}
}) => {

    const onButtonClick = () => {
        onClick();
        onClose();
    }

    return (
        <>
            <div className={modalContent__text} dangerouslySetInnerHTML={{__html: text}} />
            
            <Button className={modalContent__button} onClick={onButtonClick} variant={buttonVariant}>
                {buttonText}
            </Button>
        </>
    );
}

export default TextModal;