import Icon, { IconEnum } from "../../StateLess/Icon";

import scss from "./index.module.scss";

const { alert, alert__icon, alert__container, alert__title, alert__text } = scss;

function Alert({ title, text, color }) {
   return (
      <div className={alert}>
         <Icon className={alert__icon} name={IconEnum.siren} color={color}></Icon>
         <div className={alert__container}>
            <h2 className={alert__title}>{title}</h2>
            <p className={alert__text}>{text}</p>
         </div>
      </div>
   );
}

export default Alert;
