import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import modalsReducers from "./reducers/modals";

const store = configureStore({
    reducer: {
        modals: modalsReducers
    },
    middleware: [
        ...getDefaultMiddleware({
            serializableCheck: false
        }),
    ],
});

export default store;