function IconSearch(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
         <path
            fill="#010101"
            d="M9.6 3a6.6 6.6 0 015.26 10.59l4.98 4.97a.9.9 0 01-1.18 1.36l-.1-.08-4.97-4.98A6.6 6.6 0 119.59 3zm0 1.8a4.8 4.8 0 100 9.6 4.8 4.8 0 000-9.6z"
         ></path>
      </svg>
   );
}

export default IconSearch;
