function formatCard (num) {
    if (num) {
        if (num >= 0) {
            num = num.toString();
            const tmp = "XXXX XXXX XXXX XXXX XXXX XXXX";
            let formatedPhone = "";
            let i = 0;

            let hasEnded = false;
    
            tmp.split("").forEach(char => {
                if (!hasEnded) {
                    if (char === "X") {
                        if (num[i] !== undefined) {
                            formatedPhone += num[i];
                            i++;
                        }
                        else {
                            hasEnded = true;
                        }
                    }
                    else {
                        formatedPhone += char;
                    }
                }
            });
    
            return formatedPhone;
        }
    }

    return "";
}

export default formatCard;