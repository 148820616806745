import DocumentLinkDownload from "../DocumentLinkDownload";

import scss from "./index.module.scss";

const { documents__list, documents__listItem } = scss;

function DocumentsList({ documents, flag = true }) {
   return (
      <ul className={documents__list}>
         {documents?.map((document, key) => (
            <li className={documents__listItem} key={key}>
               <DocumentLinkDownload item={document} flag={flag} />
            </li>
         ))}
      </ul>
   );
}

export default DocumentsList;
