import formatPhone from "../../../helpers/formatPhone";

import Button from "../../Inputs/Button";

import classes from "../styles.module.scss";

const {
    modalContent__supports,
    modalContent__button
} = classes;

const Supports = ({
    onClose = () => {},
    initialState = {}
}) => {

    const telephone = parseInt(initialState.settings?.about?.phone);
    const email = initialState.settings?.about?.email;
    const wa = parseInt(initialState.settings?.about?.whatsapp);

    return (
        <>
            <div className={modalContent__supports}>
                {
                    telephone ? (
                        <Button variant="gray" className={""} href={"tel:+7" + telephone} onClick={onClose}>
                            {formatPhone(telephone)}
                        </Button>
                    ) : ""
                }
                {
                    wa ? (
                        <Button variant="gray" className={""} href={"https://wa.me/" + wa} target="_blank" onClick={onClose}>
                            WhatsApp
                        </Button>
                    ) : ""
                }
                {
                    email ? (
                        <Button variant="gray" className={""} href={"mailto:" + email} onClick={onClose}>
                            {email}
                        </Button>
                    ) : ""
                }
            </div>

            <Button className={modalContent__button} onClick={onClose}>
                Закрыть
            </Button>
        </>
    );
}

export default Supports;