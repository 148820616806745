import Breadcrumbs from "../Breadcrumbs";
import scss from "./index.module.scss";

const {
   heading_content_page,
   heading_content_page__wrapper,
   heading_content_page__title,
   heading_content_page__text,
   heading_content_page__title_hide,
   heading_content_page__text_hide,
} = scss;

function HeadingContentPage({ pageData }) {
   return (
      <div className={heading_content_page}>
         <Breadcrumbs pageData={pageData} />
         <div className={heading_content_page__wrapper}>
            <h1
               className={
                  pageData?.title
                     ? heading_content_page__title
                     : heading_content_page__title_hide
               }
            >
               {pageData?.title}
            </h1>
            <p
               className={
                  pageData?.meta?.description
                     ? heading_content_page__text
                     : heading_content_page__text_hide
               }
            >
               {pageData?.meta?.description}
            </p>
         </div>
      </div>
   );
}

export default HeadingContentPage;
