import scss from "../index.module.scss";

const { section, text } = scss;

function Text({ data }) {
   return (
      <section className={section}>
         <div className={text} dangerouslySetInnerHTML={{ __html: data.html }} />
      </section>
   );
}

export default Text;
