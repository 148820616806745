function IconGlance(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M12 19a3 3 0 003 3h4a3 3 0 003-3v-6a3 3 0 00-3-3h-4a3 3 0 00-3 3v6zM2 11a3 3 0 003 3h2a3 3 0 003-3V5a3 3 0 00-3-3H5a3 3 0 00-3 3v6zm13-3a3 3 0 010-6h4a3 3 0 010 6h-4zM2 19a3 3 0 003 3h2a3 3 0 000-6H5a3 3 0 00-3 3z"
         ></path>
      </svg>
   );
}

export default IconGlance;
