import { Link } from "react-router-dom";
import scss from "./index.module.scss";

const {
   breadcrumbs,
   breadcrumbs__item,
   breadcrumbs__link,
   breadcrumbs__text,
   breadcrumbs__text_hide,
} = scss;

function Breadcrumbs({ pageData }) {
   return (
      <ol className={breadcrumbs} itemScope itemType="https://schema.org/BreadcrumbList">
         <li
            className={breadcrumbs__item}
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
         >
            <Link className={breadcrumbs__link} itemProp="item" to="/">
               <span className={breadcrumbs__text} itemProp="name">
                  Главная
               </span>
            </Link>
            <meta itemProp="position" content="1" />
         </li>
         <li
            className={breadcrumbs__item}
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
         >
            <Link
               className={breadcrumbs__link}
               itemProp="item"
               to={"/" + pageData.pathname}
            >
               <span
                  className={pageData.title ? breadcrumbs__text : breadcrumbs__text_hide}
                  itemProp="name"
               >
                  {pageData.title}
               </span>
            </Link>
            <meta itemProp="position" content="2" />
         </li>
      </ol>
   );
}

export default Breadcrumbs;
