function IconDelete(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M10.2 4.8h3.6a1.8 1.8 0 10-3.6 0zM9 4.8a3 3 0 016 0h6A.6.6 0 1121 6h-1.265l-1.433 12.413a3.6 3.6 0 01-3.576 3.187H9.274a3.6 3.6 0 01-3.576-3.187L4.265 6H3a.6.6 0 010-1.2h6zm1.8 4.8a.6.6 0 10-1.2 0v7.2a.6.6 0 101.2 0V9.6zm3-.6a.6.6 0 00-.6.6v7.2a.6.6 0 101.2 0V9.6a.6.6 0 00-.6-.6z"
         ></path>
      </svg>
   );
}

export default IconDelete;
