import Button from "../../Inputs/Button";

import classes from "../styles.module.scss";

const {
    modalContent__text,
    modalContent__buttonRow,
    modalContent__button
} = classes;

const DeleteCard = ({
    onClose = () => {},
    onClick = () => {},
    title = "",
    number = ""
}) => {

    const onSubmit = () => {
        onClick();
        onClose();
    }

    return (
        <>
            <p className={modalContent__text}>
                Вы действительно хотите удалить карту {title} (••••  {number})
            </p>

            <div className={modalContent__buttonRow + " " + modalContent__button}>
                <Button variant="gray" onClick={onClose}>
                    Нет
                </Button>

                <Button variant="primary" onClick={onSubmit}>
                    Да
                </Button>
            </div>
        </>
    );
}

export default DeleteCard;