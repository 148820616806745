import { useState } from "react";

import Button from "../../Inputs/Button";

import classes from "../styles.module.scss";

import api from "../../../helpers/fetch";
import checkAuth from "../../../helpers/checkAuth";

const {
    modalContent__text,
    modalContent__qrcode,
    modalContent__button
} = classes;

const PaymentQR = ({
    qr = "",
    amount = 0,
    number="",
    token = "",
    onClick = () => {},
    onClose = () => {}
}) => {

    const [isWait, setWait] = useState(false);

    const auth = checkAuth();
    
    const onPay = async () => {

        const body = new FormData();

        if (token) {
            body.append("token", token);
        }

        body.append("number", number);
        body.append("amount", amount);

        setWait(true);
        const response = await (new api(`payments`, token ? false : auth, {
            method: "POST",
            body
        })).send();
        setWait(false);

        onClose(null, () => onClick(response.status === "success"));
    }

    return (
        <>
            <p className={modalContent__text}>
                Отсканируйте код<br />
                в приложении вашего банка.
            </p>

            <img src={qr} alt="QR код для оплаты" className={modalContent__qrcode} width="180" height="180" />

            <Button className={modalContent__button} onClick={onPay} isWaiting={isWait} disabled={token === "" && !auth}>
                Я перевёл {amount > 0 ? " " + (parseFloat(amount)).toLocaleString("ru-RU") + " ₽" : ""}
            </Button>
        </>
    )
}

export default PaymentQR;