import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "../../../components/Inputs/Button";
import DropDownList from "../../../components/Inputs/DropDownList";
import EmptyData from "../../../components/StateLess/EmptyData";
import IconCopy from "../../../components/Icons/Copy";
import Input from "../../../components/Inputs/Input";
import Table from "../../../components/StateFull/Table";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import formatPhone from "../../../helpers/formatPhone";
import getFIO from "../../../helpers/getFIO";
import Accordion from "../../Components/Accordion";
import AccordionItem from "../../Components/AccordionItem";
import Card from "../../Components/Card";
import IconButton from "../../Components/IconButton";
import IconEdit from "../../Components/Icons/IconEdit";
import IconShieldCheckmark from "../../Components/Icons/IconShieldCheckmark";
import PopUp from "../../Components/PopUp";

import scss from "./index.module.scss";

const {
   container,
   avatar,
   cards,
   card,
   card__title,
   card__link,
   card__link_status,
   card__link_tel,
   card__link_email,
   card__wrapper,
   card__actions,
   link,
   link__header,
   link__block,
   link__title,
   link__subtitle,
   wrapper__accept,
   attantion_small,
   buttons,
} = scss;

function Employee() {
   const params = useParams();
   const [state, setState] = useState({
      id_user: 0,
      name: "",
      surname: "",
      midname: "",
      status: "",
      role: "",
      phone_number: 0,
      email: "",
      accepted: 0,
      denied: 0,
   });

   const statusesTransaction = {
      confirmed: "Одобрена",
      processing: "Обрабатывается",
      denied: "Отклонёна",
   };

   
   // const statusesLoans = {
   //    confirmed: "Одобрен",
   //    signed: "Подписан",
   //    completed: "Выплачен",
   //    closed: "Закрыт",
   //    denied: "Отклонён",
   //    unpayed: "Нужна выплата",
   // };

   const [wait, setWait] = useState(false);

   const [name, setName] = useState("");
   const [surname, setSurname] = useState("");
   const [midname, setMidname] = useState("");
   const [phone_number, setPhone_number] = useState("");
   const [email, setEmail] = useState("");
   const [status, setStatus] = useState("");
   const [role, setRole] = useState(1);
   const roleArray = ["Менеджер", "Редактор", "Администратор"];
   const statusArray = ["Уволен", "Работает в организации"];
   const [sort, setSort] = useState([]);
   const [sortLoans, setSortLoans] = useState([]);
   const [nameError, setNameError] = useState("");
   const [surnameError, setSurnameError] = useState("");
   const [phone_numberError, setPhone_numberError] = useState("");
   const [emailError, setEmailError] = useState("");

   const [isActive, setIsActive] = useState(false);

   const [transStory, setTransStory] = useState([]);

   const [loanStory, setLoanStory] = useState([]);

   const [pagination, setPagination] = useState({});

   const [paginationPage, setPaginationPage] = useState(1);

   const [pagination2, setPagination2] = useState({});

   const [paginationPage2, setPaginationPage2] = useState(1);

   const toTable = (rows = []) => {
      const tmpArray = [];
      rows.forEach((el, key) => {
         tmpArray.push([
            {
               type: "text",
               value: getFIO(el.name, el.surname, el.midname, "ser-name-mid"),
            },
            {
               type: "text",
               value: el.id_loan_transaction,
            },
            {
               type: "date",
               value: el.date_created,
            },
            {
               type: "money",
               value: el.amount,
            },
            {
               type: el.status === "confirmed" ? "success" : "error",
               value: statusesTransaction[el.status],
            },
         ]);
      });
      return tmpArray;
   };

   const toTableLoans = (rows = []) => {
      const tmpArray = [];
      rows.forEach((el, key) => {
         tmpArray.push([
            {
               type: "text",
               value: getFIO(el.name, el.surname, el.midname, "ser-name-mid"),
            },
            {
               type: el.date_created ? "date" : "text",
               value: el.date_created ? el.date_created : "—",
            },
            {
               type: "text",
               value: el.duration ? el.duration + " дней" : "—",
            },
            {
               type: el.total_amount ? "money" : "text",
               value: el.total_amount ? el.total_amount : "—",
            },
            {
               type: "text",
               value: el.rate ? el.rate.toLocaleString("ru-RU") + " %" : "—",
            },
            {
               type: el.status === "denied" ? "error" : "success",
               value: el.status === "denied" ? "Отклонён" : "Одобрен",
            },
            {
               type: "component",
               value:
                  el.status === "confirmed" ||
                  el.status === "signed" ||
                  el.status === "unpayed" ? (
                     <Link to={"/manage/loans/" + el.id_loan}>Подробнее</Link>
                  ) : (
                     <Link to={"/manage/archive/" + el.id_loan}>Подробнее</Link>
                  ),
            },
         ]);
      });
      return tmpArray;
   };

   const getData = async () => {
      const auth = checkAuth();
      const response = await new api(`manage/users/${params.id}`, auth).send();
      if (response.status === "success") {
         setState(response?.data);
         setName(response?.data.name);
         setSurname(response?.data.surname);
         setMidname(response?.data.midname);
         setPhone_number(response?.data.phone_number.toString());
         setEmail(response?.data.email);
         setStatus(response?.data.status);
         setRole(response?.data.role - 1);
      }
   };

   const getTrans = async () => {
      const auth = checkAuth();
      let queryParams = getSortQuery("surname");
      const response = await new api(
         `manage/transactions?id_employer=${params.id}${queryParams}` +
            "&page=" +
            (paginationPage !== 0 && paginationPage ? paginationPage : 1),
         auth
      ).send();
      if (response.status === "success") {
         setTransStory(toTable(response.data.data));
         setPagination(response.data.pagination);
      }
   };

   const getLoans = async () => {
      const auth = checkAuth();
      let queryParams = getSortQuery("surname");
      const response = await new api(
         `manage/loans?total&id_employer=${params?.id}${queryParams ? queryParams : ""}` +
            "&page=" +
            (paginationPage2 !== 0 && paginationPage2 ? paginationPage2 : 1),
         auth
      ).send();
      if (response.status === "success") {
         setLoanStory(toTableLoans(response.data.data));
         setPagination2(response.data.pagination);
      }
   };

   const getSortQuery = (nameField) => {
      if (sort.length === 0) {
         setSort([nameField, "asc"]);
      } else {
         return "&sort_param=" + sort[0] + "&sort_direction=" + sort[1];
      }
      return "";
   };

   useEffect(() => {
      getData();
      getTrans();
      getLoans();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      getTrans();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [sort, paginationPage]);

   const onPaginate = (number) => {
      setPaginationPage(number);
   };

   useEffect(() => {
      getLoans();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [sortLoans, paginationPage2]);

   const onPaginate2 = (number) => {
      setPaginationPage2(number);
   };

   const onSubmit = async (e) => {
      e.preventDefault();
      setWait(true);
      setNameError("");
      setSurnameError("");
      setPhone_numberError("");
      setEmailError("");
      if (!name) {
         setNameError("Поле должно быть заполнено!");
      }
      if (!surname) {
         setSurnameError("Поле должно быть заполнено!");
      }
      if (!phone_number) {
         setPhone_numberError("Поле должно быть заполнено!");
      }
      if (!email) {
         setEmailError("Поле должно быть заполнено!");
      }
      if (name && surname && phone_number && email) {
         const auth = checkAuth();
         const body = JSON.stringify({
            status: status,
            role: role + 1,
            name: name,
            surname: surname,
            midname: midname,
            phone_number: phone_number,
            email: email,
         });
         const response = await new api(`manage/users/${state.id_user}/put`, auth, {
            method: "PUT",
            body,
         }).send();
         if (response.status === "success") {
            onCloas();
            getData();
            setWait(false);
         } else {
            setPhone_numberError("Такой пользователь уже существует!");
            setEmailError("Такой пользователь уже существует!");
            setWait(false);
         }
      }
      setWait(false);
   };

   const onCloas = () => {
      setIsActive(!isActive);
      document.body.style.overflowY = null;
      getData();
   };

   const onResetPassword = async (e) => {
      e.preventDefault();
      const auth = checkAuth();
      const response = await new api(
         `manage/users/${state.id_user}/reset_password`,
         auth,
         {
            method: "POST",
         }
      ).send();
      if (response.status === "success") {
         onCloas();
         getData();
      }
   };

   return (
      <section className={container}>
         <div className={cards}>
            <Card type={state?.id_user ? "wrapper" : ""}>
               <div className={card}>
                  <img
                     src="/assets/base_avatar.jpg"
                     className={avatar}
                     alt="Аватар пользователя"
                     width="64"
                     height="64"
                  />
                  <h2 className={card__title}>
                     {getFIO(state?.name, state?.surname, state?.midname, "ser-name-mid")}
                  </h2>
                  <div className={card__wrapper}>
                     <span className={card__link + " " + card__link_status}>
                        {statusArray[state?.status] + " | " + roleArray[state?.role - 1]}
                     </span>
                     <span className={card__link + " " + card__link_tel}>
                        <a href={"tel:+" + state?.phone_number}>
                           {formatPhone(state?.phone_number)}
                        </a>
                        <IconCopy
                           onClick={() =>
                              navigator.clipboard.writeText(
                                 formatPhone(state?.phone_number)
                              )
                           }
                        />
                     </span>
                     <span className={card__link + " " + card__link_email}>
                        <a href={"mailto:" + state?.email}>{state?.email}</a>
                        <IconCopy
                           onClick={() => navigator.clipboard.writeText(state?.email)}
                        />
                     </span>
                  </div>
                  <div className={card__actions}>
                     <IconButton type={"button"} onClick={() => setIsActive(!isActive)}>
                        <IconEdit />
                     </IconButton>
                     {status === 0 ? null : (
                        <IconButton type={"button"} onClick={onResetPassword}>
                           <IconShieldCheckmark />
                        </IconButton>
                     )}
                  </div>
               </div>
            </Card>
            <Card type={state?.id_user ? "wrapper" : ""} className={link}>
               <span className={link__header}>Статистика сотрудника</span>
               <div className={link__block}>
                  <span className={link__title}>Одобрено</span>
                  <span className={link__subtitle}>
                     {state?.accepted.toLocaleString("ru-RU")} %
                  </span>
               </div>
               <div className={link__block}>
                  <span className={link__title}>Отклонено</span>
                  <span className={link__subtitle}>
                     {state?.denied.toLocaleString("ru-RU")} %
                  </span>
               </div>
            </Card>
         </div>
         <Accordion>
            <AccordionItem title={"Решения по транзакциям"}>
               <Table
                  rows={transStory}
                  colums={[
                     {
                        paramName: "surname",
                        name: "Клиент",
                     },
                     {
                        paramName: "id_loan_transaction",
                        name: "Номер транзакции",
                     },
                     {
                        paramName: "date_created",
                        name: "Дата",
                     },
                     {
                        paramName: "amount",
                        name: "Сумма",
                     },
                     {
                        paramName: "status",
                        name: "Статус",
                     },
                  ]}
                  onSort={(param, direction) => setSort([param, direction])}
                  sortParam={sort[0]}
                  sortDirection={sort[1]}
                  baseColumnsWidth={[null, 260, 200, 200]}
                  blockOnEmpty={
                     <EmptyData
                        title="Нет данных"
                        text="Данные отсутвуют. Вероятно, что сотрудник еще не сдела ни одного решения по транзакциям."
                     />
                  }
                  pagination={pagination}
                  onPaginate={onPaginate}
               />
            </AccordionItem>
            <AccordionItem title={"Решения по займам"}>
               <Table
                  rows={loanStory}
                  colums={[
                     {
                        paramName: "surname",
                        name: "Клиент",
                     },
                     {
                        paramName: "date_created",
                        name: "Дата подачи",
                     },

                     {
                        paramName: "duration",
                        name: "Срок",
                     },
                     {
                        paramName: "total_amount",
                        name: "Сумма",
                     },
                     {
                        paramName: "rate",
                        name: "Процент",
                     },
                     {
                        paramName: "status",
                        name: "Статус",
                     },
                     {
                        paramName: "actions",
                        name: "Действия",
                     },
                  ]}
                  onSort={(param, direction) => setSortLoans([param, direction])}
                  sortParam={sortLoans[0]}
                  sortDirection={sortLoans[1]}
                  baseColumnsWidth={[null, 200, 200, 200, 160, 160, 160, 100]}
                  blockOnEmpty={
                     <EmptyData
                        title="Нет данных"
                        text="Не удалось ничего найти по вашему запросу."
                     />
                  }
                  pagination={pagination2}
                  onPaginate={onPaginate2}
               />
            </AccordionItem>
         </Accordion>
         {isActive ? (
            <PopUp title={"Новый сотрудник"} onCloase={() => onCloas()}>
               <form action="" onSubmit={onSubmit} className={wrapper__accept}>
                  {state.role !== 3 ? (
                     <>
                        <DropDownList
                           label={"Должность"}
                           options={roleArray}
                           value={role}
                           onChange={setRole}
                        />
                        <DropDownList
                           label={"Статус"}
                           options={statusArray}
                           value={status}
                           onChange={setStatus}
                        />
                     </>
                  ) : null}
                  <Input
                     placeholder={"Имя*"}
                     regex={/^[а-яА-ЯёЁ -]{0,}$/u}
                     type={"text"}
                     value={name}
                     onChange={setName}
                     error={nameError}
                  />
                  <Input
                     placeholder={"Фамилия*"}
                     regex={/^[а-яА-ЯёЁ -]{0,}$/u}
                     type={"text"}
                     value={surname}
                     onChange={setSurname}
                     error={surnameError}
                  />
                  <Input
                     placeholder={"Отчество"}
                     regex={/^[а-яА-ЯёЁ -]{0,}$/u}
                     type={"text"}
                     value={midname}
                     onChange={setMidname}
                  />
                  <Input
                     placeholder={"Телефон*"}
                     mask={"+7 (XXX) XXX-XX-XX"}
                     name="tel"
                     regex={/[0-9]/}
                     type={"text"}
                     value={phone_number}
                     onChange={setPhone_number}
                     error={phone_numberError}
                  />
                  <Input
                     placeholder={"Электронная почта*"}
                     type={"text"}
                     value={email}
                     onChange={setEmail}
                     error={emailError}
                  />
                  <p className={attantion_small}>
                     Внимательно проверяйте данные сотрудника перед сохранением.
                  </p>
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onCloas()}>
                        Отмена
                     </Button>
                     <Button isWaiting={wait} type={"submit"}>
                        Сохранить
                     </Button>
                  </div>
               </form>
            </PopUp>
         ) : null}
      </section>
   );
}

export default Employee;
