import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AsideListItem from "../AsideListItem";
import scss from "./index.module.scss";

const { list, list__item_active, list__element, list__item_disabled } = scss;

function AsideList({ data }) {
   const location = useLocation();

   const getPage = () => {
      const loc = location.pathname.split("/");
      return loc[loc.length - 1];
   };
   return (
      <ul className={list}>
         {data.map(({ title, link, disabled }, key) => (
            <div key={key} className={list__element}>
               {disabled ? (
                  <AsideListItem className={list__item_disabled} title={title} link={link} disabeld />
               ) : (
                  <AsideListItem
                     className={link.includes(getPage()) ? list__item_active : null}
                     title={title}
                     link={link}
                  />
               )}
            </div>
         ))}
      </ul>
   );
}

export default AsideList;
