import Button from "../../Inputs/Button";
import Icon from "../../StateLess/Icon";

import scss from "./index.module.scss";

const { actionButton, actionButton__text, actionButton__icon } = scss;

function ActionButton({
   className,
   iconName,
   iconColor,
   action,
   href,
   value,
   flip = false,
}) {
   const attributes = {};

   if (href) attributes.href = href;
   if (action) attributes.onClick = action;

   return (
      <Button
         variant="transparent"
         className={actionButton + " " + className}
         {...attributes}
      >
         {!flip || (
            <Icon className={actionButton__icon} name={iconName} color={iconColor}></Icon>
         )}
         <span className={actionButton__text}>{value}</span>
         {flip || (
            <Icon className={actionButton__icon} name={iconName} color={iconColor}></Icon>
         )}
      </Button>
   );
}

export default ActionButton;
