import { useDispatch } from "react-redux";

import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import logOut from "../../../helpers/logout";

import Button from "../../Inputs/Button";
import CopiedField from '../../StateLess/CopiedField';
import Icon, { ColorIconEnam, IconEnum } from "../../StateLess/Icon";

import classes from '../styles.module.scss';

import { addModal } from "../../../store/reducers/modals";

import DeleteCard from "../DeleteCard";
import TextModal from "../TextModal";
import RenameCard from "../RenameCard";

const {
    modalContent__button,
    modalContent__buttonRow,
    modalContent__copiedFields
} = classes;


const BankCard = ({
    onClose = () => {},
    title = "",
    number = "",
    expire  = "",
    id = 0,
    onTitleChange = () => {},
    onDelete = () => {},
    onRename = () => {}
}) => {

    const dispatch = useDispatch();

    const deleteCard = () => {
        dispatch(addModal({
            title: "",
            icon: <Icon name={IconEnum.question} color={ColorIconEnam.prim} />,
            content: props => <DeleteCard {...props}
                number={number}
                title={title}
                onClick={async () => {
                    const auth = checkAuth();
            
                    if (!auth) logOut();
            
                    const body = JSON.stringify({
                        id
                    });
            
                    const resp = await (new api("card", auth, {
                        method: "DELETE",
                        body
                    })).send();
            
                    if (resp.status === 'success') {
                        onClose(null, () => {
                            onDelete();

                            dispatch(addModal({
                                title: "Готово",
                                icon: <Icon name={IconEnum.ok} color={ColorIconEnam.base} />,
                                content: props => <TextModal {...props}
                                    text="Мы удалили карту из&nbsp;Вашего профиля."
                                />
                            }));
                        });
                    }
                    else {
                        onClose(null, () => {
                            dispatch(addModal({
                                title: "Что-то пошло не так...",
                                icon: <Icon name={IconEnum.expressionless} color={ColorIconEnam.prim} />,
                                content: props => <TextModal {...props}
                                    text="Не удалось удалить Вашу карту из&nbsp;профиля. Повторите попытку позже или&nbsp;обратитесь в&nbsp;службу&nbsp;поддержки."
                                />
                            }));
                        });
                    }
                }}
            />
        }))
    }

    // const renameCard = () => {
    //     dispatch(addModal({
    //         title: "Новое название",
    //         icon: <Icon name={IconEnum.card} color={ColorIconEnam.base} />,
    //         content: props => <RenameCard {...props}
    //             onClick={async (newTitle) => {
    //                 const auth = checkAuth();

    //                 if (!auth) logOut();

    //                 const body = JSON.stringify({
    //                     id,
    //                     title: newTitle
    //                 });

    //                 const resp = await (new api("card", auth, {
    //                     method: "PUT",
    //                     body
    //                 })).send();
                    
    //                 if (resp?.status === 'success') {
    //                     onClose(null, () => {
    //                         onRename(newTitle);

    //                         dispatch(addModal({
    //                             title: "Готово",
    //                             icon: <Icon name={IconEnum.ok} color={ColorIconEnam.base} />,
    //                             content: props => <TextModal {...props}
    //                                 text={"Новое название Вашей карты в профиле \"" + newTitle + "\""}
    //                             />
    //                         }));
    //                     });
    //                 }
    //                 else {
    //                     onClose(null, () => {
    //                         dispatch(addModal({
    //                             title: "Что-то пошло не так...",
    //                             icon: <Icon name={IconEnum.expressionless} color={ColorIconEnam.prim} />,
    //                             content: props => <TextModal {...props}
    //                                 text="Не удалось изменить название Вашей карты. Повторите попытку позже или&nbsp;обратитесь в&nbsp;службу&nbsp;поддержки."
    //                             />
    //                         }));
    //                     });
    //                 }
    //             }}
    //         />
    //     }))
    // }

    return (
        <>

            <div className={modalContent__copiedFields}>
                <CopiedField
                    label='Название (в профиле)'
                    value={title}
                />

                <CopiedField
                    label='Номер карты'
                    value={"•••• •••• •••• " + number}
                />

                <CopiedField
                    label='Срок действия'
                    value={expire}
                />
            </div>

            <div className={modalContent__buttonRow}>
                <Button onClick={deleteCard} variant="primary">
                    Удалить
                </Button>
                {/* <Button onClick={renameCard}>
                    Переименовать
                </Button> */}
            </div>

            <Button className={modalContent__button} onClick={onClose} variant="gray">
                Закрыть    
            </Button>
        </>
    );
}

export default BankCard;