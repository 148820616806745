function checkAuth() {
   const token = localStorage.getItem("token");
   const expireIn = localStorage.getItem("expireIn");

   if (token) {
      if (expireIn * 1000 < new Date().getTime()) {
         return false;
      }

      return token;
   } else {
      return false;
   }
}

export default checkAuth;
