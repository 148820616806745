import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BlockWrapper from "../../../components/Containers/BlockWrapper";
import Button from "../../../components/Inputs/Button";
import IconLogo from "../../../components/Icons/Logo";
import Input from "../../../components/Inputs/Input";
import api from "../../../helpers/fetch";

import css from "./styles.module.scss";

const {
   login,
   login__wrapper,
   login__logo,
   login__form,
   login__input,
   login__button,
   login__link,
} = css;

const LoginManagePage = () => {
   const navigate = useNavigate();

   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");

   const [errors, setErrors] = useState([]);

   const submit = async (e) => {
      e.preventDefault();

      if (validation()) {
         const body = new FormData();

         body.append("email", email);
         body.append("password", password);

         const resp = await new api("manage/auth", false, {
            method: "POST",
            body,
         }).send();

         if (resp.status === "success") {
            localStorage.setItem("token", resp.data.token);
            localStorage.setItem("expireIn", resp.data.expire_in);
            localStorage.setItem("role", resp.data.role);

            navigate(0);
         } else {
            const errorsTmp = [null, null];

            if (
               resp.message === "email is empty" ||
               resp.message === "email is invalid" ||
               resp.message === "password or email are invalid"
            ) {
               errorsTmp[0] = "Эл. почта указана не верно";
            }
            if (
               resp.message === "password is empty" ||
               resp.message === "password is invalid" ||
               resp.message === "password or email are invalid"
            ) {
               errorsTmp[1] = "Пароль указан не верно";
            }
            if (resp.message === "user not found") {
               errorsTmp[0] = "Не удалось найти пользователя с указанными данными";
            }
            if (resp.message === "can't authorize") {
               errorsTmp[0] = "Не удалось войти в систему. Повторите попытку позже.";
            }

            setErrors(errorsTmp);
         }
      }
   };

   const inputEmail = (e) => {
      const errorsTmp = errors;
      errorsTmp[0] = null;
      setErrors(errorsTmp);
      setEmail(e);
   };

   const inputPassword = (e) => {
      const errorsTmp = errors;
      errorsTmp[1] = null;
      setErrors(errorsTmp);
      setPassword(e);
   };

   const validation = () => {
      return (
         /^(\w|-|\.|_){2,}@(\w|-|\.|_){2,}\.\w{2,}$/.test(email) &&
         /^[a-zA-Z0-9а-яА-ЯёЁ\-_@!#$]{8,}$/.test(password)
      );
   };

   return (
      <div className={login}>
         <BlockWrapper className={login__wrapper}>
            <IconLogo className={login__logo} />
            <form action="/" method="POST" className={login__form} onSubmit={submit}>
               <Input
                  className={login__input}
                  placeholder="Эл. почта"
                  type="text"
                  value={email}
                  name="email"
                  onChange={inputEmail}
                  error={errors[0] ? errors[0] : null}
               />
               <Input
                  className={login__input}
                  placeholder="Пароль"
                  type="password"
                  value={password}
                  name="password"
                  onChange={inputPassword}
                  error={errors[1] ? errors[1] : null}
               />
               <Button type="submit" className={login__button} disabled={!validation()}>
                  Войти
               </Button>
            </form>
            <Link to={"/manage/reset-password/"} className={login__link}>
               Забыли пароль?
            </Link>
         </BlockWrapper>
      </div>
   );
};

export default LoginManagePage;
