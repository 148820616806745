function IconShieldCheckmark(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M10.068 2.1a.6.6 0 01.664 0 18.06 18.06 0 007.553 2.906.6.6 0 01.515.594v5.52a6.6 6.6 0 00-8.138 10.022l-.046.018a.6.6 0 01-.432 0C4.77 19.076 2 15.668 2 11V5.6a.6.6 0 01.516-.594A18.06 18.06 0 0010.068 2.1zM21.2 17a5.4 5.4 0 10-10.8 0 5.4 5.4 0 0010.8 0zm-2.575-2.225a.6.6 0 010 .85l-3.6 3.6a.598.598 0 01-.85 0l-1.2-1.2a.6.6 0 11.85-.85l.775.776 3.175-3.176a.6.6 0 01.85 0z"
         ></path>
      </svg>
   );
}

export default IconShieldCheckmark;
