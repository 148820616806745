import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import api from "../../helpers/fetch";
import logOut from "../../helpers/logout";
import checkAuth from "../../helpers/checkAuth";
import Picture from "../../components/StateLess/Picture";
import ThreeDots from "../../components/Contented/ThreeDots";
import LoadingComponent from "../../components/UI/ScreenLoader";
import DocumentsList from "../../components/UI/DocumentsList";
import ConfirmTelephone from "../../components/Inputs/ConfirmTelephone";

import scss from "./index.module.scss";

const {
   promo,
   promo__content,
   promo__heading,
   promo__title,
   promo__text,
   promo__actions,
   promo__link,
   promo__link_gray,
   promo__img,
   sing,
   sing__title,
   sing__form,
} = scss;

function VirtualCard() {
   const navigate = useNavigate();
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState("");
   const [codeSended, setCodeSended] = useState(false);
   const [codeError, setCodeError] = useState(false);
   const dots = {
      title: "Что такое виртуальная карта?",
      color: "secondary",
      list: [
         {
            title: "Интернет покупки",
            text: "Оплачивайте покупки в интернет магазинах или оплачивайте подписки на онлайн сервисы",
         },
         {
            title: "Покупки в магазинах",
            text: "Оплачивайте товары в магазинах с использованием Mir Pay или Кошелёк Pay",
         },
         {
            title: "Простая в оформлении",
            text: "Быстрое и простое оформление без похода в отделение банка",
         },
      ],
   };

   const getData = async () => {
      const auth = checkAuth();
      if (auth === false) {
         alert();
         logOut();
         return;
      }
      const responce = await new api("virtual_card_promo", auth).send();
      if (responce.status === "success") {
         const { data } = responce;
         setData(data);
         setIsLoading(true);
         if (data.status !== "created") navigate("/404");
      } else {
         logOut();
      }
   };

   const onConfirm = async (code = 0) => {
      if (/^[0-9]{6}$/.test(code)) {
         setCodeSended(true);
         const body = new FormData();
         body.append("code", code);
         const auth = checkAuth();
         if (!auth) logOut();
         const resp = await new api("virtual_card_promo/code", auth, {
            method: "POST",
            body,
         }).send();
         if (resp.status === "success") {
            setCodeSended(false);
            navigate("/profile");
         } else {
            setCodeSended(false);
            if (resp.message === "Code is empty" || resp.message === "Code is invalid") {
               setCodeError("Указанный код не совпадает с кодом, отправленным в СМС.");
            } else {
               navigate("/profile");
            }
         }
      }
   };

   useEffect(() => {
      getData();
      document.title = 'Новая виртуальная кредитная карта - ЭкспрессФинанс';

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <>
         {isLoading ? (
            <>
               <section className={promo}>
                  <div className={promo__content}>
                     <div className={promo__heading}>
                        <h1 className={promo__title}>Виртуальная карта</h1>
                        <p className={promo__text}>
                           Оформите бесплатную виртуальную карту
                           <br />с кредитным лимитом до&nbsp;{data?.card_limit?.toLocaleString("ru-RU")}&nbsp;₽ онлайн
                           <br />
                           за пару минут!
                        </p>
                     </div>
                     <div className={promo__actions}>
                        <Link
                           href="#confirm"
                           className={promo__link}
                           to={"confirm"}
                           spy={true}
                           smooth={true}
                           offset={-70}
                           duration={500}
                        >
                           Оформить карту
                        </Link>
                        <Link
                           href="#advantages"
                           className={promo__link + " " + promo__link_gray}
                           to={"advantages"}
                           spy={true}
                           smooth={true}
                           offset={-70}
                           duration={500}
                        >
                           Подробнее
                        </Link>
                     </div>
                  </div>
                  <Picture
                     className={promo__img}
                     lazy
                     fileName={"VirtualOfferCard"}
                     alt={"Рекламное предложение об оформлении виртуальной карты."}
                     ftype="png"
                     secondImges={["VirtualOfferCard", "VirtualOfferCard"]}
                  />
               </section>
               <div id="advantages">
                  <ThreeDots data={dots} />
               </div>
               <section id="confirm" className={sing}>
                  <h2 className={sing__title}>Оформить виртуальную карту</h2>
                  <DocumentsList
                     documents={[
                        {
                           url: data?.document,
                           name: "Договор о использовании виртуальной карты",
                        },
                     ]}
                  />
                  <div className={sing__form}>
                     <ConfirmTelephone
                        phone_number={data?.phone_number}
                        onConfirm={onConfirm}
                        disableInput={codeSended}
                        inputError={codeError}
                        VirtualCardGet={"virtual_card_promo/send_code"}
                        VirtualCardText={
                           "На Ваш номер будет отправлено SMS-сообщение с кодом. Введите полученный код в поле, чтобы подписать согласие с Договор о использовании виртуальной карты."
                        }
                     />
                  </div>
               </section>
            </>
         ) : (
            <LoadingComponent />
         )}
      </>
   );
}

export default VirtualCard;
