import React from "react";

export default function IconWatch(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" fill="none" viewBox="0 0 130 130" {...props}>
         <g filter="url(#filter0_i_202_1577)">
            <path
               fill="#9FD6FF"
               d="M101.562 26V12.878H28.438V26c0 16.25 11.375 29.656 26.406 33.313 1.218.406 2.031 1.624 2.031 2.843v4.875c0 1.219-.813 2.438-2.031 2.438-15.032 3.656-26.407 17.062-26.407 33.312v14h73.125v-14c0-16.25-11.374-29.656-26.406-33.312-1.219-.406-2.031-1.219-2.031-2.438v-4.875c0-1.218.813-2.437 2.031-2.437C90.187 55.656 101.562 42.25 101.562 26z"
            ></path>
            <path
               fill="url(#paint0_radial_202_1577)"
               d="M101.562 26V12.878H28.438V26c0 16.25 11.375 29.656 26.406 33.313 1.218.406 2.031 1.624 2.031 2.843v4.875c0 1.219-.813 2.438-2.031 2.438-15.032 3.656-26.407 17.062-26.407 33.312v14h73.125v-14c0-16.25-11.374-29.656-26.406-33.312-1.219-.406-2.031-1.219-2.031-2.438v-4.875c0-1.218.813-2.437 2.031-2.437C90.187 55.656 101.562 42.25 101.562 26z"
            ></path>
            <path
               fill="url(#paint1_radial_202_1577)"
               d="M101.562 26V12.878H28.438V26c0 16.25 11.375 29.656 26.406 33.313 1.218.406 2.031 1.624 2.031 2.843v4.875c0 1.219-.813 2.438-2.031 2.438-15.032 3.656-26.407 17.062-26.407 33.312v14h73.125v-14c0-16.25-11.374-29.656-26.406-33.312-1.219-.406-2.031-1.219-2.031-2.438v-4.875c0-1.218.813-2.437 2.031-2.437C90.187 55.656 101.562 42.25 101.562 26z"
            ></path>
         </g>
         <path
            fill="url(#paint2_radial_202_1577)"
            d="M101.562 16.25H28.438c-2.438 0-4.063-1.625-4.063-4.063 0-2.437 1.625-4.062 4.063-4.062h73.124c2.438 0 4.063 1.625 4.063 4.063 0 2.437-1.625 4.062-4.063 4.062z"
         ></path>
         <path
            fill="url(#paint3_linear_202_1577)"
            d="M101.562 16.25H28.438c-2.438 0-4.063-1.625-4.063-4.063 0-2.437 1.625-4.062 4.063-4.062h73.124c2.438 0 4.063 1.625 4.063 4.063 0 2.437-1.625 4.062-4.063 4.062z"
         ></path>
         <path
            fill="url(#paint4_linear_202_1577)"
            d="M101.562 16.25H28.438c-2.438 0-4.063-1.625-4.063-4.063 0-2.437 1.625-4.062 4.063-4.062h73.124c2.438 0 4.063 1.625 4.063 4.063 0 2.437-1.625 4.062-4.063 4.062z"
         ></path>
         <path
            fill="url(#paint5_radial_202_1577)"
            d="M101.562 121.875H28.438c-2.438 0-4.063-1.625-4.063-4.063 0-2.437 1.625-4.062 4.063-4.062h73.124c2.438 0 4.063 1.625 4.063 4.062 0 2.438-1.625 4.063-4.063 4.063z"
         ></path>
         <path
            fill="url(#paint6_linear_202_1577)"
            d="M101.562 121.875H28.438c-2.438 0-4.063-1.625-4.063-4.063 0-2.437 1.625-4.062 4.063-4.062h73.124c2.438 0 4.063 1.625 4.063 4.062 0 2.438-1.625 4.063-4.063 4.063z"
         ></path>
         <path
            fill="url(#paint7_linear_202_1577)"
            d="M101.562 121.875H28.438c-2.438 0-4.063-1.625-4.063-4.063 0-2.437 1.625-4.062 4.063-4.062h73.124c2.438 0 4.063 1.625 4.063 4.062 0 2.438-1.625 4.063-4.063 4.063z"
         ></path>
         <g filter="url(#filter1_i_202_1577)">
            <path
               fill="url(#paint8_radial_202_1577)"
               d="M69.063 78.813v-17.47c0-2.843 2.03-4.874 4.468-5.687 6.906-1.625 13-5.687 17.469-10.968 2.844-3.25.406-8.126-3.656-8.126H42.656c-4.062 0-6.5 4.876-3.656 8.126 4.469 5.28 10.563 8.937 17.469 10.968 2.843.813 4.468 2.844 4.468 5.688v15.843c0 2.844-1.218 4.063-2.437 4.47-15.031 2.843-26 14.218-26 27.624v4.469h65v-4.469c0-13.406-10.969-24.781-26-27.625-1.219 0-2.438-1.219-2.438-2.844z"
            ></path>
            <path
               fill="url(#paint9_radial_202_1577)"
               d="M69.063 78.813v-17.47c0-2.843 2.03-4.874 4.468-5.687 6.906-1.625 13-5.687 17.469-10.968 2.844-3.25.406-8.126-3.656-8.126H42.656c-4.062 0-6.5 4.876-3.656 8.126 4.469 5.28 10.563 8.937 17.469 10.968 2.843.813 4.468 2.844 4.468 5.688v15.843c0 2.844-1.218 4.063-2.437 4.47-15.031 2.843-26 14.218-26 27.624v4.469h65v-4.469c0-13.406-10.969-24.781-26-27.625-1.219 0-2.438-1.219-2.438-2.844z"
            ></path>
            <path
               fill="url(#paint10_radial_202_1577)"
               d="M69.063 78.813v-17.47c0-2.843 2.03-4.874 4.468-5.687 6.906-1.625 13-5.687 17.469-10.968 2.844-3.25.406-8.126-3.656-8.126H42.656c-4.062 0-6.5 4.876-3.656 8.126 4.469 5.28 10.563 8.937 17.469 10.968 2.843.813 4.468 2.844 4.468 5.688v15.843c0 2.844-1.218 4.063-2.437 4.47-15.031 2.843-26 14.218-26 27.624v4.469h65v-4.469c0-13.406-10.969-24.781-26-27.625-1.219 0-2.438-1.219-2.438-2.844z"
            ></path>
         </g>
         <path
            fill="url(#paint11_radial_202_1577)"
            d="M69.063 78.813v-17.47c0-2.843 2.03-4.874 4.468-5.687 6.906-1.625 13-5.687 17.469-10.968 2.844-3.25.406-8.126-3.656-8.126H42.656c-4.062 0-6.5 4.876-3.656 8.126 4.469 5.28 10.563 8.937 17.469 10.968 2.843.813 4.468 2.844 4.468 5.688v15.843c0 2.844-1.218 4.063-2.437 4.47-15.031 2.843-26 14.218-26 27.624v4.469h65v-4.469c0-13.406-10.969-24.781-26-27.625-1.219 0-2.438-1.219-2.438-2.844z"
         ></path>
         <path
            fill="url(#paint12_radial_202_1577)"
            d="M69.063 78.813v-17.47c0-2.843 2.03-4.874 4.468-5.687 6.906-1.625 13-5.687 17.469-10.968 2.844-3.25.406-8.126-3.656-8.126H42.656c-4.062 0-6.5 4.876-3.656 8.126 4.469 5.28 10.563 8.937 17.469 10.968 2.843.813 4.468 2.844 4.468 5.688v15.843c0 2.844-1.218 4.063-2.437 4.47-15.031 2.843-26 14.218-26 27.624v4.469h65v-4.469c0-13.406-10.969-24.781-26-27.625-1.219 0-2.438-1.219-2.438-2.844z"
         ></path>
         <path
            fill="url(#paint13_linear_202_1577)"
            d="M91 108.875a3.641 3.641 0 01-3.656-3.656c-.813-7.719-4.875-18.281-14.625-21.938-2.031-.812-2.844-2.844-2.031-4.875.812-2.031 2.843-2.844 4.875-2.031 5.687 2.031 10.562 6.094 13.812 11.781 3.25 4.875 4.875 10.563 5.281 17.063.407 1.625-1.218 3.656-3.656 3.656z"
         ></path>
         <path
            fill="url(#paint14_linear_202_1577)"
            d="M91 19.906a3.641 3.641 0 00-3.656 3.657c-.813 7.718-4.875 18.28-14.625 21.937-2.031.813-2.844 2.844-2.031 4.875.812 2.031 2.843 2.844 4.875 2.031 5.687-2.031 10.562-6.093 13.812-11.781 3.25-4.875 4.875-10.563 5.281-17.063.407-1.625-1.218-3.25-3.656-3.656z"
         ></path>
         <g filter="url(#filter2_f_202_1577)">
            <path
               fill="#FFDF81"
               d="M65.195 77.903c0 2.904-1.072 4.224-1.609 4.521h5.932c-1.642-1.097-1.857-3.473-1.76-4.522V59.26c0-2.064 1.613-3.523 2.418-3.55h-7.102c1.816.905 2.17 3.038 2.121 3.55v18.647-.004z"
            ></path>
         </g>
         <defs>
            <filter
               id="filter0_i_202_1577"
               width="73.125"
               height="103.902"
               x="28.438"
               y="12.878"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset></feOffset>
               <feGaussianBlur stdDeviation="2.031"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.619608 0 0 0 0 0.756863 0 0 0 0 0.87451 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_202_1577"></feBlend>
            </filter>
            <filter
               id="filter1_i_202_1577"
               width="66.219"
               height="77.188"
               x="32.5"
               y="36.563"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="1.219"></feOffset>
               <feGaussianBlur stdDeviation="1.219"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.705882 0 0 0 0 0.541176 0 0 0 0 0.384314 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_202_1577"></feBlend>
            </filter>
            <filter
               id="filter2_f_202_1577"
               width="13.195"
               height="32.809"
               x="60.028"
               y="52.662"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_202_1577" stdDeviation="1.523"></feGaussianBlur>
            </filter>
            <radialGradient
               id="paint0_radial_202_1577"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(131.055 20.323 65.429) scale(27.4172 22.2256)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C4F4FF"></stop>
               <stop offset="1" stopColor="#C4F4FF" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint1_radial_202_1577"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 22.8303 -44.7342 0 86.56 17.948)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C4F4FF"></stop>
               <stop offset="1" stopColor="#C4F4FF" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint2_radial_202_1577"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 4.0625 -51.2891 0 83.537 12.188)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#CDCFCF"></stop>
               <stop offset="1" stopColor="#A9A9A9"></stop>
            </radialGradient>
            <linearGradient
               id="paint3_linear_202_1577"
               x1="15.998"
               x2="65"
               y1="13.264"
               y2="13.264"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#707477"></stop>
               <stop offset="1" stopColor="#707477" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint4_linear_202_1577"
               x1="65"
               x2="65"
               y1="19.614"
               y2="14.28"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#707477"></stop>
               <stop offset="1" stopColor="#707477" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint5_radial_202_1577"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 4.57031 -57.7001 0 83.537 116.35)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#CDCFCF"></stop>
               <stop offset="1" stopColor="#A9A9A9"></stop>
            </radialGradient>
            <linearGradient
               id="paint6_linear_202_1577"
               x1="15.998"
               x2="65"
               y1="118.889"
               y2="118.889"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#707477"></stop>
               <stop offset="1" stopColor="#707477" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint7_linear_202_1577"
               x1="65"
               x2="65"
               y1="123.082"
               y2="119.145"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#7CA0B3"></stop>
               <stop offset="1" stopColor="#7CA0B3" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint8_radial_202_1577"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 40.3525 -46.5607 0 80.49 99.206)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.088" stopColor="#FFD68C"></stop>
               <stop offset="1" stopColor="#E69E45"></stop>
            </radialGradient>
            <radialGradient
               id="paint9_radial_202_1577"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(132.427 35.612 36.506) scale(31.9901 26.9391)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F3C169"></stop>
               <stop offset="1" stopColor="#F3C169" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint10_radial_202_1577"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-71.66597 17.10894 -25.9732 -108.79665 101.087 87.588)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.667" stopColor="#B7845C" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#B7845C"></stop>
            </radialGradient>
            <radialGradient
               id="paint11_radial_202_1577"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(22.34535 -8.71609 8.3883 21.50502 42.656 48.202)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#B69E6D"></stop>
               <stop offset="1" stopColor="#B69E6D" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint12_radial_202_1577"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-39.2226 0 0 -6.57028 84.622 40.617)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFE379"></stop>
               <stop offset="0.406" stopColor="#FFCB56"></stop>
               <stop offset="1" stopColor="#FFCB56" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint13_linear_202_1577"
               x1="80.998"
               x2="84.297"
               y1="82.075"
               y2="108.875"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#fff"></stop>
               <stop offset="0.822" stopColor="#fff" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint14_linear_202_1577"
               x1="93.186"
               x2="77.188"
               y1="21.645"
               y2="51.098"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#fff"></stop>
               <stop offset="0.822" stopColor="#fff" stopOpacity="0"></stop>
            </linearGradient>
         </defs>
      </svg>
   );
}
