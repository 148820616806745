import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "../../Inputs/Button";
import Icon, { ColorIconEnam, IconEnum } from "../../StateLess/Icon";
import PaymentsMethods from "../../Inputs/PaymentsMethods";
import InputHint from "../../Inputs/InputHint";

import {
    PaymentIssue,
    PaymentQR,
    PaymentRequisites,
    PaymentSuccess,
    PaymmentCard,
    TextModal
} from "../../ModalContents";

import classes from "./styles.module.scss";

import { addModal } from "../../../store/reducers/modals";

import api from "../../../helpers/fetch";
import checkAuth from "../../../helpers/checkAuth";

const {
    paymentForm,

    paymentForm__header,

    paymentForm__selects,
    paymentForm__select,
    paymentForm__selectLabel,
    paymentForm__selectInput,
    paymentForm__selectWrapper,

    paymentForm__target,
    paymentForm__targetNumber,
    paymentForm__targetOwe,
    paymentForm__targetIcon,

    paymentForm__amount,
    paymentForm__amountForm,
    paymentForm__amountFormInput,
    paymentForm__amountInput,
    paymentForm__submit,
    paymentForm__amountText
} = classes;

const PaymentForm = ({
    number = "",
    token = "",
    minimalPayment = 0,
    nextPaymentDate = 0,
    totalPayment = 0,
    paymentMethods = []
}) => {

    const dispatch = useDispatch();

    const [isWait, setWait] = useState(false);

    const [amount, setAmount] = useState();
    const [selectedMethod, setSelectedMethod] = useState(0);

    const navigate = useNavigate();

    const onPayClick = async () => {

        const method = paymentMethods[selectedMethod];

        const auth = checkAuth();

        setWait(true);

        if (method) {
            const errorModal = (content) => {
                setWait(false);
                dispatch(addModal({
                    title: "Упссссс...",
                    icon: <Icon className={""} name={IconEnum.expressionless} color={ColorIconEnam.prim} />,
                    content: (props) => <PaymentIssue {...props}>
                        {content}
                    </PaymentIssue>
                }));
            }

            const resultPayment = (status) => {
                if (status) {
                    dispatch(addModal({
                        title: "Отлично!",
                        icon: <Icon className={""} name={IconEnum.ok} color={ColorIconEnam.base} />,
                        content: PaymentSuccess,
                        onClose: () => {navigate("/")}
                    }));
                }
                else {
                    errorModal(
                        <>
                            При попытке произвести оплату произошла ошибка.<br />
                            Повторите попытку позже или&nbsp;обратитесь в&nbsp;службу поддержки.
                        </>
                    );
                }
            }
            
            if (method.type === "inner") {

                let modal;

                switch (method.img) {
                    case "card": {
                        const response = await (new api(`payments?${token ? "token=" + token + "&" : ""}type=card&amount=${amount}`, auth)).send();

                        if (response.status === "success") {
                            modal = {
                                title: "Реквизиты карты",
                                icon: <Icon className={""} name={IconEnum.card} color={ColorIconEnam.base} />,
                                content: (props) => <PaymmentCard
                                    {...props}
                                    data={response.data}
                                    token={token}
                                    number={number}
                                    onClick={resultPayment}
                                />
                            };
                        }
                        else {
                            errorModal(
                                <>
                                    Не удалось загрузить платёжную информацию.<br />
                                    Обновите страницу и&nbsp;попробуйте снова.
                                </>
                            );
                        }
                        break;
                    }
                    case "qr-code": {
                        const response = await (new api(`payments?${token ? "token=" + token + "&" : ""}type=qr&amount=${amount}`, auth)).send();

                        if (response.status === "success") {
                            modal = {
                                title: "QR-код",
                                icon: <Icon className={""} name={IconEnum.qr_code} color={ColorIconEnam.base} />,
                                content: (proprs) => <PaymentQR
                                    {...proprs}
                                    qr={response.data.qr}
                                    amount={response.data.amount}
                                    token={token}
                                    number={number}
                                    onClick={resultPayment}
                                />
                            };
                        }
                        else {
                            errorModal(
                                <>
                                    Не удалось загрузить платёжную информацию.<br />
                                    Обновите страницу и&nbsp;попробуйте снова.
                                </>
                            );
                        }
                        
                        break;
                    }
                    
                    case "check": {
                        const response = await (new api(`payments?${token ? "token=" + token + "&" : ""}type=requisites&amount=${amount}`, auth)).send();

                        if (response.status === "success") {
                            modal = {
                                title: "Реквизиты",
                                icon: <Icon className={""} name={IconEnum.check} color={ColorIconEnam.base} />,
                                content: (props) => <PaymentRequisites
                                    {...props}
                                    data={response.data}
                                    token={token}
                                    number={number}
                                    onClick={resultPayment}
                                />
                            };
                        }
                        else {
                            errorModal(
                                <>
                                    Не удалось загрузить платёжную информацию.<br />
                                    Обновите страницу и&nbsp;попробуйте снова.
                                </>
                            );
                        }
                        break;
                    }

                    default:
                        setWait(false);
                        modal = {
                            title: "Упссссс...",
                            icon: <Icon className={""} name={IconEnum.expressionless} color={ColorIconEnam.prim} />,
                            content: (props) => <PaymentIssue {...props}>
                                Что-то пошло не по плану.<br />Попробуйте обновить страницу и&nbsp;заполнить форму снова.
                            </PaymentIssue>
                        };
                    break;
                }
                
                if (modal) {
                    dispatch(addModal(modal));
                }
            }
            else {
                if (method.id) {
                    const response = await (new api(`payments?${token ? "token=" + token + "&" : ""}type=${method.id}&amount=${amount}`, auth)).send();
    
                    if (response.status === "success") {
                        if (response.redirect) {
                            window.location.href = response.redirect;
                        }
                        else if (!isNaN(method.id)) {
                            navigate("/profile");
                            dispatch(addModal({
                                title: 'Готово',
                                icon: <Icon name={IconEnum.ok} color={ColorIconEnam.base} />,
                                content: props => <TextModal {...props}
                                    text={"Платёж на сумму " + (amount.toLocaleString("ru-RU")) + " ₽ внесён"}
                                />
                            }))
                        }
                    }
                    else {
                        errorModal(
                            <>
                                Не удалось загрузить платёжную информацию.<br />
                                Обновите страницу и&nbsp;попробуйте снова.
                            </>
                        );
                    }
                }
                else {
                    errorModal(
                        <>
                            Не удалось загрузить платёжную информацию.<br />
                            Обновите страницу и&nbsp;попробуйте снова.
                        </>
                    );
                }
            }
        }
    }

    const hintOptions = [
        {
            value: parseFloat(totalPayment / 100),
            text: parseFloat(totalPayment / 100).toLocaleString("ru-RU") + " ₽"
        }
    ];

    if (minimalPayment > 0) {
        hintOptions.unshift(
            {
                value: parseFloat(minimalPayment / 100),
                text: parseFloat(minimalPayment / 100).toLocaleString("ru-RU") + " ₽"
            }
        );
    }

    return (
        <div className={paymentForm}>
            <h2 className={paymentForm__header}>
                Оплата задолженности
            </h2>

            <div className={paymentForm__selects}>
                <div className={paymentForm__select}>
                    <p className={paymentForm__selectLabel}>
                        Способ оплаты
                    </p>

                    <PaymentsMethods
                        className={paymentForm__selectInput}
                        paymentMethods={paymentMethods}
                        slectedIndex={selectedMethod}
                        onSelect={setSelectedMethod}
                    />
                </div>

                <div className={paymentForm__select}>
                    <p className={paymentForm__selectLabel}>
                        Счёт пополнения
                    </p>

                    <div className={paymentForm__selectInput + " " + paymentForm__selectWrapper}>
                        <div className={paymentForm__target}>
                            <p className={paymentForm__targetNumber}>
                                Заём №{number}
                            </p>
                            <p className={paymentForm__targetOwe}>
                                Задолженность: {parseFloat(totalPayment / 100).toLocaleString("ru-RU")} ₽
                            </p>
                        </div>

                        <Icon className={paymentForm__targetIcon} name={IconEnum.card_arrow_up} color={ColorIconEnam.base} />
                    </div>
                </div>
            </div>

            <div className={paymentForm__amount}>
                <div className={paymentForm__amountForm}>
                    <div className={paymentForm__amountFormInput}>
                        <InputHint
                            className={paymentForm__amountInput}
                            value={amount}
                            onChange={e => setAmount(parseFloat(isNaN(e) ? 0 : e))}
                            type="number"
                            placeholder={"Сумма пополнения"}
                            options={hintOptions}
                        />
                    </div>

                    <Button
                        className={paymentForm__submit}
                        disabled={amount <= 0 || amount > totalPayment || !amount}
                        onClick={onPayClick}
                        isWaiting={isWait}
                    >
                        Оплатить
                    </Button>
                </div>

                {
                    minimalPayment > 0 ? (
                        <p className={paymentForm__amountText}>
                            Ближайший минимальный платёж на сумму {parseFloat(minimalPayment / 100).toLocaleString("ru-RU")} ₽ - {new Date(nextPaymentDate).toLocaleDateString("ru-RU")}
                        </p>
                    ) : ""
                }
                
                {
                    paymentMethods[selectedMethod].id === 'sbp' ||
                    paymentMethods[selectedMethod].id === 'bank-card' ||
                    !isNaN(paymentMethods[selectedMethod].id) ? (
                        <p className={paymentForm__amountText}>
                            Комиссия платёжной системы: <b>{(amount * ((paymentMethods[selectedMethod].id === 'sbp' ? 0.7 : 3) / 100)).toLocaleString('ru-RU')}&nbsp;₽</b><br />
                            Итого: <b>{(amount + amount * ((paymentMethods[selectedMethod].id === 'sbp' ? 0.7 : 3) / 100)).toLocaleString('ru-RU')}&nbsp;₽</b>
                        </p>
                    ) : ""
                }
            </div>
        </div>
    );
}

export default PaymentForm;