import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../../components/StateFull/Header";

import css from "./index.module.scss";
import Modals from "../../components/Containers/Modals";

const { root } = css;

function Root() {

   let location = useLocation();

   useEffect(()=>{
      window.scrollTo(0, 0);
   }, [location])

   return (
      <div className={root}>
         <Header />
         <Modals />
         <Outlet />
      </div>
   );
}

export default Root;
