import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

import css from "./styles.module.scss";

const {
   inputRange,
   inputRange__wrapper,
   inputRange__text,
   inputRange__label,
   inputRange__value,
   inputRange__lines,
   inputRange__linesGroup,
   inputRange__linesItem,
   inputRange__linesItem_long
} = css;

const InputRange = ({
   ariaLabel,
   unit = "",
   label = "",
   className,
   value,
   list = [],
   length,
   step = 1,
   onChange = () => {},
   showValue = null
}) => {
   let classNameTmp = inputRange + " single-thumb";

   classNameTmp += className ? " " + className : "";

   return (
      <div className={inputRange__wrapper}>
         {
            label ? (
               <div className={inputRange__text}>
                  <div className={inputRange__label}>
                     {label}
                  </div>
                  
                  <div className={inputRange__value}>
                     {
                        showValue ? showValue : (
                           <>
                              {isNaN(list[value]) ? list[value] : parseFloat(list[value]).toLocaleString("ru-RU")}
                              {unit ? " " + unit : ""}
                           </>
                        )
                     }
                  </div>
               </div>
            ) : ""
         }
         <RangeSlider
            ariaLabel={ariaLabel}
            className={classNameTmp}
            defaultValue={[0, value]}
            step={step}
            min={0}
            max={length}
            thumbsDisabled={[true, false]}
            onInput={(e) => onChange(e[1])}
         />
         <div className={inputRange__lines}>
            {
               [0,0,0,0,0,0,0].map((group, i) => (
                  <div className={inputRange__linesGroup} key={i}>
                     <div className={inputRange__linesItem + " " + inputRange__linesItem_long} />
                     {
                        [0,0,0,0].map((item, j) => (
                           <div className={inputRange__linesItem} key={j} />
                        ))
                     }
                     {
                        i === 6 ? (
                           <div className={inputRange__linesItem + " " + inputRange__linesItem_long} />
                        ) : ""
                     }
                  </div>
               ))
            }
         </div>
      </div>
   );
};

export default InputRange;
