import { ColorIconEnam } from "../../StateLess/Icon";

import Alert from "../Alert";

import scss from "./index.module.scss";

const { banner, banner__container, banner__img, banner__body } = scss;

function Banner({ children, data, onlyBanner = false }) {
   return (
      <div className={banner__container}>
         {onlyBanner ? (
            <div className={banner}>
               <div className={banner__body}>{children}</div>
               <div className={banner__img}></div>
            </div>
         ) : (
            <>
               {data?.status === 3 ? (
                  <Alert
                     title={"В займе отказано"}
                     text={`К сожалению, мы не можем выдать Вам заём в данный момент. Вы сможете оформить новую заявку на заём ${new Date(
                        data?.denied_to
                     ).toLocaleDateString("ru-RU")}.`}
                     color={ColorIconEnam.prim}
                  ></Alert>
               ) : (
                  <div className={banner}>
                     <div className={banner__img}></div>
                     <div className={banner__body}>{children}</div>
                  </div>
               )}

               {data?.status === 2 ? (
                  <Alert
                     title={"Ограничения ЦБ РФ"}
                     text={
                        "В связи с ограничениями ЦБ РФ, при Вашей долговой нагрузке мы не можем выдать сумму выше одобренной. Данные ограничения действуют во всех МФО с 1 января 2023 года."
                     }
                     color={ColorIconEnam.red}
                  ></Alert>
               ) : null}
            </>
         )}
      </div>
   );
}

export default Banner;
