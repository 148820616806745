/**
 * Reducing text to the desired value.
 * @param {string} text - The text to be shortened..
 * @param {int} maxLength - The maximum text length you need.
 * @returns shorted text whis "..."
 */

function TextOverflow(text, maxLength) {
   if (text) {
      return text.length > maxLength ? text.substring(0, maxLength - 3).concat("...") : text;
   } else {
      return "...";
   }
}

export default TextOverflow;
