import { useNavigate } from "react-router-dom";

import Button from "../../Inputs/Button";

import classes from "../styles.module.scss";

const {
    modalContent__text,
    modalContent__button
} = classes;

const PaymentSuccess = ({
    onClose = () => {}
}) => {

    const navigate = useNavigate();

    const onClick = (path) => {
        onClose(null, () => {
            navigate(path);
        });
    }

    return (
        <>
            <p className={modalContent__text}>
                Дальше дело за нами!<br />
                Мы проверим поступление средств<br />
                и обработаем платёж.
            </p>

            <Button className={modalContent__button} onClick={() => onClick(window.location.pathname === "/oplatit" ? "/" : "/profile")}>
                {
                    window.location.pathname === "/oplatit" ? "На главную" : "В личный кабинет"
                }
            </Button>
        </>
    )
}

export default PaymentSuccess;