import Button from "../../Inputs/Button";
import OperationsHistoryItem from "../OperationsHistoryItem";

import scss from "./index.module.scss";

const {
   operationsHistory,
   operationsHistory__item,
   operationsHistory__text,
   operationsHistoryItem,
} = scss;

function OperationsHistory({ data, onPadination, wait }) {
   return (
      <ul className={operationsHistory}>
         {data?.data?.length > 0 ? (
            data?.data?.map((item, key) => (
               <li className={operationsHistoryItem} key={key}>
                  <OperationsHistoryItem
                     value={{
                        title: item?.title,
                        text: item?.sub_title,
                        date: item?.date,
                        summ: item?.amount,
                        status: item?.status,
                     }}
                  />
               </li>
            ))
         ) : (
            <li className={operationsHistory__item}>
               <span className={operationsHistory__text}>Нет данных по операциям</span>
            </li>
         )}
         {data?.pagination?.page_count > 0 &&
         data?.pagination?.page !== data?.pagination?.page_count ? (
            <li className={operationsHistory__item}>
               <Button
                  onClick={() => onPadination(data?.pagination)}
                  isWaiting={wait}
                  disabled={wait}
               >
                  Загрузить ещё
               </Button>
            </li>
         ) : null}
      </ul>
   );
}

export default OperationsHistory;
