import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BlockWrapper from "../../components/Containers/BlockWrapper";
import Button from "../../components/Inputs/Button";
import PincodeInput from "../../components/Inputs/PincodeInput";
import css from "./styles.module.scss";

const {
    resetPassword,
    resetPassword__wrapper,
    resetPassword__title,
    resetPassword__text,
    resetPassword__form,
    resetPassword__button
} = css;

const ResetPassword = () => {

    useEffect(() => {
        document.title = 'Восстановление пароля - ЭкспрессФинанс';
    }, []);

    const params = useParams();
    const navigate = useNavigate();

    if (!params.token) navigate("/");

    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [state, setState] = useState(false);

    const sendReset = async (e) => {
        e.preventDefault();

        const body = new FormData();

        body.append("token", params.token);
        body.append("password", password);

        const resp = await fetch(global.api + "reset_password", {
            method: "POST",
            body
        });

        const json = await resp.json();

        if (json.status === "success") {
            setState(true);
        }
        else {
            setError(true);

            const timer = setTimeout(() => {
                setError(false);
                clearTimeout(timer);
            }, 2000);
        }
    }

    return (
        <div className={resetPassword}>
            <BlockWrapper className={resetPassword__wrapper}>
                {
                    state ? (
                        <>
                            <h2 className={resetPassword__title}>Смена пин-кода</h2>
                            <p className={resetPassword__text}>Пин-код изменён на новый! Теперь Вы можете войти в свой личный кабинет.</p>
                            <Button href="/profile">Личный кабинет</Button>
                        </>
                    ) : (
                        <>
                            <h2 className={resetPassword__title}>Смена пин-кода</h2>
                            <p className={resetPassword__text}>Укажите новый пин-код для входа в&nbsp;личный кабинет</p>
                            <form className={resetPassword__form} onSubmit={sendReset} action="">
                            <PincodeInput
                                length={5}
                                type="password"
                                regexp={/[0-9]/}
                                value={password}
                                onChange={setPassword}
                                invalid={error}
                            />
                            <Button
                                className={resetPassword__button}
                                onClick={sendReset}
                                disabled={password.length !== 5}
                            >Сменить пин-код</Button>
                            </form>
                        </>
                    )
                }
            </BlockWrapper>
        </div>
    );
}

export default ResetPassword;