import { Link } from "react-router-dom";

import formatPhone from "../../../helpers/formatPhone";

import IconDisclaimer from "../../Icons/Disclaimer";
import IconLogo from "../../Icons/Logo";
import Icon, { IconEnum } from "../../StateLess/Icon";

import classes from "./index.module.scss";

const {
   footer,
   footer__aboutCompany,
   footer__grid,
   footer__cel,
   footer__info,
   footer__links,
   footer__contacts,
   footer__pictures,
   footer__age,
   footer__logo,
   footer__pays,
   footer__pay,
} = classes;

const initialState = window.initialState || {
   settings: {
      about: {
         inn: 7805772796,
         ogrn: 1207800157465,
         license_cb: "№2103140009674, выдана 28.04.2021 года",
         min_loan: 10000,
         max_loan: 30000,
         min_interest: 0,
         max_interest: 1,
         min_duration: 10,
         max_duration: 30,
         min_psk: 0,
         max_psk: 365,
         address:
            "Адрес: 195221, город Санкт-Петербург, пр-кт Металлистов, д. 75 литера А, кв. 12",
         phone: 8126288181,
         email: "support@slon.finance",
         work_time: "ежедневно: с 06:00 до 22:00 (по Москве).",
         footer_text: [
            "Компания внесена в госреестр Центрального банка России и осуществляет свою деятельность в рамках Федеральных законов РФ. ЦБ РФ контролирует работу МФО и оказывает содействие физическим лицам, в онлайн-режиме доступна интернет-приёмная Банка России.",
            "Адрес местонахождения и почтовый адрес организации: 195221, город Санкт-Петербург, пр-кт Металлистов, д. 75 литера А, кв. 12. Номер телефона службы обеспечения деятельности финансового уполномоченного - +7 (812) 628-81-81.",
         ],
         copyright: [
            "© 2020-2023 Общество с ограниченной ответственностью Микрокредитная компания «СЕВЕРНАЯ ПАЛЬМИРА» (сокращенно – ООО МКК «СЕВЕРНАЯ ПАЛЬМИРА»), осуществляет деятельность под контролем Центрального Банка РФ.",
         ],
      },
   },
};

function Footer() {
   const about = initialState.settings.about || {};

   const footerTextItems = about.footer_text.map((text, i) => <p key={i}>{text}</p>);

   const footerGridItems = [
      {
         title: "Сумма займа",
         content: `от ${parseFloat(about.min_loan).toLocaleString(
            "ru-RU"
         )} до ${parseFloat(about.max_loan).toLocaleString("ru-RU")} рублей`,
      },
      {
         title: "Срок займа",
         content: `от ${about.min_duration} до ${about.max_duration} календарных дней`,
      },
      {
         title: "Процентная ставка",
         content: `от ${about.min_interest}% до ${about.max_interest}% в день`,
      },
      {
         title: "ПСК (полная стоимость кредита)",
         content: `от ${about.min_psk}% до ${about.max_psk}% годовых`,
      },
      { title: "ИНН", content: about.inn },
      { title: "ОРГН", content: about.ogrn },
      { title: "Лицензия Центрального Банка", content: about.license_cb },
   ].map((item, index) => (
      <div key={index} className={footer__cel}>
         <h3>{item.title}</h3>
         <p>{item.content}</p>
      </div>
   ));

   const footerLinks = [
      { label: "Главная страница", path: "/" },
      { label: "О нас", path: "/o-nas" },
      { label: "Документы", path: "/dokumenti" },
      { label: "Дополнительные услуги", path: "/dopolnitelnie-uslugi" },
      { label: "Оплатить", path: "/oplatit" },
      { label: "Личный кабинет", path: "/profile" },
      {
         label: "Политика конфиденциальности",
         path: "/politika-konfidencialnosti",
      },
   ].map((link, index) => (
      <Link key={index} to={link.path}>
         {link.label}
      </Link>
   ));

   return (
      <footer className={footer}>
         <div className={footer__aboutCompany}>{footerTextItems}</div>
         <div className={footer__grid}>{footerGridItems}</div>
         <div className={footer__info}>
            <div className={footer__links}>{footerLinks}</div>
            <div className={footer__contacts}>
               <p>{about?.copyright[0]}</p>
               <address>Адрес: {about.address}</address>
               <p>
                  Телефон для связи:{" "}
                  <a href={`tel:+7${about.phone}`}>{formatPhone(about.phone)}</a>
               </p>
               <p>График работы: {about.work_time}</p>
               <p>
                  Служба поддержки клиентов:{" "}
                  <a href={`mailto:${about.email}`}>{about.email}</a>
               </p>
            </div>
         </div>
         <div className={footer__pictures}>
            <Link aria-label="Главная" to="/" title="Главная">
               <IconLogo className={footer__logo} />
            </Link>
            <IconDisclaimer className={footer__age} />
            <div className={footer__pays}>
               <Icon className={footer__pay} name={IconEnum.sbp_min_text} />
               <Icon className={footer__pay} name={IconEnum.mir} />
               <Icon className={footer__pay} name={IconEnum.visa} />
               <Icon className={footer__pay} name={IconEnum.mastercard} />
            </div>
         </div>
      </footer>
   );
}

export default Footer;
