import { useState } from "react";
import ListCards from "../ListCards";
import ListServices from "../ListServices";
import OperationsHistory from "../OperationsHistory";

import scss from "./index.module.scss";

const {
   mobileСarousel,
   mobileСarousel__heading,
   toggleCarousel,
   toggleButton,
   toggleButton__active,
   mobileСarousel__container,
   mobileСarousel__card,
   mobileСarousel__services,
   mobileСarousel__history,
} = scss;

const MobileСarousel = ({
   data,
   onStory,
   transaction = {},
   onPadination = {},
   wait = {},

   onCardDelete = () => {},
   onCardRename = () => {}
}) => {
   const [activeCarouselItem, setActiveCarouselItem] = useState(0);
   const toggleCarouselItems = [
      {
         id: 0,
         title: "Мои карты",
      },
      {
         id: 1,
         title: "Мои услуги",
      },
      {
         id: 2,
         title: "История операций",
      },
   ];
   return (
      <div className={mobileСarousel}>
         <div className={mobileСarousel__heading}>
            <div className={toggleCarousel}>
               {toggleCarouselItems.map((item) => (
                  <button
                     className={
                        activeCarouselItem === item.id
                           ? toggleButton + " " + toggleButton__active
                           : toggleButton
                     }
                     key={item.id}
                     onClick={() => setActiveCarouselItem(item.id)}
                  >
                     {item.title}
                  </button>
               ))}
            </div>
         </div>
         <div className={mobileСarousel__container}>
            {activeCarouselItem === 0 ? (
               <div className={mobileСarousel__card}>
                  <ListCards
                     data={data}
                     onDelete={onCardDelete}
                     onRename={onCardRename}
                  />
               </div>
            ) : null}

            {activeCarouselItem === 1 ? (
               <div className={mobileСarousel__services}>
                  <ListServices services={data?.services} onStory={onStory} />
               </div>
            ) : null}

            {activeCarouselItem === 2 ? (
               <div className={mobileСarousel__history}>
                  <OperationsHistory
                     data={transaction}
                     onPadination={onPadination}
                     wait={wait}
                  />
               </div>
            ) : null}
         </div>
      </div>
   );
}

export default MobileСarousel;
