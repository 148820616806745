import Button from "../../Inputs/Button";
import Timer from "../../StateLess/Timer";

import BannerGrid from "../BannerGrid";

import scss from "./index.module.scss";

const { banner__content, banner__heading, banner__title, banner__text, banner__button } =
   scss;

function BannerContent({ data }) {
   switch (data?.status) {
      case 0: {
         return (
            <div className={banner__content}>
               <div className={banner__heading}>
                  <h2 className={banner__title}>Оформить заём</h2>
                  <p className={banner__text}>
                     Вы можете оставить заявку на получения заёма в необходимом размере. Мы
                     рассмотрим заявку и уведомим вас о решении.
                  </p>
               </div>
               <Button className={banner__button} href={"/application"}>
                  Оформить заём
               </Button>
            </div>
         );
      }
      case 1: {
         return (
            <div className={banner__content}>
               {
                  data?.waiting_time - new Date().getTime() > 0 ? (
                     <>
                        <div className={banner__heading}>
                           <h2 className={banner__title}>Рассмотрение заявки</h2>
                           <p className={banner__text}>
                              Мы сообщим о решении <wbr /> по Вашему займу <br />в течении:
                           </p>
                        </div>
                        <Timer date={data?.waiting_time} />
                     </>
                  ) : (
                     <div className={banner__heading}>
                        <h2 className={banner__title}>Рассмотрение заявки</h2>
                        <p className={banner__text}>
                           Нам требуется еще некоторое время для&nbsp;принятия решения по&nbsp;вашей заявке.<br /><br />
                           Пожалуйста ожидайте решения.
                        </p>
                     </div>
                  )
               }
            </div>
         );
      }
      case 2: {
         return (
            <div className={banner__content}>
               <div className={banner__heading}>
                  <h2 className={banner__title}>Заём одобрен!</h2>
                  <p className={banner__text}>
                     Осталось лишь подписать документы и получить деньги на карту.
                  </p>
               </div>
               <Button className={banner__button} href={"/sign"}>
                  Подписать документы
               </Button>
            </div>
         );
      }
      case 4: {
         return <BannerGrid data={data} />;
      }
      default: {
         return;
      }
   }
}

export default BannerContent;
