import scss from "./index.module.scss";

const { banner__grid, banner__grid__item, banner__grid__title, banner__grid__subtitle } =
   scss;

function BannerGrid({ data }) {
   return (
      <div className={banner__grid}>
         <div className={banner__grid__item}>
            <span className={banner__grid__title}>Сумма займа</span>
            <span className={banner__grid__subtitle}>
               {data?.current?.total
                  ? `${parseFloat(data?.current?.balance / 100).toLocaleString("ru-RU")} ₽`
                  : `${parseFloat(data?.info?.total_amount / 100).toLocaleString(
                       "ru-RU"
                    )} ₽`}
            </span>
         </div>
         <div className={banner__grid__item}>
            <span className={banner__grid__title}>Процентная ставка</span>
            <span className={banner__grid__subtitle}>
               {data?.current?.rate || data?.current?.rate === 0
                  ? `${parseFloat(data?.current?.rate).toLocaleString("ru-RU")}%`
                  : "0%"}
            </span>
         </div>
         <div className={banner__grid__item}>
            <span className={banner__grid__title}>Минимальный платёж</span>
            <span className={banner__grid__subtitle}>
               {data?.info
                  ? data?.info?.total >= 0
                     ? data?.info?.total === 0
                        ? "Оплачено"
                        : `${parseFloat(data?.info?.total / 100).toLocaleString(
                             "ru-RU"
                          )} ₽`
                     : `${parseFloat(
                          data?.info?.next_payment_amount / 100
                       ).toLocaleString("ru-RU")} ₽`
                  : null}
            </span>
         </div>
         <div className={banner__grid__item}>
            <span className={banner__grid__title}>Дата платежа</span>
            <span className={banner__grid__subtitle}>
               {data?.info
                  ? data?.info?.date
                     ? new Date(data?.info?.date)?.toLocaleDateString("RU-ru")
                     : new Date(data?.info?.next_payment_date)?.toLocaleDateString(
                          "RU-ru"
                       )
                  : null}
            </span>
         </div>
      </div>
   );
}

export default BannerGrid;
