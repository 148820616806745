import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import Button from "../../Inputs/Button";
import IconLogo from "../../Icons/Logo";
import Icon, { ColorIconEnam, IconEnum } from "../../StateLess/Icon";
import { Supports } from "../../ModalContents";
import ClientHeaderBlock from "../../PersonalArea/ClientHeaderBlock";

import css from "./styles.module.scss";

import checkAuth from "../../../helpers/checkAuth";
import logOut from "../../../helpers/logout";

import { addModal } from "../../../store/reducers/modals";

const {
   header,
   header__logo,
   header__logoImg,
   header__nav,
   header__navList,
   header__navListItem,
   header__navListItemLink,
   header__button,
   header__mobileMenuWrapper,
   header__mobileMenuWrapper_shown,
   header__mobileMenuBackground,
   header__mobileMenu,
   header__mobileMenu_shown,
   header__mobileMenuLine,
   header__mobileMenuGrid,
   header__mobileMenuGridItem,
   header__mobileMenuGridItemText,
   header__mobileMenuGridLogOut,

   mobileMenuBar,
   mobileMenuBar__item,
   mobileMenuBar__item_hideOnMobile,
   mobileMenuBar__item_hideOnTablet,
   mobileMenuBar__itemIcon,
   mobileMenuBar__itemText
} = css;

let initState = {
   menu_items: [
      {
         id_page: 0,
         title: "О нас",
         short_title: "О нас",
         pathname: "o-nas"
      },
      {
         id_page: 1,
         title: "Документы",
         short_title: "Документы",
         pathname: "dokumenti"
      },
      {
         id_page: 2,
         title: "Дополнительные услуги",
         short_title: "Услуги",
         pathname: "dopolnitelnie-uslugi"
      },
   ]
};

if (window.initialState) {
   initState = window.initialState;
}

function Header() {
   const location = useLocation();

   const [mobileMenuState, setMobileMenuState] = useState([false, false]);

   const [menuItems] = useState(initState.menu_items ? initState.menu_items : []);

   const startMenuShowing = () => {
      setMobileMenuState([true, false]);
      document.body.style.overflow = "hidden";
      const timeOut = setTimeout(() => {
         setMobileMenuState([true, true]);
         clearTimeout(timeOut);
      }, 1);
   };

   const endMenuShowing = () => {
      setMobileMenuState([true, false]);
      document.body.style.overflow = null;
      const timeOut = setTimeout(() => {
         setMobileMenuState([false, false]);
         clearTimeout(timeOut);
      }, 300);
   };

   useEffect(() => {
      
   }, [location.pathname]);

   const mobileMenuIcons = [
      <Icon className={mobileMenuBar__itemIcon} name={IconEnum.about_menu} color={ColorIconEnam.base} />,
      <Icon className={mobileMenuBar__itemIcon} name={IconEnum.document_menu} color={ColorIconEnam.base} />,
      <Icon className={mobileMenuBar__itemIcon} name={IconEnum.services_menu} color={ColorIconEnam.base} />
   ]

   const token = checkAuth();

   const dispatch = useDispatch();

   const showSupport = () => {
      dispatch(addModal({
         title: "Поддержка",
         icon: <Icon name={IconEnum.user_speak} color={ColorIconEnam.base} />,
         content: (props) => <Supports {...props} initialState={initState} />
      }))
   }

   return (
      <>
         <header className={header}>
            <Link aria-label="Главная" to="/" className={header__logo}>
               <IconLogo className={header__logoImg} />
            </Link>

            <nav className={header__nav}>
               <ul className={header__navList}>
                  {menuItems.map((item) => (
                     <li className={header__navListItem} key={item.id_page}>
                        <Link
                           to={item.pathname !== "/" ? "/" + item.pathname : "/"}
                           className={header__navListItemLink}
                           aria-label={item.title}
                        >
                           {item.title}
                        </Link>
                     </li>
                  ))}
                  <li className={header__navListItem}>
                     <Link aria-label="Оплатить" to="/oplatit" className={header__navListItemLink}>
                        Оплатить
                     </Link>
                  </li>
               </ul>
            </nav>

            <ClientHeaderBlock className={header__button}>
               <Button
                  aria-label="Личный кабинет"
                  href={"/profile"}
                  className={header__button}
                  variant={"transparent"}
               >
                  <Icon className={""} name={IconEnum.user} color={ColorIconEnam.base} /> Войти
               </Button>
            </ClientHeaderBlock>
         </header>

         <div className={mobileMenuBar}>
            <Button
               variant="transparent"
               className={mobileMenuBar__item}
               href={"/"}
               title="Главная"
            >
               <Icon className={mobileMenuBar__itemIcon} name={IconEnum.home_menu} color={ColorIconEnam.base} />
               <p className={mobileMenuBar__itemText}>Главная</p>
            </Button>

            {menuItems.map((item, i) => (
               <Button
                  variant="transparent"
                  className={mobileMenuBar__item + " " + mobileMenuBar__item_hideOnMobile}
                  href={item.pathname}
                  key={item.id_page}
                  title={item.short_title}
               >
                  {mobileMenuIcons[i]}
                  <p className={mobileMenuBar__itemText}>{item.short_title}</p>
               </Button>
            ))}
            
            <Button
               variant="transparent"
               className={mobileMenuBar__item}
               href={"/oplatit"}
               title="Оплатить"
            >
               <Icon className={mobileMenuBar__itemIcon} name={IconEnum.card} color={ColorIconEnam.base} />
               <p className={mobileMenuBar__itemText}>Оплатить</p>
            </Button>
            
            <Button
               variant="transparent"
               className={mobileMenuBar__item}
               href={"/profile"}
               title="Профиль"
            >
               <Icon className={mobileMenuBar__itemIcon} name={IconEnum.user} color={ColorIconEnam.base} />
               <p className={mobileMenuBar__itemText}>Профиль</p>
            </Button>
            
            <Button
               variant="transparent"
               className={mobileMenuBar__item + " " + mobileMenuBar__item_hideOnTablet}
               onClick={startMenuShowing}
               title="Меню"
            >
               <Icon className={mobileMenuBar__itemIcon} name={IconEnum.menu} color={ColorIconEnam.base} />
               <p className={mobileMenuBar__itemText}>Меню</p>
            </Button>
         </div>

         <div
            className={header__mobileMenuWrapper + (mobileMenuState[1] ? " " + header__mobileMenuWrapper_shown : "")}
            style={{ display: mobileMenuState[0] ? "flex" : "none" }}
         >
            <div className={header__mobileMenuBackground} onClick={endMenuShowing} />
            <div className={header__mobileMenu + (mobileMenuState[1] ? " " + header__mobileMenu_shown : "")}>
               <div className={header__mobileMenuLine} />

               <div className={header__mobileMenuGrid}>
                  
                  {menuItems.map((item, i) => (
                     <Button
                        variant="transparent"
                        className={header__mobileMenuGridItem}
                        href={item.pathname}
                        key={item.id_page}
                        onClick={endMenuShowing}
                        title={item.short_title}
                     >
                        {mobileMenuIcons[i]}
                        <p className={header__mobileMenuGridItemText}>{item.short_title}</p>
                     </Button>
                  ))}
                  
                  <Button
                        variant="transparent"
                        className={header__mobileMenuGridItem}
                        onClick={() => {
                           showSupport();
                           endMenuShowing();
                        }}
                        title="Контакты"
                     >
                     <Icon className={mobileMenuBar__itemIcon} name={IconEnum.message} color={ColorIconEnam.base} />
                     <p className={header__mobileMenuGridItemText}>Контакты</p>
                  </Button>
                  
                  {
                     token ? (
                        <Button
                              variant="transparent"
                              className={header__mobileMenuGridLogOut}
                              onClick={() => {
                                 endMenuShowing();
                                 logOut();
                              }}
                           >
                           Выйти из профиля
                        </Button>
                     ) : ""
                  }
               </div>
            </div>
         </div>
      </>
   );
}

export default Header;
