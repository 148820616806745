import scss from "./index.module.scss";

const { container } = scss;

function Container({ children, className }) {
   let tmpClassName = container;
   tmpClassName += className ? " " + className : "";
   return <section className={tmpClassName}>{children}</section>;
}

export default Container;
