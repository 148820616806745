import { useRef } from "react";

import Button from "../../Inputs/Button";
import IconAttach from "../../Icons/Attach";

import Icon, { ColorIconEnam, IconEnum } from "../../StateLess/Icon";

import ToolTipWrapper, { createInputToolTip } from "../../Inputs/ToolTipWrapper";

import scss from "./index.module.scss";

const {
   servicesCard,
   servicesCard__wrapper,
   card__wrapper,
   card__icon,
   card__title,
   card__content,
} = scss;

function ServicesCard({ data, editable = false, blockIndex = -1, onChange = () => {} }) {
   const defaultCards = data.cards.map((card) => ({
      Title: () => useRef(card.title).current,
      Text: () => useRef(card.text).current,
      Info: () => useRef(card.info).current,
   }));

   return (
      <section className={servicesCard}>
         <div className={servicesCard__wrapper}>
            {/* Карточки */}
            {data.cards.map((card, key) => (
               <article className={card__wrapper} key={key}>
                  <Icon
                     className={card__icon}
                     name={key === 0 ? IconEnum.rocket : IconEnum.card}
                     color={ColorIconEnam.base}
                  />
                  {/* Заголовок карточки */}
                  <h3
                     className={card__title}
                     contentEditable={editable}
                     suppressContentEditableWarning={true}
                     value={card.title}
                     onChange={(e) =>
                        onChange(blockIndex, `cards.${key}.title`, e.target.textContent)
                     }
                     onBlur={(e) =>
                        onChange(blockIndex, `cards.${key}.title`, e.target.textContent)
                     }
                  >
                     {card.title}
                  </h3>
                  <div className={card__content}>
                     {/* Текст карточки */}
                     <p
                        contentEditable={editable}
                        suppressContentEditableWarning={true}
                        value={card.text}
                        onChange={(e) =>
                           onChange(blockIndex, `cards.${key}.text`, e.target.textContent)
                        }
                        onBlur={(e) =>
                           onChange(blockIndex, `cards.${key}.text`, e.target.textContent)
                        }
                     >
                        {defaultCards[key].Text()}
                     </p>
                     {/* Информация карточки */}
                     <span
                        contentEditable={editable}
                        suppressContentEditableWarning={true}
                        value={card.info}
                        onChange={(e) =>
                           onChange(blockIndex, `cards.${key}.info`, e.target.textContent)
                        }
                        onBlur={(e) =>
                           onChange(blockIndex, `cards.${key}.info`, e.target.textContent)
                        }
                     >
                        {defaultCards[key].Info()}
                     </span>
                  </div>
                  {/* Кнопка с всплывающей подсказкой */}
                  <ToolTipWrapper
                     showOnHover={editable}
                     tooltipContent={createInputToolTip(
                        <IconAttach />,
                        "Ссылка",
                        "text",
                        card.href,
                        (val) => onChange(blockIndex, `cards.${key}.href`, val)
                     )}
                  >
                     <Button
                        href={card.href}
                        contentEditable={editable}
                        value={card.button_text}
                        onChange={(e) =>
                           onChange(
                              blockIndex,
                              `cards.${key}.button_text`,
                              e.target.textContent
                           )
                        }
                     >
                        {card.button_text}
                     </Button>
                  </ToolTipWrapper>
               </article>
            ))}
         </div>
      </section>
   );
}

export default ServicesCard;
