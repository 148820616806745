import { useEffect, useState } from "react";
import formatPhone from "../../../helpers/formatPhone";
import styles from "./index.module.scss";

const { footer, footer__wrapper, footer__text, footer__link } = styles;

const PAFooter = () => {
   const [phone, setPhone] = useState();
   const [email, setEmail] = useState();

   useEffect(() => {
      const initialState = window.initialState || {
         settings: {
            about: {
               phone: 4862331242,
               email: "support@экспрессфинанс.рф",
            },
         },
      };

      setPhone(formatPhone(initialState.settings.about.phone));
      setEmail(initialState.settings.about.email);
   }, []);

   return (
      <footer className={footer}>
         <div className={footer__wrapper}>
            <span className={footer__text}>
               Телефон для связи: <wbr />
               <a
                  className={footer__link}
                  href={`tel:${phone}`}
                  title={`Позвонить по номеру: ${phone}`}
               >
                  {phone}
               </a>
            </span>
            <span className={footer__text}>
               Служба поддержки клиентов: <wbr />
               <a
                  className={footer__link}
                  href={`mailto:${email}`}
                  title={`Написать на почту: ${email}`}
               >
                  {email}
               </a>
            </span>
         </div>
      </footer>
   );
};

export default PAFooter;
