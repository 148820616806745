import css from "./styles.module.scss";

const {
    blockWrapper
} = css;

const BlockWrapper = ({className, children}) => {
    return (
        <div className={blockWrapper + (className ? " " + className : "")}>
            {children}
        </div>
    );
}

export default BlockWrapper;