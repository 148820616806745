import React, { useState, useEffect } from "react";
import LoadingComponent from "../../components/UI/ScreenLoader";
import Banner from "../../components/PersonalArea/Banner";
import BannerContent from "../../components/PersonalArea/BannerContent";

import checkAuth from "../../helpers/checkAuth";
import logOut from "../../helpers/logout";
import api from "../../helpers/fetch";

import scss from "./index.module.scss";
import AboutLoanCard from "../../components/PersonalArea/AboutLoanCard";
import ActionButton from "../../components/PersonalArea/ActionButton";
import Icon, { ColorIconEnam, IconEnum } from "../../components/StateLess/Icon";
import ListCards from "../../components/PersonalArea/ListCards";
import ListServices from "../../components/PersonalArea/ListServices";
import OperationsHistory from "../../components/PersonalArea/OperationsHistory";
import MobileСarousel from "../../components/PersonalArea/MobileСarousel";
import { useDispatch } from "react-redux";
import { addModal } from "../../store/reducers/modals";

import { Supports, LoanStory } from "../../components/ModalContents";

const {
   profile__title,
   cards,
   alert,
   alert__desktop,
   alert__desktop_show,
   alert__mobile,
   services,
   actions,
   actions__buttons,
   story,
   slider,
   lists,
} = scss;

let initialState = {};

if (window.initialState) {
   initialState = window.initialState;
}

function Profile() {

   const [data, setData] = useState({});
   const [storyTransaction, setStoryTransaction] = useState({});
   const [loading, setLoading] = useState(true);
   const [isWait, setIsWait] = useState(false);

   const dispatch = useDispatch();

   const getData = async () => {
      const auth = checkAuth();

      if (!auth) {
         logOut();
         return;
      }

      const response = await new api("profile", auth).send();

      if ( response.status === "success" ) {
         const { data } = response;
         setData({ ...data });

         setLoading(false);
         
         const responseStoryTransaction = await new api("transactions", auth).send();

         if ( responseStoryTransaction.status === "success" ) {
            setStoryTransaction({ ...responseStoryTransaction });
         }
      } else {
         logOut();
      }
   };

   const onPadination = async (pagination) => {
      const auth = checkAuth();

      if (!auth) {
         logOut();
         return;
      }

      setIsWait(true);

      const responseStoryTransaction = await new api(
         `transactions/?page=${pagination?.page + 1}`,
         auth
      ).send();

      if (responseStoryTransaction.status === "success") {
         let newObjectStory = { ...storyTransaction };
         newObjectStory.data.push(...responseStoryTransaction?.data);
         newObjectStory.pagination.page = responseStoryTransaction?.pagination?.page;
         setStoryTransaction(newObjectStory);
      } else {
         logOut();
      }
      setIsWait(false);
   };

   const showLoanStory = () => {
      dispatch(
         addModal({
            title: "История займов",
            icon: <Icon name={IconEnum.clock} color={ColorIconEnam.base} />,
            content: (props) => <LoanStory data={data?.story} {...props} />,
         })
      );
   };

   useEffect(() => {
      getData();
      document.title = 'Личный кабинет клиента - ЭкспрессФинанс';
   }, []);

   useEffect(() => {
      if (!loading) { // && document.querySelector('#yandex_rtb_R-A-2643536-2')
         window.yaContextCb.push(()=>{
            const delay = setTimeout(() => {
               clearTimeout(delay);
   
               // eslint-disable-next-line
               Ya.Context.AdvManager.render({
                  "blockId": "R-A-2643536-2",
                  "renderTo": "yandex_rtb_R-A-2643536-2"
               });
            }, 100);
         });
      }
   }, [loading]);

   const showSupport = () => {
      dispatch(addModal({
         title: "Поддержка",
         icon: <Icon name={IconEnum.user_speak} color={ColorIconEnam.base} />,
         content: (props) => <Supports {...props} initialState={initialState} />
      }))
   }

   const onCardDelete = (i) => {
      const tmp_data = JSON.parse(JSON.stringify(data));

      if (tmp_data.bank_cards[i]) {
         tmp_data.bank_cards.splice(i, 1);
         setData(tmp_data);
      }
   }

   const onCardRename = (i, title) => {
      const tmp_data = JSON.parse(JSON.stringify(data));

      if (tmp_data.bank_cards[i]) {
         tmp_data.bank_cards[i].title = title;
         setData(tmp_data);
      }
   }

   return !loading ? (
      <>
         <section className={lists}>
            <div className={cards}>
               <h2 className={profile__title}>Мои карты</h2>
               <ListCards
                  data={data}
                  onDelete={onCardDelete}
                  onRename={onCardRename}
               />
            </div>
            <div className={services}>
               <h2 className={profile__title}>Мои услуги</h2>
               <ListServices services={data?.services} onStory={showLoanStory} />
            </div>
         </section>
         <section className={alert}>
            {data?.status === 4 ? (
               <>
                  <div className={alert__desktop}>
                     <Banner data={data}>
                        <BannerContent data={data} />
                     </Banner>
                  </div>
                  <div className={alert__mobile}>
                     <AboutLoanCard data={data} />
                  </div>
               </>
            ) : null}

            {data?.status !== 4 ? (
               <div className={alert__desktop_show}>
                  <Banner data={data}>
                     <BannerContent data={data} />
                  </Banner>
               </div>
            ) : null}

            
            <div style={{marginTop: '24px'}} className="" id="yandex_rtb_R-A-2643536-2"></div>
         </section>
         {data?.status === 4 ? (
            <section className={actions}>
               <h2 className={profile__title}>Действия</h2>
               <div className={actions__buttons}>
                  <ActionButton
                     value={"Внести платёж"}
                     iconName={IconEnum.cash_getter}
                     iconColor={ColorIconEnam.base}
                     href="/payment/"
                  />
                  <ActionButton
                     value={"Сведения по займу"}
                     iconName={IconEnum.document}
                     iconColor={ColorIconEnam.base}
                     href={"/loan/" + data?.current?.id}
                  />
                 <ActionButton
                    value={"Поддрежка"}
                    iconName={IconEnum.user_speak}
                    iconColor={ColorIconEnam.base}
                    action={showSupport}
                 />
               </div>
            </section>
         ) : null}
         <section className={story}>
            <h2 className={profile__title}>История операций</h2>
            <OperationsHistory
               data={storyTransaction}
               onPadination={onPadination}
               wait={isWait}
            />
         </section>
         <section className={slider}>
            <MobileСarousel
               data={data}
               transaction={storyTransaction}
               onPadination={onPadination}
               wait={isWait}
               onStory={showLoanStory}
               
               onCardDelete={onCardDelete}
               onCardRename={onCardRename}
            />
         </section>
      </>
   ) : (
      <LoadingComponent />
   );
}

export default Profile;
