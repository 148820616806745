import { useState } from 'react';
import classes from './styles.module.scss';

import Tab from './tab';

const {
    tabView,
    tabView__controls,
    tabView__tab,
    tabView__tab_active,
    tabView__tabName,
    tabView__container
} = classes;

const TabView = ({
    className = '',
    children
}) => {

    let tmpClassName = tabView;
    if (className) tmpClassName += ' ' + className;

    const [selectedTab, setSelectedTab] = useState(0);

    if (!Array.isArray(children)) {
        children = [children];
    }

    return (
        <div className={tmpClassName}>
            <div className={tabView__controls}>
                {
                    children.map((item, i) => (
                        item?.props?.tabName ? (
                            <button
                                className={tabView__tab + (selectedTab === i ? ' ' + tabView__tab_active : '')}
                                type='button'
                                onClick={() => setSelectedTab(i)}
                                key={i}
                            >
                                {
                                    item.props.icon ? item.props.icon : ''
                                }

                                <span className={tabView__tabName}>
                                    {item.props.tabName}
                                </span>
                            </button>
                        ) : null
                    ))
                }
            </div>

            <div className={tabView__container}>
                {children[selectedTab] ? (
                    children[selectedTab].props.tabName ? children[selectedTab] : ''
                ) : ''}
            </div>
        </div>
    );
}

export { Tab };
export default TabView;