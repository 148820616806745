const NotFoundIcon = (props) => {
   return (
      <svg width="593" height="200" viewBox="0 0 593 200" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
         <path
            d="M215.835 58.7h67.171a17.33 17.33 0 0 1 2.422-2.69c3.278-2.913 7.013-4.37 11.202-4.37s7.923 1.456 11.202 4.37c.85.757 1.651 1.653 2.404 2.69h67.044a94.3 94.3 0 0 0-5.24-12.798c-7.286-14.937-17.487-26.321-30.601-34.153C328.506 3.916 313.57 0 296.63 0c-16.94 0-31.967 3.916-45.082 11.749-12.933 7.832-23.133 19.216-30.601 34.153a95.474 95.474 0 0 0-5.112 12.798z"
            fill="url(#a)"
         />
         <path d="m124.999 58.7 36.135-54.328h-66.12L57.49 58.7H125z" fill="url(#b)" />
         <path d="m508.687 58.7 36.135-54.328h-66.12L441.177 58.7h67.51z" fill="url(#c)" />
         <path
            d="M43.493 58.7 0 121.67h187.029v-8.828h-25.41V83.607h-60.656v29.235h-25.97l36.01-54.141h-67.51z"
            fill="url(#d)"
         />
         <path
            d="M201.838 58.7c-3.877 12.12-5.816 25.886-5.816 41.3 0 7.627.474 14.85 1.424 21.67h64.638c-.872-6.002-1.308-13.226-1.308-21.67 0-12.386.911-22.04 2.732-28.962 1.484-5.261 3.318-9.374 5.502-12.337h-67.172z"
            fill="url(#e)"
         />
         <path
            d="M296.239 58.7c2.149 2.964 3.898 7.077 5.247 12.338 2.004 6.922 3.006 16.576 3.006 28.962 0 8.444-.48 15.668-1.439 21.67h64.733c.973-6.82 1.46-14.043 1.46-21.67 0-15.414-1.988-29.18-5.962-41.3h-67.045z"
            fill="url(#f)"
         />
         <path
            d="m427.181 58.7-43.493 62.97h187.028v-8.828h-25.409V83.607h-60.656v29.235h-25.97l36.01-54.141h-67.51z"
            fill="url(#g)"
         />
         <path d="m21.33 121.67-.129.188v40.984h99.181v32.787h62.568v-32.787h25.41V121.67H21.33z" fill="url(#h)" />
         <path
            d="M218.777 121.67c1.68 12.074 4.848 22.884 9.505 32.428 7.468 14.937 17.668 26.321 30.601 34.153C271.997 196.084 287.025 200 303.965 200c16.939 0 31.876-3.916 44.808-11.749 13.115-7.832 23.315-19.216 30.601-34.153 4.773-9.544 8.02-20.354 9.743-32.428h-64.733c-.427 2.673-.95 5.103-1.567 7.292-1.821 7.104-4.372 12.113-7.65 15.027-3.279 2.915-7.013 4.372-11.202 4.372-4.19 0-7.924-1.457-11.203-4.372-3.278-2.914-5.919-7.923-7.923-15.027-.561-2.189-1.036-4.619-1.424-7.292h-64.638z"
            fill="url(#i)"
         />
         <path d="m405.019 121.67-.13.188v40.984h99.18v32.787h62.569v-32.787h25.409V121.67H405.019z" fill="url(#j)" />
         <defs>
            <linearGradient id="a" x1="57.489" y1="58.7" x2="71.428" y2="-57.022" gradientUnits="userSpaceOnUse">
               <stop stopColor="#2D9614" />
               <stop offset="1" stopColor="#BAE860" />
            </linearGradient>
            <linearGradient id="b" x1="57.489" y1="58.7" x2="71.428" y2="-57.022" gradientUnits="userSpaceOnUse">
               <stop stopColor="#2D9614" />
               <stop offset="1" stopColor="#BAE860" />
            </linearGradient>
            <linearGradient id="c" x1="57.489" y1="58.7" x2="71.428" y2="-57.022" gradientUnits="userSpaceOnUse">
               <stop stopColor="#2D9614" />
               <stop offset="1" stopColor="#BAE860" />
            </linearGradient>
            <linearGradient id="d" x1="0" y1="121.67" x2="13.728" y2="-2.754" gradientUnits="userSpaceOnUse">
               <stop stopColor="#2D9614" />
               <stop offset="1" stopColor="#BAE860" />
            </linearGradient>
            <linearGradient id="e" x1="0" y1="121.67" x2="13.728" y2="-2.754" gradientUnits="userSpaceOnUse">
               <stop stopColor="#2D9614" />
               <stop offset="1" stopColor="#BAE860" />
            </linearGradient>
            <linearGradient id="f" x1="0" y1="121.67" x2="13.728" y2="-2.754" gradientUnits="userSpaceOnUse">
               <stop stopColor="#2D9614" />
               <stop offset="1" stopColor="#BAE860" />
            </linearGradient>
            <linearGradient id="g" x1="0" y1="121.67" x2="13.728" y2="-2.754" gradientUnits="userSpaceOnUse">
               <stop stopColor="#2D9614" />
               <stop offset="1" stopColor="#BAE860" />
            </linearGradient>
            <linearGradient id="h" x1="21.201" y1="200" x2="42.3" y2="46.235" gradientUnits="userSpaceOnUse">
               <stop stopColor="#2D9614" />
               <stop offset="1" stopColor="#BAE860" />
            </linearGradient>
            <linearGradient id="i" x1="21.201" y1="200" x2="42.3" y2="46.235" gradientUnits="userSpaceOnUse">
               <stop stopColor="#2D9614" />
               <stop offset="1" stopColor="#BAE860" />
            </linearGradient>
            <linearGradient id="j" x1="21.201" y1="200" x2="42.3" y2="46.235" gradientUnits="userSpaceOnUse">
               <stop stopColor="#2D9614" />
               <stop offset="1" stopColor="#BAE860" />
            </linearGradient>
         </defs>
      </svg>
   );
};

export default NotFoundIcon;
