import { Link } from "react-router-dom";
import scss from "./index.module.scss";
import logOut from "../../../helpers/logout";

const { card, card__title, card__action, card__avatar, card__block } = scss;

function AsideUserCard({ username, onLogOut }) {
   return (
      <article className={card}>
         <Link to="/manage">
            <img
               src="/assets/base_avatar.jpg"
               className={card__avatar}
               alt="Аватар пользователя"
               width="64"
               height="64"
            />
         </Link>
         <div className={card__block}>
            <Link to="/manage" className={card__title}>
               {username}
            </Link>
            <p className={card__action} onClick={() => logOut("/", onLogOut)}>
               Выйти
            </p>
         </div>
      </article>
   );
}

export default AsideUserCard;
