function IconChevronLeft(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M15.523 19.17a.9.9 0 01-1.272-.032l-6.002-6.3a.9.9 0 010-1.242l6-6.3a.9.9 0 111.304 1.24l-5.41 5.682 5.41 5.68a.9.9 0 01-.03 1.271z"
         ></path>
      </svg>
   );
}

export default IconChevronLeft;
