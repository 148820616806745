function IconArrowRight(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
         <path
            fill="#010101"
            d="M13.52 3.85a.9.9 0 00-1.23 1.3l6.28 5.95H3.3a.9.9 0 100 1.8h15.27l-6.28 5.95a.9.9 0 101.23 1.3l7.7-7.28a1.2 1.2 0 000-1.74l-7.7-7.29z"
         ></path>
      </svg>
   );
}

export default IconArrowRight;
