import { useState } from "react";

import Button from "../../Inputs/Button";
import CopiedField from "../../StateLess/CopiedField";

import classes from "../styles.module.scss";

import api from "../../../helpers/fetch";
import formatCard from "../../../helpers/formatCard";
import checkAuth from "../../../helpers/checkAuth";

const {
    modalContent__text,
    modalContent__button,
    modalContent__copiedFields
} = classes;

const PaymentRequisites = ({
    onClose = () => {},
    data = {},
    token,
    number,
    onClick
}) => {

    const [isWait, setWait] = useState(false);

    const {
        card = 0,
        amount = 0
    } = data;

    const auth = checkAuth();

    const onPay = async () => {
        const body = new FormData();

        if (token) {
            body.append("token", token);
        }

        body.append("number", number);
        body.append("amount", amount);

        setWait(true);
        const response = await (new api(`payments`, token ? false : auth, {
            method: "POST",
            body
        })).send();
        setWait(false);

        onClose(null, () => onClick(response.status === "success"));
    }

    return (
        <>
            <p className={modalContent__text}>
                Олата переводом на карту.
            </p>

            <div className={modalContent__copiedFields}>
                <CopiedField
                    label="Номер карты"
                    value={formatCard(card)}
                    valueToCopy={card}
                />
                
                <CopiedField
                    label="Сумма платежа"
                    value={parseFloat(amount).toLocaleString("ru-RU") + " ₽"}
                    valueToCopy={amount}
                />
            </div>
            
            <Button className={modalContent__button} onClick={onPay} isWaiting={isWait} disabled={token === "" && !auth}>
                Я перевёл{amount > 0 ? " " + (parseFloat(amount)).toLocaleString("ru-RU") + " ₽" : ""}
            </Button>
        </>
    );
}

export default PaymentRequisites;