import { useState } from "react";

import Button from "../Button";
import Icon, { ColorIconEnam, IconEnum } from "../../StateLess/Icon";

import classes from "./styles.module.scss";

const {
    paymentsList,
    paymentsList__select,
    paymentsList__select_active,
    paymentsList__selectImg,
    paymentsList__selectInfo,
    paymentsList__selectInfoName,
    paymentsList__selectInfoDuration,
    paymentsList__selectArrow,
    
    paymentsList__wrapper,
    paymentsList__wrapper_active,
    paymentsList__background,
    paymentsList__list,
    paymentsList__listLine,
    paymentsList__item,
    paymentsList__itemInfo,
    paymentsList__itemName,
    paymentsList__itemDuration,
    paymentsList__itemImg,
    paymentsList__cancel,
} = classes;

let leaveTimer;

const PaymentsMethods = ({
    paymentMethods = [],
    slectedIndex = 0,
    onSelect = () => {}
}) => {

    const [wrapperState, setWrapperState] = useState(false);
    const [listState, setListState] = useState(false);
    
    const show = () => {
        setWrapperState(true);
        setTimeout(() => {
            setListState(true);
        }, 1);
    }

    const hide = () => {
        setListState(false);
        setTimeout(() => {
            setWrapperState(false);    
        }, 300);
    }

    const leave = () => {
        if (document.body.offsetWidth > 525) {
            leaveTimer = setTimeout(() => {
                hide();
            }, 100);
        }
    }

    const abortHide = () => {
        clearTimeout(leaveTimer);
    }

    const choose = (val) => {
        hide();
        onSelect(val);
    }

    return (
        <div className={paymentsList}>
            {
                paymentMethods.length > 0 ? (
                    <div
                        className={paymentsList__select + (listState ? " " + paymentsList__select_active : "")}
                        onClick={show}
                        onMouseLeave={leave}
                        onMouseEnter={abortHide}
                    >
                        <Icon className={paymentsList__selectImg} name={paymentMethods[slectedIndex].img} color={ColorIconEnam.base} />
        
                        <div className={paymentsList__selectInfo}>
                            <p className={paymentsList__selectInfoName}>
                                {paymentMethods[slectedIndex].name}
                            </p>
                            <p className={paymentsList__selectInfoDuration}>
                                {paymentMethods[slectedIndex].time === 0 ? "Мгновенно" : "Обработка до " + paymentMethods[slectedIndex].time + "х дней"}
                            </p>
                        </div>
        
                        <Icon className={paymentsList__selectArrow} name={IconEnum.chewron_right} color={ColorIconEnam.base} />
                    </div>
                ) : ""
            }

            <div className={paymentsList__wrapper + (wrapperState ? " " + paymentsList__wrapper_active : "")}>
                <div className={paymentsList__background} onClick={hide} />

                <div className={paymentsList__list} onMouseEnter={abortHide} onMouseLeave={leave}>
                    <div className={paymentsList__listLine} />

                    {
                        paymentMethods.map((method, i) => slectedIndex !== i ? (
                            <div className={paymentsList__item} key={i} onClick={() => choose(i)}>
                                <Icon className={paymentsList__itemImg} name={method.img} color={ColorIconEnam.base} />
                                
                                <div className={paymentsList__itemInfo}>
                                    <p className={paymentsList__itemName}>
                                        {method.name}
                                    </p>
                                    <p className={paymentsList__itemDuration}>
                                    {method.time === 0 ? "Мгновенно" : "Обработка до " + method.time + "х дней"}
                                    </p>
                                </div>
                            </div>
                        ) : "")
                    }

                    <Button className={paymentsList__cancel} variant={"gray"} onClick={hide}>Назад</Button>
                </div>
            </div>
        </div>
    );
}

export default PaymentsMethods;