import { useRef } from "react";
import classes from "./styles.module.scss";

const {
    mainDigitLine,
    mainDigitLine__card,
    mainDigitLine__cardValue,
    mainDigitLine__cardSubText
} = classes;

const MainDigitLine = ({ data, editable = false, blockIndex = -1, onChange = () => {} }) => {

    const list = data.digit_line;
    const defaultList = useRef(list).current;

    return (
        <div className={mainDigitLine}>
            {
                list.map ((item, i) => (
                    <div className={mainDigitLine__card} key={i}>
                        <h3
                            className={mainDigitLine__cardValue}
                            contentEditable={editable}
                            suppressContentEditableWarning={true}
                            value={item.value}
                            onChange={e=>onChange(blockIndex, `digit_line.${i}.value`, e.target.textContent)}
                            onBlur={e=>onChange(blockIndex, `digit_line.${i}.value`, e.target.textContent)}
                        >
                            {defaultList[i].value}
                        </h3>

                        <p
                            className={mainDigitLine__cardSubText}
                            contentEditable={editable}
                            suppressContentEditableWarning={true}
                            value={item.value}
                            onChange={e=>onChange(blockIndex, `digit_line.${i}.sub_text`, e.target.textContent)}
                            onBlur={e=>onChange(blockIndex, `digit_line.${i}.sub_text`, e.target.textContent)}
                        >
                            {defaultList[i].sub_text}
                        </p>
                    </div>
                ))
            }
        </div>
    );
}

export default MainDigitLine;