import Icon, { ColorIconEnam, IconEnum } from "../../StateLess/Icon";

import scss from "./index.module.scss";

const {
   operationsHistoryItem__body,
   operationsHistoryItem__heading,
   operationsHistoryItem__title,
   operationsHistoryItem__text,
   operationsHistoryItem__info,
   operationsHistoryItem__text__summ,
   operationsHistoryItem__text__summ_false,
   operationsHistoryItem__icon,
} = scss;

function OperationsHistoryItem({
   value = { title: "", text: "", date: "", summ: "", status: "" },
}) {
   const getIconByTitle = (title) => {
      switch (title) {
         case "Поступление стредств": {
            return IconEnum.arrow_down;
         }
         case "Получение займа": {
            return IconEnum.arrow_up;
         }
         case "Проценты по займу": {
            return IconEnum.sale;
         }
         default:
            return null;
      }
   };
   const getStatusByStatus = (status) => {
      switch (status) {
         case "processing": {
            return "В обработке";
         }
         case "denied": {
            return "Отклонён";
         }
         default:
            return null;
      }
   };
   return (
      <>
         <div className={operationsHistoryItem__body}>
            <Icon
               className={operationsHistoryItem__icon}
               name={
                  value?.status === "confirmed"
                     ? getIconByTitle(value?.title)
                     : value?.status === "denied"
                     ? IconEnum.expressionless
                     : IconEnum.clock_light
               }
               color={ColorIconEnam.gray}
            />
            <div className={operationsHistoryItem__heading}>
               <span className={operationsHistoryItem__title}>{value?.title}</span>
               <span className={operationsHistoryItem__text}>{value?.text}</span>
            </div>
         </div>
         <div className={operationsHistoryItem__info}>
            <span className={operationsHistoryItem__title}>
               {value?.status !== "confirmed"
                  ? getStatusByStatus(value?.status)
                  : new Date(value?.date).toLocaleDateString("RU-ru")}
            </span>
            <span
               className={
                  operationsHistoryItem__text__summ +
                  " " +
                  (value?.summ < 0 ? operationsHistoryItem__text__summ_false : "")
               }
            >
               {`${(value?.summ / 100).toLocaleString("RU-ru")} ₽`}
            </span>
         </div>
      </>
   );
}

export default OperationsHistoryItem;
