import IconCopy from "../../../components/Icons/Copy";
import Card from "../Card";
import scss from "./index.module.scss";

const { container, header, header__title, header__subtitle, wait, cards } = scss;

function InfoLoanHeader({ params, rate, amount, initials, id }) {
   return (
      <section className={container}>
         <div className={header}>
            <span className={header__title}>
               Займ №{params ? params : <div className={wait}></div>}{" "}
               <IconCopy onClick={() => navigator.clipboard.writeText(params)} />
            </span>
            <h2 className={header__subtitle}>Информация по займу</h2>
         </div>
         <div className={cards}>
            <Card
               type={amount !== 0 ? "base" : ""}
               title={"Ставка в день"}
               text={rate.toLocaleString("ru-RU") + " %"}
            />
            <Card
               type={amount !== 0 ? "base" : ""}
               title={"Сумма займа"}
               text={amount.toLocaleString("ru-RU") + " ₽"}
            />
            <Card type={amount !== 0 ? "linked" : ""} title={"Клиент"} text={initials} id={id} />
         </div>
      </section>
   );
}

export default InfoLoanHeader;
