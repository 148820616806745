import { useState } from "react";
import { Link } from "react-router-dom";

import Button from "../../Inputs/Button";
import InputRange from "../../Inputs/InputRange";
import Picture from "../../StateLess/Picture";
import Icon, { IconEnum } from "../../StateLess/Icon";

import css from "./styles.module.scss";

const {
   calculator,
   calculator__banner,
   calculator__bannerText,
   calculator__bannerH1,
   calculator__bannerH2,
   calculator__bannerH3,
   calculator__bannerImageMobile,
   calculator__bannerImage,
   calculator__form,
   calculator__formArea,
   calculator__formFields,
   calculator__formField,
   calculator__formFieldInput,
   calculator__formDocs,
   calculator__formDocsLink,
   calculator__formInfo,
   calculator__formInfoField,
   calculator__formInfoValue,
   calculator__formSubmit,
   calculator__formSubmitText,
   calculator__formSubmitIcon
} = css;

const initialState = window.initialState
   ? window.initialState
   : {
        settings: {
           calculator: {
              min_amount: 10000,
              max_amount: 30000,
              min_interest: 0,
              max_interest: 1,
              min_duration: 7,
              max_duration: 30,
           },
        },
     };

const Calculator = () => {
   const amount = [];
   const days = [];

   for (
      let i = parseInt(initialState.settings.calculator.min_amount);
      i <= parseInt(initialState.settings.calculator.max_amount);
      i += 1000
   ) {
      amount.push(i);
   }
   for (
      let i = parseInt(initialState.settings.calculator.min_duration);
      i <= parseInt(initialState.settings.calculator.max_duration);
      i++
   ) {
      days.push(i);
   }

   const [selectedAmount, selectAmount] = useState(3);
   const [selectedDay, selectDay] = useState(3);

   const lastDate = new Date(new Date().getTime() + days[selectedDay] * 24 * 60 * 60 * 1000);

   return (
      <section className={calculator}>
         <div className={calculator__banner}>
            <div className={calculator__bannerText}>
               <Picture
                  fileName={'main_offer_mob'}
                  secondImges={['main_offer_mob', 'main_offer_mob']}
                  alt={''}
                  className={calculator__bannerImageMobile}
                  ftype="png"
               />

               <h2 className={calculator__bannerH1}>Первый заём</h2>
               <p className={calculator__bannerH2}>до 15 дней</p>
               <p className={calculator__bannerH3}>ставка 0%</p>
            </div>
            <Picture
               fileName={'main_offer'}
               secondImges={['main_offer', 'main_offer']}
               alt={''}
               className={calculator__bannerImage}
               ftype="png"
            />
         </div>

         <div className={calculator__form}>
            <div className={calculator__formArea}>
               <div className={calculator__formFields}>
                  <div className={calculator__formField}>
                     <InputRange
                        label="Сумма"
                        unit="₽"
                        ariaLabel="Сумма займа"
                        className={{calculator__formFieldInput}}
                        value={selectedAmount}
                        list={amount}
                        length={amount.length - 1}
                        onChange={(e) => selectAmount(e)}
                        step={1}
                     />
                  </div>

                  <div className={calculator__formField}>
                     <InputRange
                        label="Срок"
                        unit="дней"
                        ariaLabel="Срок займа"
                        className={calculator__formFieldInput}
                        value={selectedDay}
                        list={days}
                        length={days.length - 1}
                        onChange={(e) => selectDay(e)}
                        step={1}
                     />
                  </div>
               </div>
               
               <p className={calculator__formDocs}>
                  Предложение является публичной офертой.<br />
                  <Link className={calculator__formDocsLink} to={"/dokumenti"}>Условия получения займа</Link>
               </p>
            </div>

            <div className={calculator__formInfo}>
               <p className={calculator__formInfoField}>
                  К возврату <span className={calculator__formInfoValue}>
                     {
                        days[selectedDay] < 15 ? (
                           (amount[selectedAmount]).toLocaleString("ru-RU")
                        )
                        : (
                           (amount[selectedAmount] + (amount[selectedAmount] / 100 * days[selectedDay])).toLocaleString("ru-RU")
                        )
                     } ₽
                  </span>
               </p>

               <p className={calculator__formInfoField}>
                  Дата платежа <span className={calculator__formInfoValue}>{lastDate.toLocaleDateString("ru-RU")}</span>
               </p>

               <Button
                  href={"/application?amount=" + amount[selectedAmount] + "&duration=" + days[selectedDay]}
                  variant={"white"}
                  className={calculator__formSubmit}
               >
                  <span className={calculator__formSubmitText}>Оформить заём</span> <Icon className={calculator__formSubmitIcon} name={IconEnum.arror_right} color="#ffffff" />
               </Button>
            </div>
         </div>
      </section>
   );
};

export default Calculator;
